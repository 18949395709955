import {AbstractDefaultComponent} from 'app/shared/abstracts/abstract-default-component/abstract-default-component';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GenericTableConfigurationModel} from '../../../shared/components/table/model/generic-table-model';
import {ActivatedRoute} from '@angular/router';
import {
    AuthorityType,
    MobilitaRequestSelectFormValuesDTO,
    PeriodoDiMobilitaStudenteStatus,
    TipoPeriodoEnum
} from '../../../../api-clients/generated/services';
import {TranslocoService} from '@ngneat/transloco';
import {BehaviorSubject, finalize, of, Subscription, take, takeUntil} from 'rxjs';
import {FuseMediaWatcherService} from '../../../../@fuse/services/media-watcher';
import {MatDrawer} from '@angular/material/sidenav';
import {filter, first, switchMap, tap} from 'rxjs/operators';
import {AppInitService} from '../../../shared/service/app-init.service';
import {FuseConfirmationService} from '../../../../@fuse/services/confirmation';
import {LogoutService} from '../../../shared/service/logout.service';
import {
    MobilitaSidebarFilterContainerComponent
} from './mobilita-sidebar-filter-container/mobilita-sidebar-filter-container.component';
import {FormControl} from '@angular/forms';
import {LocalStorageService} from '../../../shared/service/local-storage.service';
import {MatTabGroup} from '@angular/material/tabs';
import {GenericTableComponent} from '../../../shared/components/table/generic-table/generic-table.component';
import {
    RicercaSidebarFilterContainerComponent
} from './ricerca-sidebar-filter-container/ricerca-sidebar-filter-container.component';
import {MobilitaFilterService} from './mobilita-sidebar-filter-container/mobilita-filter.service';
import {RicercaFilterService} from './ricerca-sidebar-filter-container/ricerca-filter.service';
import {
    BudgetSidebarFilterContainerComponent
} from "./budget-sidebar-filter-container/budget-sidebar-filter-container.component";
import {BudgetFilterService} from "./budget-sidebar-filter-container/budget-filter.service";
import {TableMobilityComponent} from "./table-mobility/table-mobility.component";
import {ActivitiesFilterService} from "./activities-sidebar-filter-container/activities-filter.service";
import {StudyPlansFilterService} from "./study-plans-sidebar-filter-container/study-plans-filter.service";

export const DEFAULT_PAGE_SIZE = 10;

export enum TabsEnum {
    SOGGIORNOESTERO = 'SOGGIORNO_ESTERO',
    RICERCA = 'RICERCA',
    BUDGET = 'BUDGET',
    ACTIVITIES = 'ACTIVITIES',
    STUDY_PLANS = 'STUDY_PLANS'
}

@Component({
    selector: 'app-requests-to-be-resolved',
    templateUrl: './requests-to-be-resolved.component.html',
    styleUrls: ['./requests-to-be-resolved.component.scss']
})
export class RequestsToBeResolvedComponent extends AbstractDefaultComponent implements OnDestroy, OnInit {

    loading: boolean;
    mobilityRequestsListTableConfiguration: GenericTableConfigurationModel;
    protected readonly Object = Object;
    protected readonly PeriodoDiMobilitaStudenteStatus = PeriodoDiMobilitaStudenteStatus;
    drawerOpened: boolean = false;
    @ViewChild('drawer') drawer: MatDrawer;
    selectValues: MobilitaRequestSelectFormValuesDTO;
    showMiniLoader: boolean = false;
    mobilitaSidebarContainerComponent: MobilitaSidebarFilterContainerComponent;
    ricercaSidebarContainerComponent: RicercaSidebarFilterContainerComponent;
    budgetSidebarFilterContainerComponent: BudgetSidebarFilterContainerComponent;
    TabsEnum = TabsEnum;

    @ViewChild(TableMobilityComponent) tableMobilityComponent: TableMobilityComponent;

    @ViewChild(MobilitaSidebarFilterContainerComponent) set mobilitaSidebarContainer(c: MobilitaSidebarFilterContainerComponent) {
        if (!!c) {
            this.mobilitaSidebarContainerComponent = c;
        }
    }

    @ViewChild(RicercaSidebarFilterContainerComponent) set ricercaSidebarContainer(c: RicercaSidebarFilterContainerComponent) {
        if (!!c) {
            this.ricercaSidebarContainerComponent = c;
        }
    }

    @ViewChild(BudgetSidebarFilterContainerComponent) set budgetSidebarContainer(c: BudgetSidebarFilterContainerComponent) {
        if (!!c) {
            this.budgetSidebarFilterContainerComponent = c;
        }
    }

    currentLanguage: string;
    currentRuolo: AuthorityType;
    sottoruoli: AuthorityType[];
    ctrlSelectFormControl: FormControl = new FormControl(null);
    currentSubroleCanAccess: boolean;
    isSubroleSelected: boolean;
    mustChooseSubrole: boolean;
    protected readonly AuthorityType = AuthorityType;
    selectedTabTipo: TabsEnum = TabsEnum.SOGGIORNOESTERO;
    private filterChangesSubscription = new Map<TipoPeriodoEnum, Subscription>();
    _tabsGroup: MatTabGroup;

    @ViewChild(MatTabGroup) set tabsGroup(tabsGroup: MatTabGroup) {
        if (tabsGroup && !this._tabsGroup) {
            this._tabsGroup = tabsGroup;
            this.handleFragmentNavigation(tabsGroup);
        }
    }

    private table: GenericTableComponent;

    @ViewChild(GenericTableComponent) set genericTable(gt: GenericTableComponent) {
        this.table = gt;
    }

    constructor(private translocoService: TranslocoService,
                private _fuseMediaWatcherService: FuseMediaWatcherService,
                private mobilitaFilterManagerService: MobilitaFilterService,
                private ricercaFilterManagerService: RicercaFilterService,
                private budgetFilterService: BudgetFilterService,
                private appInitService: AppInitService,
                private fuseConfirmationService: FuseConfirmationService,
                private logoutService: LogoutService,
                private localStorageService: LocalStorageService,
                private activatedRoute: ActivatedRoute,
                private activitiesFilterService: ActivitiesFilterService,
                private studyPlansFilterService: StudyPlansFilterService,
    ) {
        super();

        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this.destroy$))
            .subscribe(({matchingAliases}) => {
                this.drawerOpened = false;
            });
        this.translocoService.langChanges$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(lang => this.currentLanguage = lang);
    }

    private initService() {
        this.mobilitaFilterManagerService.init(true);
        this.ricercaFilterManagerService.init(true);
        this.budgetFilterService.init(true);
        this.activitiesFilterService.init(true);
        this.studyPlansFilterService.init(true);
    }

    ngOnInit(): void {
        this.getSottoruoliRequest();
        this.subscribeToSottoruoliValueChange();
    }

    handleFragmentNavigation(tabsGroup) {
        this.activatedRoute.fragment.pipe(takeUntil(this.destroy$), first()).subscribe((fragment: string) => {
            console.log('typed fragment', fragment);
            const tabs = (tabsGroup?._tabs as any)._results;
            console.log(tabs);
            const matchIndex = tabs?.findIndex(x => x.ariaLabel === fragment);
            console.log('matched fragment with tab at position', matchIndex);
            if (matchIndex >= 0) {
                tabsGroup.selectedIndex = matchIndex;
            }
        });
    }

    getSottoruoliRequest(): void {
        this.loading = true;
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            tap(value => this.currentRuolo = value?.ruolo),
            switchMap((value) => {
                if (this.isDocenteOrPTA(this.currentRuolo)) {
                    return this.appInitService.currentSottoruoliUtente$.pipe(first());
                } else {
                    return of(null);
                }
            }),
            tap((sottoruoli: AuthorityType[]) => {
                if (!!sottoruoli) {
                    this.sottoruoli = sottoruoli;
                    this.setCurrentSottoruolo();
                    this.mustChooseSubrole = true;
                    this.currentSubroleCanAccess = [
                        AuthorityType.COORDINATORE,
                        AuthorityType.GRUPPODICOORDINAMENTO,
                        AuthorityType.DIRETTOREDIDIPARTIMENTO
                    ].includes(this.ctrlSelectFormControl?.value);
                } else {
                    this.mustChooseSubrole = false;
                    this.currentSubroleCanAccess = true;
                }
            }),
            finalize(() => this.loading = false),
            takeUntil(this.destroy$),
        ).subscribe({
                next: (value) => {
                    this.initService();
                },
                error: (err) => {
                    this.loading = false;
                    this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                        () => this.logoutService.goToHome(),
                        () => this.getSottoruoliRequest(),
                        'common.go_to_home',
                        err?.error?.message);
                }
            }
        );
    }

    private subscribeToSottoruoliValueChange() {
        this.ctrlSelectFormControl.valueChanges.subscribe((sottoruolo) => {
            this.localStorageService.setSottoruoloCiclo(sottoruolo);
            this.appInitService.setCicloCorsoRuoloSelected$(this.appInitService.cicloCorsoRuoloSelected).subscribe();
            if ([
                AuthorityType.COORDINATORE,
                AuthorityType.GRUPPODICOORDINAMENTO,
                AuthorityType.DIRETTOREDIDIPARTIMENTO].includes(this.ctrlSelectFormControl?.value)) {
                this.currentSubroleCanAccess = true;
            } else {
                this.mobilityRequestsListTableConfiguration = undefined;
                this.currentSubroleCanAccess = false;
            }
            this.isSubroleSelected = true;
        });
    }

    setCurrentSottoruolo(): void {
        const savedSottoruolo: AuthorityType = this.localStorageService?.dipartimentoRuoloCiclo?.sottoruolo;
        if (!!savedSottoruolo) {
            const findedSottoruolo = this.sottoruoli.includes(savedSottoruolo);
            if (findedSottoruolo) {
                this.ctrlSelectFormControl.setValue(savedSottoruolo, {emitEvent: false});
                this.isSubroleSelected = true;
            }
        }
        this.localStorageService.setSottoruoloCiclo(this.ctrlSelectFormControl?.value);
        this.appInitService.setCicloCorsoRuoloSelected$(this.appInitService.cicloCorsoRuoloSelected).subscribe();
    }

    isDocenteOrPTA(ruolo?: AuthorityType): boolean {
        return ruolo === AuthorityType.DOCENTE || ruolo === AuthorityType.PERSONALEPTA;
    }


    closeDrawer() {
        this.drawer.close();
    }


    toogleFilterContainer(resp: { hasToogleInternalMenu: boolean, tab: TabsEnum }): void {
        this.selectedTabTipo = resp.tab;
        if (resp.hasToogleInternalMenu) {
            this.drawer.toggle();
        }
    }

    ngOnDestroy(): void {
        this.mobilitaFilterManagerService.filterApplied = undefined;
        this.ricercaFilterManagerService.filterApplied = undefined;
        this.budgetFilterService.filterApplied = undefined;
        this.activitiesFilterService.filterApplied = undefined;
        this.mobilitaFilterManagerService.page = 0;
        this.ricercaFilterManagerService.page = 0;
        this.budgetFilterService.page = 0;
        this.activitiesFilterService.page = 0;
        this.mobilitaFilterManagerService.pageSize = DEFAULT_PAGE_SIZE;
        this.ricercaFilterManagerService.pageSize = DEFAULT_PAGE_SIZE;
        this.budgetFilterService.pageSize = DEFAULT_PAGE_SIZE;
        this.activitiesFilterService.pageSize = DEFAULT_PAGE_SIZE;
        this.mobilitaFilterManagerService.selectValues = undefined;
        this.ricercaFilterManagerService.selectValues = undefined;


        this.mobilitaFilterManagerService.mainFormGroup = undefined;
        this.ricercaFilterManagerService.mainFormGroup = undefined;
        this.activitiesFilterService.mainFormGroup = undefined;
        this.budgetFilterService.mainFormGroup = undefined;

        this.studyPlansFilterService.filterApplied = undefined;
        this.studyPlansFilterService.page = 0;
        this.studyPlansFilterService.mainFormGroup = undefined;
        this.studyPlansFilterService.pageSize = DEFAULT_PAGE_SIZE;
        this.studyPlansFilterService.selectValues = undefined;
        super.ngOnDestroy();
    }


    reset(tab: TabsEnum): void {
        switch (tab) {
            case TabsEnum.SOGGIORNOESTERO:
                this.mobilitaFilterManagerService?.reset();
                break;
            case TabsEnum.RICERCA:
                this.ricercaFilterManagerService?.reset();
                break;
            case TabsEnum.BUDGET:
                this.budgetFilterService?.reset();
                break;
            case TabsEnum.ACTIVITIES:
                this.activitiesFilterService?.reset();
                break;
            case TabsEnum.STUDY_PLANS:
                this.studyPlansFilterService?.reset();
                break;

        }
    }
}
