import {
    AnnoRiferimentoValues,
    AttivitaTrasversaleStatus,
    CategoriaInAggiornamentoInPianoDiStudiInfoView,
    CategoriaInPianoDiStudiInfoView, DatiCorsoOffertaFormativa, PianoDiStudiInfoViewImpl,
    TipoModificaCorsoPianoDiStudi
} from "api-clients/generated/services";
import {
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from "app/shared/components/table/model/generic-table-model";
import {TypeDialogFormEnum} from "../../../../../../layout/common/generic-components/generic-components-enum";
import {
    getCfuOttentutiFromApprovedPlan,
    getCfuPrevistiFromApprovedPlan, getDataSuperamentoValueFromApprovedPlan, getMotivazioneRifiutoValueFromApprovedPlan, getNotaSuperamentoValueFromApprovedPlan,
    getStatoAttivitaTrasversaleFromApprovedPlan, getSuperatoValueFromApprovedPlan,
    visualizzaBottoniForm,
    visualizzaStatoAttivitaTrasversale
} from "../../study-plan-utils";
import {assign as assignLodash, get, sortBy, trim} from 'lodash';
import {getPresumedNumbersForDraft} from "../nuova-presentazione/configurazione-corsi-nuova-presentazione";
import {Translation} from "@ngneat/transloco";

/* NOT USED ANYMORE
export function buildConfigurationForTransversalActivitiesToApprove(
    category: CategoriaInPianoDiStudiInfoView | CategoriaInAggiornamentoInPianoDiStudiInfoView,
    approvedPlan?: PianoDiStudiInfoViewImpl,
    showModificaForApprover?: boolean): GenericTableConfigurationModel {
    if (!!showModificaForApprover) {
        return buildConfigurationForTransversalActivitiesToApproveInDialog(category, approvedPlan);
    } else {
        return buildConfigurationForTransversalActivitiesToApproveNotInDialog(category, approvedPlan);
    }
}

export function buildConfigurationForTransversalActivitiesToApproveInDialog(
    category: CategoriaInPianoDiStudiInfoView | CategoriaInAggiornamentoInPianoDiStudiInfoView,
    approvedPlan?: PianoDiStudiInfoViewImpl,
): GenericTableConfigurationModel {

    const coursesPlan = category.corsi;

    //count get presumed numbers
    const presumedNumbers = getPresumedNumbersForDraft(category);

    const myData = coursesPlan.map(item =>
        assignLodash(item.datiCorsoOffertaFormativa, {tipoModifica: item.tipoModifica})).map(
        element => ({
            ...element,
            readonly: true,
            idAttivitaTrasversale: element?.idAttivitaTrasversale,
            tipoModifica: element?.tipoModifica,
            statoAttivitaTrasversale: getStatoAttivitaTrasversale(element, approvedPlan, category.idCategoriaOffertaFormativa),
           /!* todo superato: corsiApprovati?.find(corso =>
                corso.annoInsegnamento === annoInsegnamentoInput
                && corso.idAttivitaTrasversale === element.idAttivitaTrasversale)?.superato,
            statoAttivitaTrasversale: corsiApprovati?.find(corso =>
                corso.annoInsegnamento === annoInsegnamentoInput
                && corso.idAttivitaTrasversale === element.idAttivitaTrasversale)?.statoAttivitaTrasversale,
            dataSuperamento: corsiApprovati?.find(corso =>
                corso.annoInsegnamento === annoInsegnamentoInput
                && corso.idAttivitaTrasversale === element.idAttivitaTrasversale)?.dataSuperamento,
            notaSuperamento: corsiApprovati?.find(corso =>
                corso.annoInsegnamento === annoInsegnamentoInput
                && corso.idAttivitaTrasversale === element.idAttivitaTrasversale)?.notaSuperamento,
            cfu: corsiApprovati?.find(corso =>
                corso.annoInsegnamento === annoInsegnamentoInput
                && corso.idAttivitaTrasversale === element.idAttivitaTrasversale)?.cfuPrevisti ||
                corsiApprovati?.find(corso =>
                    corso.annoInsegnamento === annoInsegnamentoInput
                    && corso.idAttivitaTrasversale === element.idAttivitaTrasversale)?.descrizioneCfu,*!/
            tipoDocumentoDottorandoFormatted: element?.tipoDocumentoDottorando || '-',
            tipoDocumentoSupervisoreFormatted: element?.tipoDocumentoSupervisore || '-',
            denominazione: element.denominazione ?? element.denominazioneAttivitaPresunta,
            denominazioneFormatted: (element.idAttivitaPresunta && !element.denominazione) ? (get(translation, 'study_plan_status.presumed_activity') + ' ' + presumedNumbers.pop()) : element.denominazione,
            idCategoriaOffertaFormativa: category.idCategoriaOffertaFormativa,
            cfuPrevisti: getCfuPrevisti(element, approvedPlan, category.idCategoriaOffertaFormativa),
            cfuOttenuti: getCfuOttentuti(element, approvedPlan, category.idCategoriaOffertaFormativa),
        })
    ).sort((item1, item2) => item1.denominazioneFormatted?.localeCompare(item2.denominazioneFormatted));
    const myConfig = {
        configuration: {
            data: myData,
            totalElements: myData?.length,
            isPaginatedBE: false,
            sticky: true,

            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.activity_typology',
                    colonnaKey: 'denominazioneFormatted',
                    flex: 20,
                },
                /!*{
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'common.status',
                    colonnaKey: 'statoAttivitaTrasversale',
                    flex: 18,
                    show: (value, element) => visualizzaStatoAttivitaTrasversale(element),
                    hideColonna: !!showModificaForApprover
                    statusType: AttivitaTrasversaleStatus
                },*!/
/!*                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'student.passed',
                    colonnaKey: 'superato',
                    flex: 16,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                },*!/
                // aggiungo un form anche per l'id perché ho bisogno di distinguere le righe
                {
                    tipo: TipoColonnaEnum.STRING,
                    show: () => false,
                    nomeColonna: 'id',
                    colonnaKey: 'id',
                    flex: 0,
                    formControl: {
                        type: TypeDialogFormEnum.TEXT,
                        show: false,
                        name: 'id',
                    }
                },
                // aggiungo un form per mettere in readonly i form
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    show: () => false,
                    nomeColonna: 'readonly',
                    colonnaKey: 'readonly',
                    flex: 0,
                    formControl: {
                        type: TypeDialogFormEnum.BOOLEAN,
                        show: false,
                        name: 'readonly',
                        value: true
                    }
                },
                {
                    tipo: TipoColonnaEnum.INPUT_NUMBER,
                    nomeColonna: 'common.cfu',
                    colonnaKey: 'cfu',
                    flex: 16,
                    formControl: {
                        type: TypeDialogFormEnum.NUMBER,
                        show: true,
                        name: 'cfu',
                        required: true,
                    }
                },
                {
                    tipo: TipoColonnaEnum.INPUT_NUMBER,
                    nomeColonna: 'total_hour_table.column_total_hour',
                    colonnaKey: 'ore',
                    flex: 16,
                    formControl: {
                        type: TypeDialogFormEnum.NUMBER,
                        show: true,
                        name: 'ore',
                        required: true,
                        min: {number: 1, text: ''},
                    }
                },
                /!*                    {
                        tipo: TipoColonnaEnum.BOOLEAN,
                        nomeColonna: 'cycle_doctorate.student_document_required',
                        colonnaKey: 'presenzaDocumentoDottorando',
                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no',
                        flex: 8,
                    },*!/
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.student_document',
                    colonnaKey: 'tipoDocumentoDottorandoFormatted',
                    flex: 20,
                },
                /!*                    {
                                        tipo: TipoColonnaEnum.BOOLEAN,
                                        nomeColonna: 'cycle_doctorate.supervisor_document_required',
                                        colonnaKey: 'presenzaDocumentoSupervisore',
                                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no',
                                        flex: 8,
                                    },*!/
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.supervisor_document',
                    colonnaKey: 'tipoDocumentoSupervisoreFormatted',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'cycle_doctorate.coordinator_required',
                    colonnaKey: 'presenzaDeliberaCoordinatore',
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no',
                    flex: 8,
                },
                {
                    nomeColonna: 'common.edit_type',
                    colonnaKey: 'tipoModifica',
                    flex: 0,
                    show: () => false,
                    tipo: TipoColonnaEnum.STRING,
                    isCheckboxDisabled: false,
                    formControl: {
                        type: TypeDialogFormEnum.TEXT,
                        show: false,
                        name: 'tipoModifica'
                    }
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'study_plan_status.archived_activity',
                    colonnaKey: 'isArchiviato',
                    flex: 8,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                },
                {
                    nomeColonna: 'common.additional_information',
                    colonnaKey: 'descrizione',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'info',
                        click: TipoClickEnum.SHOW,
                        tooltip: 'common.additional_information',
                        color: 'accent'
                    }]
                },
                {
                    nomeColonna: 'user_list.action',
                    colonnaKey: 'azioni',
                    flex: 15,
                    show: (value, element) => value === TipoModificaCorsoPianoDiStudi.AGGIUNTA,
                    valueCheck: 'tipoModifica',
                    tipo: TipoColonnaEnum.ACTION,
                    // @ts-ignore
                    button: [
                        {
                            nameIconButton: 'edit',
                            click: TipoClickEnum.MODIFICA_FORM,
                            tooltip: 'common.edit',
                            show: (value, element) => visualizzaBottoniForm(element),
                            valueShow: ['tipoModifica'],
                            color: 'accent',
                        },
                        {
                            nameIconButton: 'save',
                            click: TipoClickEnum.SALVA_FORM,
                            tooltip: 'common.save',
                            color: 'accent',
                            show: (value, element) => visualizzaBottoniForm(element),
                            valueShow: ['tipoModifica'],
                        },
                        {
                            nameIconButton: 'refresh',
                            click: TipoClickEnum.RESET_FORM,
                            tooltip: 'menu_filter.reset',
                            color: 'accent',
                            show: (value, element) => visualizzaBottoniForm(element),
                            valueShow: ['tipoModifica'],
                        }
                    ]

                }
            ],
            hidePaginator: true,
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_activities_for_year'
        }
    };
    return myConfig as GenericTableConfigurationModel;
}*/

export function buildConfigurationForTransversalActivitiesToApprove(
    translation: Translation,
    category: CategoriaInPianoDiStudiInfoView | CategoriaInAggiornamentoInPianoDiStudiInfoView,
    approvedPlan?: PianoDiStudiInfoViewImpl,
    ): GenericTableConfigurationModel {

    const coursesPlan = category.corsi;

    //count get presumed numbers
    const presumedNumbers = getPresumedNumbersForDraft(category);

    let myData = coursesPlan.map(item =>
        assignLodash(item.datiCorsoOffertaFormativa, {tipoModifica: item.tipoModifica})).map(
        element => ({
            ...element,
            readonly: true,
            idAttivitaTrasversale: element?.idAttivitaTrasversale,
            tipoModifica: element?.tipoModifica,
            statoAttivitaTrasversale: getStatoAttivitaTrasversaleFromApprovedPlan(element, approvedPlan, category.idCategoriaOffertaFormativa),
            superato: getSuperatoValueFromApprovedPlan(element, approvedPlan, category.idCategoriaOffertaFormativa, true),
            notaSuperamento: getNotaSuperamentoValueFromApprovedPlan(element, approvedPlan, category.idCategoriaOffertaFormativa, true),
            dataSuperamento: getDataSuperamentoValueFromApprovedPlan(element, approvedPlan, category.idCategoriaOffertaFormativa, true),
            tipoDocumentoDottorandoFormatted: element?.tipoDocumentoDottorando || '-',
            tipoDocumentoSupervisoreFormatted: element?.tipoDocumentoSupervisore || '-',
            denominazione: element.denominazione ?? element.denominazioneAttivitaPresunta,
            denominazioneFormatted: (element.idAttivitaPresunta && !element.denominazione) ? (get(translation, 'study_plan_status.presumed_activity') + ' ' + presumedNumbers.pop()) : element.denominazione,
            idCategoriaOffertaFormativa: category.idCategoriaOffertaFormativa,
            cfuPrevisti: getCfuPrevistiFromApprovedPlan(element, approvedPlan, category.idCategoriaOffertaFormativa, true),
            cfuOttenuti: getCfuOttentutiFromApprovedPlan(element, approvedPlan, category.idCategoriaOffertaFormativa, true),
            motivazioneRifiuto: getMotivazioneRifiutoValueFromApprovedPlan(element, approvedPlan, category.idCategoriaOffertaFormativa, true),
            isArchiviato: element.isArchiviato || element?.isAssociazioneArchiviata,
        })
    );

    myData = sortBy(myData, [
        attivita => !attivita.idAttivitaProposta,
        attivita => !attivita.idAttivitaPresunta,
        attivita => trim(attivita.denominazioneFormatted)
    ], ['asc', 'asc', 'asc']);

    const myConfig = {
        configuration: {
            data: myData,
            totalElements: myData?.length,
            isPaginatedBE: false,
            sticky: true,

            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.activity_typology',
                    colonnaKey: 'denominazioneFormatted',
                    flex: 20,
                    alsoShowChipIf: (activity) => {
                        if(activity.idAttivitaProposta){
                            return {
                                chipText: 'study_plan_status.proposed_activity',
                                iconName: 'school',
                                classes: 'text-blue-600'
                            };
                        } else if (activity.idAttivitaPresunta){
                            return {
                                chipText: activity.denominazione ? 'study_plan_status.presumed_activity_defined' : 'study_plan_status.presumed_activity_not_defined',
                                iconName: 'question_mark',
                                classes: activity.denominazione ? 'text-green-600' : 'text-orange-600'
                            };
                        } else {
                            return undefined;
                        }
                    },
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'common.detail',
                    colonnaKey: 'dettaglioAttivitaTrasversale',
                    flex: 25,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'common.edit_type',
                    colonnaKey: 'tipoModifica',
                    flex: 15,
                    tooltip: tipoModifica => 'study_plan_status.edit_types.' + tipoModifica.toLowerCase(),
                    statusType: TipoModificaCorsoPianoDiStudi
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'common.status',
                    colonnaKey: 'statoAttivitaTrasversale',
                    flex: 21,
                    show: (value, element) => visualizzaStatoAttivitaTrasversale(element),
                    statusType: AttivitaTrasversaleStatus
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'study_plan_status.archived_activity',
                    colonnaKey: 'isArchiviato',
                    show: (_, activity) => !activity.idAttivitaProposta && !activity.idAttivitaPresunta,

                    flex: 8,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                },
                {
                    nomeColonna: 'common.detail',
                    colonnaKey: 'descrizione',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'info',
                        click: TipoClickEnum.SHOW,
                        tooltip: 'common.show_detail',
                        color: 'accent'
                    }]
                },
            ],
            hidePaginator: true,
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_activities_for_year'
        }
    };
    return myConfig as GenericTableConfigurationModel;
}
