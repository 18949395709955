import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {
    BehaviorSubject,
    catchError,
    distinctUntilChanged,
    EMPTY,
    map,
    Observable, ObservedValueOf, OperatorFunction,
    switchMap,
    take,
    throwError,
    timer
} from 'rxjs';

import {Router} from '@angular/router';
import {filter, mergeMap, tap} from 'rxjs/operators';
import {SamlAuthService, TokenResponse} from '../../shared/service/saml-auth.service';
import {LocalStorageService, TokenI} from '../../shared/service/local-storage.service';
import {TranslocoService} from '@ngneat/transloco';
import {FuseConfirmationConfig, FuseConfirmationService} from '../../../@fuse/services/confirmation';
import {SnackbarTypes} from '../../../@fuse/services/confirmation/snackbar/snackbar.component';
import {PathEnum} from '../../app.routing';
import {UsersService, UserView} from '../../../api-clients/generated/services';
import {get} from "lodash";
import {LogoutService} from "../../shared/service/logout.service";
import {MatDialog} from "@angular/material/dialog";
import {AppInitService, ignoreInitPaths} from "../../shared/service/app-init.service";
import {CicloCorsoRuoloInterface} from "../../shared/interface/CicloCorsoRuoloInterface";
import {ImageManagerService} from "../../shared/service/image-manager.service";

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

    isTokenRefreshing: BehaviorSubject<boolean | undefined> | undefined;
    private errorRefresh: string = 'Errore durante l\'aggiornamento della sessione. Si prega di rieseguire la login.';
    private dipartimentoRuoloCicloSelected: CicloCorsoRuoloInterface;
    counterError401 = 0; // Se le chiamate vanno in 401 uno più di 10 volte viene effettuata la logout
    maxError401 = 10;
    cicloAmmSelected: number;

    /**
     * Constructor
     */
    constructor(
        private localStorageService: LocalStorageService,
        private router: Router,
        private samlAuthService: SamlAuthService,
        private translocoService: TranslocoService,
        private fuseConfirmationService: FuseConfirmationService,
        private usersService: UsersService,
        private logoutService: LogoutService,
        private appInitservice: AppInitService,
        private imageManagerService: ImageManagerService,
        private matDialog: MatDialog
    ) {
        this.translocoService.selectTranslate('common.error_refresh_token').subscribe(value => this.errorRefresh = value);
        this.appInitservice.cicloCorsoRuoloSelected$.subscribe(dipartimentoRuoloCicloSelected => this.dipartimentoRuoloCicloSelected = dipartimentoRuoloCicloSelected);
        this.appInitservice.cicloCorsoRuoloForSottoruoliRequest$.subscribe(dipartimentoRuoloCicloSelected => this.dipartimentoRuoloCicloSelected = dipartimentoRuoloCicloSelected);
        this.appInitservice.cicloAmmSelected$.subscribe(cicloAmmSelected => this.cicloAmmSelected = (cicloAmmSelected || this.localStorageService.getCicloAmm(true)));


    }

    checkSecondsToExpireToken(): number {
        const jwtToken = this.localStorageService.decodeToken();
        if (jwtToken) {
            const expires = new Date(jwtToken.exp * 1000);
            return expires.getTime() - Date.now();
        }

    }

    /**
     * Intercept
     *
     * @param httpRequest
     * @param next
     */
    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        console.log('INTERCEPTED REQUEST', httpRequest.url)

        let authReq = this.getHttpRequestWithCycleHeaders(httpRequest);
        if (requestsToNotCheckToken.some(path => authReq.url?.includes(path))) {
            console.log('Intercettata chiamata di login, refresh token o pubblica, NON utilizzo refresh token interceptor: ', authReq.url);
            return next.handle(authReq);
        }

        const millisToExpire = this.checkSecondsToExpireToken();
        console.log('millisToExpire', millisToExpire)

        if (httpRequest.method !== 'OPTIONS') {
            if (millisToExpire < 5 * 60 * 1000) {
                console.log('refreshToken in millisToExpire')
                return this.refreshToken(authReq, next, null);
            } else {
                console.log('refreshToken in else')
                return this.handleRequest(authReq, next);
            }
        } else {
            return next.handle(authReq);
        }
    }

    handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(
                (error: HttpErrorResponse) => {
                    if (error.status === 401) {
                        if (this.counterError401 <= this.maxError401) {
                            console.log('error');
                            this.counterError401 += 1;
                            return this.refreshToken(request, next, error);
                        } else {
                            return this.stopRefreshAndLogout(error);
                        }
                    } else if (error?.status === 403 && error?.error?.code === 1009) {
                        return this.stopRefreshAndLogout(error, request);
                    } else {
                        return throwError(() => error);
                    }
                }),
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse && event.status === 200) {
                    this.counterError401 = 0;
                }
            }));
    }

    refreshToken(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
        if (!!this.isTokenRefreshing) {
            console.log('Token is already refreshing.');
            return this.isTokenRefreshing.pipe(
                filter(value => value !== undefined),
                take(1),
                switchMap(
                    (result) => {
                        if (result) {
                            console.log('Chiamata handlerequest 1');
                            return timer(500).pipe(
                                switchMap(() => this.handleRequest(request.clone({
                                    setHeaders: {
                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                        Authorization: 'Bearer ' + this.localStorageService.getAccessToken(),
                                    },
                                }), next)));
                        } else {
                            return EMPTY;
                        }
                    }
                )
            );
        } else {
            console.log('Token is NOT already refreshing.');
            this.isTokenRefreshing = new BehaviorSubject<boolean | undefined>(undefined);
            return this.postRequestRefreshFlow$().pipe(
                mergeMap((result) => {
                    this.localStorageService.setTokenResponse(result);
                    console.log('Chiamata handlerequest 2');
                    return this.handleRequest(request.clone({
                        setHeaders: {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            Authorization: 'Bearer ' + result.access_token,
                        },
                    }), next);
                }),
                catchError((tokenError) => {
                    if (tokenError.url.includes('token')) {
                        return this.stopRefreshAndLogout(tokenError);
                    } else {
                        return throwError(() => error);
                    }
                }));
        }
    }


    postRequestRefreshFlow$(): Observable<TokenResponse | any> {
        return (this.samlAuthService.postRequestRefresh() as Observable<TokenResponse>).pipe(
            tap((value) => {
                this.isTokenRefreshing?.next(true);
                this.isTokenRefreshing = undefined;
            }),
            distinctUntilChanged(),
            take(1),
            tap((value: TokenResponse) => this.localStorageService.setTokenResponse(value)),
            switchMap((tokenResponse: TokenResponse) => this.usersService.checkAndGetUser().pipe(
                this.catchErrorInRefresh$(this.usersService.checkAndGetUser()),
                tap((userView: UserView) => this.localStorageService.setProfileResponse(userView)),
                switchMap((userView: UserView) =>
                    this.imageManagerService.getProfileImage(userView?.urlImmagineProfiloThumb, userView?.codiceFiscale).pipe(
                        this.catchErrorInRefresh$(this.imageManagerService.getProfileImage(userView?.urlImmagineProfiloThumb, userView?.codiceFiscale))
                    )),
                map(() => tokenResponse),
            )),
            catchError((error) => {
                if (error.url.includes('token')) {
                    return this.stopRefreshAndLogout(error);
                } else {
                    return throwError(() => error);
                }
            })
        );
    }

    getConfigModalError(): FuseConfirmationConfig {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        return {
            title: get(translation, 'common.attention', null),
            message: get(translation, 'error.generic', null),
            onBackdrop: {
                show: false,
                backdrop: false,
            },
            actions: [
                {
                    function: (): void => {
                        this.logoutService.logout();
                        this.isTokenRefreshing?.next(false);
                        this.isTokenRefreshing = undefined;
                        this.showErrorMessage();
                    },
                    label: get(translation, 'common.go_to_login', null),
                    hexColor: '#809dbd',
                    hexColorText: '#FFFFFF'
                },
                {
                    label: get(translation, 'common.try_again', null),
                    hexColor: '#809dbd',
                    hexColorText: '#FFFFFF',
                    closeValue: true,
                }
            ]
        };
    }

    catchErrorInRefresh$(inputObs: Observable<any>): OperatorFunction<unknown, ObservedValueOf<Observable<any>> | unknown> {
        return this.fuseConfirmationService.catchErrorCustom$(inputObs, this.getConfigModalError(), true, undefined, undefined, undefined, true);
    }

    private stopRefreshAndLogout(error: HttpErrorResponse, request?: HttpRequest<any>): Observable<HttpEvent<any>> {

        // tslint:disable-next-line:no-non-null-assertion
        if (this.isTokenRefreshing) {
            this.isTokenRefreshing!.next(false);
            this.isTokenRefreshing = undefined;
        }
        this.counterError401 = 0;
        this.showErrorMessage();


        if (error.status === 401 || error.status === 400) {
            this.localStorageService.cleanAllClassVariablesAndStorage();
        } else if (error.status === 403) {
            if(request?.url?.includes('/logout')){
                this.localStorageService.cleanAllClassVariablesAndStorage();
            } else {
                // only if the error is 403 and the request is not a logout request
                this.logoutService.logout();
            }
        }
        this.router.navigateByUrl('/' + PathEnum.SIGN_IN);
        this.matDialog?.closeAll();
        return EMPTY;
    }

    showErrorMessage(): void {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.openSnackBar({
            message: get(translation, 'common.error_refresh_token', null),
            type: SnackbarTypes.Error,
        });
    }

    // occorre fare prima un check e poi il settaggio degli header
    // perché se gli header non vengono settati insieme
    // c'è una sovrascrittura dei primi header settati
    getHttpRequestWithCycleHeaders(httpRequest: HttpRequest<any>): HttpRequest<any> {
        const isAreaAdministrator = this.appInitservice.isAreaAdministrator;
        if(httpRequest.url.includes('ciclo-corso-di-studi/configurazione')){
            return httpRequest.clone({
                headers: httpRequest.headers
                    .set('Accept-Language', this.translocoService.getActiveLang())
                    .set('Dottorandi-User-Ruolo', this.appInitservice.ruoloSelectedForConfigurationFetch)
            });
        } else if (!httpRequest.url.includes('token')
            && (!isAreaAdministrator  || managementRequests.some(path => httpRequest.url?.includes(path)))
            && !!this.dipartimentoRuoloCicloSelected?.codiceCorsoStudi
            && !!this.dipartimentoRuoloCicloSelected?.ruolo
            && !!this.dipartimentoRuoloCicloSelected?.ciclo
            && !!this.dipartimentoRuoloCicloSelected?.sottoruolo) {
            return httpRequest.clone({
                headers: httpRequest.headers
                    .set('Accept-Language', this.translocoService.getActiveLang())
                    .set('Dottorandi-Corso-Di-Studi-Codice', this.dipartimentoRuoloCicloSelected?.codiceCorsoStudi)
                    .set('Dottorandi-User-Ruolo', this.dipartimentoRuoloCicloSelected?.ruolo)
                    .set('Dottorandi-Dottorato-Ciclo', this.dipartimentoRuoloCicloSelected?.ciclo?.toString())
                    .set('Dottorandi-User-Sottoruolo', this.dipartimentoRuoloCicloSelected?.sottoruolo)
            });
        } else if (!httpRequest.url.includes('token')
            && (!isAreaAdministrator  || managementRequests.some(path => httpRequest.url?.includes(path)))
            && !!this.dipartimentoRuoloCicloSelected?.codiceCorsoStudi
            && !!this.dipartimentoRuoloCicloSelected?.ruolo
            && !!this.dipartimentoRuoloCicloSelected?.ciclo) {
            return httpRequest.clone({
                headers: httpRequest.headers
                    .set('Accept-Language', this.translocoService.getActiveLang())
                    .set('Dottorandi-Corso-Di-Studi-Codice', this.dipartimentoRuoloCicloSelected?.codiceCorsoStudi)
                    .set('Dottorandi-User-Ruolo', this.dipartimentoRuoloCicloSelected?.ruolo)
                    .set('Dottorandi-Dottorato-Ciclo', this.dipartimentoRuoloCicloSelected?.ciclo?.toString())
            });
        } else if (!httpRequest.url.includes('token')
            && isAreaAdministrator
            && this.cicloAmmSelected) {
            return this.getHttpRequestWithCycleHeadersAmm(httpRequest);
        } else {
            return httpRequest.clone({
                headers: httpRequest.headers
                    .set('Accept-Language', this.translocoService.getActiveLang())
            });
        }
    }

    private getHttpRequestWithCycleHeadersAmm(httpRequest: HttpRequest<any>) {
        if(this.appInitservice.codiceCorsoStudiAmmSelected){
            return httpRequest.clone({
                headers: httpRequest.headers
                    .set('Accept-Language', this.translocoService.getActiveLang())
                    .set('Dottorandi-Dottorato-Ciclo', this.cicloAmmSelected?.toString())
                    .set('Dottorandi-Corso-Di-Studi-Codice', this.appInitservice.codiceCorsoStudiAmmSelected)
            });
        } else {
            return httpRequest.clone({
                headers: httpRequest.headers
                    .set('Accept-Language', this.translocoService.getActiveLang())
                    .set('Dottorandi-Dottorato-Ciclo', this.cicloAmmSelected?.toString())
            });
        }

    }
}

export const requestsToNotCheckToken = [
    'login',
    'token',
    'user/esterno/invito'
]

export const managementRequests = [
    '/user/sottoruoli',
]
