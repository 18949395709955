import {Injectable} from '@angular/core';
import {formGroupConfigInterface} from "../../layout/common/generic-components/generic-components.interface";
import {TypeDialogFormEnum} from "../../layout/common/generic-components/generic-components-enum";
import {openFileInBlankWindow} from "../utils/utils";
import {TranslocoService} from "@ngneat/transloco";
import {switchMap, tap, throwError, timer} from "rxjs";
import {FuseConfirmationService} from "../../../@fuse/services/confirmation";
import {
    CicliCorsiDiStudiService,
    ConfigurazioneCicloCorsoDiStudi,
    ConfigurazioneDocumento,
    DettaglioDocumentiAllegato,
    LinguaValore
} from "../../../api-clients/generated/services";
import moment from "moment";
import {optionFile} from "../costants/app-constants";
import {DocumentNameFromUrlPipe} from "../components/document/pipes/document-name-from-url.pipe";
import {max} from "lodash";

@Injectable({
    providedIn: 'root'
})
export class CicloCorsoConfigurationService {
    /**
     * Holds the right flows-operations configuration.
     * Provides methods to get the configuration according the given flow and operation.
     */

    configuration: ConfigurazioneCicloCorsoDiStudi;

    // getCicloCorsoConfiguration = (ciclo: string, codiceCorsoStudi: string) =>
    //     // this.cicliCorsiDiStudiService.getConfigurazioneCicloCorsoDiStudi(ciclo, codiceCorsoStudi).pipe(
    //
    //     timer(1000).pipe(
    //         switchMap(() => throwError(() => new Error('ciap'))),
    //         tap(cicloCorsoConfig => console.log('Fetching ciclo-corso configuration json for', ciclo, codiceCorsoStudi)),
    //         // tap(cicloCorsoConfig => this.configuration = cicloCorsoConfig),
    //         tap(_ => this.configuration = {}),
    //         tap(cicloCorsoConfig => console.log('Configuration fetched successfully')),
    //     );

    getCicloCorsoConfiguration = (ciclo: string, codiceCorsoStudi: string) => {
        console.log('Fetching ciclo-corso configuration json for', ciclo, codiceCorsoStudi);
        return this.cicliCorsiDiStudiService.getConfigurazioneCicloCorsoDiStudi(+ciclo, codiceCorsoStudi).pipe(
            tap(cicloCorsoConfig => this.configuration = cicloCorsoConfig),
            tap(_ => console.log('Configuration fetched successfully')),
        );
    }

    constructor(private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private cicliCorsiDiStudiService: CicliCorsiDiStudiService) {

    }

    getDocsConfigForFlowOperation(selectConfigFn: (config: ConfigurazioneCicloCorsoDiStudi) => any,
                                  downloadFileCallback: (filename: string) => void,
                                  ): formGroupConfigInterface[] {
        const config = selectConfigFn(this.configuration)?.map((documentSpecification: ConfigurazioneDocumento) => ({
            show: true,
            name: documentSpecification.chiave,
            noTransloco: this.getDescriptionValueAccordingCurrentLanguage(documentSpecification.descrizione),
            numeroMinimoDocumenti: documentSpecification.numero_minimo_documenti,
            numeroMassimoDocumenti: documentSpecification.numero_massimo_documenti,
            type: TypeDialogFormEnum.DOCUMENT,
            pipe: DocumentNameFromUrlPipe,
            downloadFileCallback: (fileOrUrl: File | string) => {
                if(typeof fileOrUrl === 'string'){
                    downloadFileCallback(fileOrUrl);
                } else {
                    openFileInBlankWindow(fileOrUrl, fileOrUrl.name);
                }
            },
            optionFile: {
                sizeByte: (documentSpecification.dimensione_massima_byte ?? optionFile.sizeMb * 1024),
                sizeMb: 0, // unused for TypeDialogFormEnum.DOCUMENT
                listaAccepted: documentSpecification.tipi_file_ammessi
            }
        }) as formGroupConfigInterface);
        return config ?? [];
    }

    getDocsValuesForFlowOperation(selectConfigFn: (config: ConfigurazioneCicloCorsoDiStudi) => any,
                                  documentsData: any[]): any {
        const valuesObj = {};
        selectConfigFn(this.configuration)?.forEach((documentSpecification: ConfigurazioneDocumento) => {
            valuesObj[documentSpecification.chiave] = (documentsData?.filter(doc => doc?.documentoInfo?.chiave === documentSpecification.chiave)?.map(doc => doc.url) ?? []) ?? [];
            // preadding empty required values
            while (valuesObj[documentSpecification.chiave]?.length < (max([1, documentSpecification.numero_minimo_documenti ?? 0]))){
                valuesObj[documentSpecification.chiave]?.push(undefined);
            }
        });
        return valuesObj;
    }

    prepareDocsSubmitObjectForFlowOperation(selectConfigFn: (config: ConfigurazioneCicloCorsoDiStudi) => any,
                                            formValues: any,): {
        specifications: DettaglioDocumentiAllegato[];
        files: File[];
    } {
        const specifications: DettaglioDocumentiAllegato[] = selectConfigFn(this.configuration)?.flatMap((documentSpecification: ConfigurazioneDocumento) => {
            return formValues?.[documentSpecification.chiave]
                ?.filter(value => !!value)
                ?.map((fileOrUrl: File | string) => ({
                    chiave: documentSpecification.chiave,
                    tipo_documento: documentSpecification.documento_type,
                    url: typeof fileOrUrl !== 'string' ? this.createNameForAddedFile(fileOrUrl.name) : fileOrUrl,
                }) as DettaglioDocumentiAllegato);
        }) ?? [];
        const files: File[] = selectConfigFn(this.configuration)?.flatMap((documentSpecification: ConfigurazioneDocumento) => {
            return formValues?.[documentSpecification.chiave]
                ?.filter(value => !!value)
                ?.filter((fileOrUrl: File | string) => typeof fileOrUrl !== 'string')
                ?.map((file: File) => new File([file], this.createNameForAddedFile(file.name), {type: file.type}));
        }) ?? [];
        return {
            specifications,
            files
        };
    }

    private getDescriptionValueAccordingCurrentLanguage(descriptionSpecifications: LinguaValore[]): string {
        const activeLang = this.translocoService.getActiveLang();
        return descriptionSpecifications?.find(descriptionSpecification => descriptionSpecification?.lingua?.toLowerCase() === activeLang?.toLowerCase())?.valore;
    }

    private createNameForAddedFile(fileName: string) {
        const extensionDotIndex = fileName?.lastIndexOf('.');
        return moment().unix() + fileName?.substring(extensionDotIndex);
    }

    getDescriptionValueAccordingCurrentLanguageAndKey(selectDocConfigFn: (config: ConfigurazioneCicloCorsoDiStudi) => ConfigurazioneDocumento[], chiave: string) {
        const activeLang = this.translocoService.getActiveLang();
        return selectDocConfigFn(this.configuration)?.find(config => config.chiave === chiave)
            ?.descrizione?.find(
                descriptionSpecification => descriptionSpecification?.lingua?.toLowerCase() === activeLang?.toLowerCase())?.valore;
    }

    init() {
        // subscribe to ciclo and corso changes
        // this.cicloCorsoConfiguration$.subscribe({
        //     next: flowOperationsConfiguration => this.configuration = flowOperationsConfiguration,
        //     error: err => {
        //         this.fuseConfirmationService.openErrorDialog({error: err},
        //             this.translocoService,
        //             undefined,
        //             () => this.init(),
        //             undefined,
        //             err?.error?.message)
        //     }
        // })
    }

}
