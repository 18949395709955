import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import moment from "moment";
import {DialogWrapI} from "../../../../../layout/common/dialog-wrap/dialog-wrap.component";
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TableData
} from "../../../../../shared/components/table/model/generic-table-model";
import {UIActivityI} from "../study-plans-categories-utils";
import {BehaviorSubject} from "rxjs";
import {cloneDeep} from "lodash-es";

export interface DialogDefineCoursePresumedI extends DialogWrapI {
    onConfirm: (activity: UIActivityI, loading$: BehaviorSubject<boolean>, dialogRef: MatDialogRef<DialogDefinePresumedCourseComponent>) => void;
    activitiesConf: GenericTableConfigurationModel;
    activitiesToNotShow: string[];
    onTableClickAction: (clickEvent: ClickEvent) => void;
    dontCloseDialog?: boolean;
}

@Component({
    selector: 'app-dialog-define-presumed-course',
    templateUrl: './dialog-define-presumed-course.component.html',
    styleUrls: ['./dialog-define-presumed-course.component.scss']
})
export class DialogDefinePresumedCourseComponent implements OnInit {

    righeSelezionate: Array<any>;
    tableData: TableData;

    loading$ = new BehaviorSubject(false);
    activitiesConf:GenericTableConfigurationModel;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDefineCoursePresumedI,
                public dialogRef: MatDialogRef<DialogDefinePresumedCourseComponent>) {
        this.activitiesConf = cloneDeep(this.data.activitiesConf);
        this.activitiesConf.configuration.data = this.activitiesConf?.configuration?.data?.filter(att => !this.data.activitiesToNotShow.includes(att.id));
    }

    ngOnInit(): void {

    }

    closeDialog() {
        this.dialogRef.close();
    }

    confirmAction() {
        this.data?.onConfirm(this.righeSelezionate?.[0]?.data, this.loading$, this.dialogRef);
        if(!this.data.dontCloseDialog){
            this.closeDialog();
        }
    }


    protected readonly stop = stop;
    protected readonly moment = moment;
    protected readonly confirm = confirm;
    trackByIndex = (index: number, obj: any) => index;

    onAttivitaSelezionateChanged($event: TableData) {
        this.tableData = $event;
        this.righeSelezionate = $event.selectedRows;
    }

}
