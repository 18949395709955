import {Injectable, Injector} from '@angular/core';
import {LocalStorageService} from './local-storage.service';
import {
    BehaviorSubject,
    catchError,
    EMPTY,
    finalize,
    forkJoin,
    map,
    Observable,
    of,
    Subject,
    switchMap,
    takeUntil,
    tap,
    throwError,
    timer,
    zip
} from 'rxjs';
import {
    AuthorityType,
    CicloAbilitatoViewImpl,
    CorsoDiStudiViewImpl,
    UserAmministrazioneInfoCicliDTO,
    UsersService,
    UserView
} from '../../../api-clients/generated/services';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractDefaultComponent} from '../abstracts/abstract-default-component/abstract-default-component';
import {CicloCorsoRuoloInterface} from '../interface/CicloCorsoRuoloInterface';
import {LogoutService} from './logout.service';
import {FuseConfirmationService} from '../../../@fuse/services/confirmation';
import {SnackbarTypes} from '../../../@fuse/services/confirmation/snackbar/snackbar.component';
import {TranslocoService} from '@ngneat/transloco';
import {get, groupBy, head} from 'lodash';
import {cloneDeep, uniqBy} from 'lodash-es';
import {ImageManagerService} from './image-manager.service';
import {PathEnum} from '../../app.routing';
import {GenericComponentDialogConfig} from '../../layout/common/generic-components/generic-components.interface';
import {
    DialogSetCicloCorsoRuoloComponent
} from '../../layout/layouts/vertical/classic/dialog-set-ciclo-corso-ruolo/dialog-set-ciclo-corso-ruolo.component';
import {MatDialog} from '@angular/material/dialog';
import {
    AreaSeleztione,
    DialogAreaSelectionComponent
} from "../components/dialog-area-selection/dialog-area-selection.component";
import {DialogWrapI} from "../../layout/common/dialog-wrap/dialog-wrap.component";
import {
    DialogSetCicloComponent,
    DialogSetCicloComponentI
} from "../../layout/layouts/vertical/classic/dialog-set-ciclo/dialog-set-ciclo.component";
import {HttpErrorResponse} from "@angular/common/http";
import {filter} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {versionInfo} from "../../../../version-info";
import {Meta} from "@angular/platform-browser";
import {CicloCorsoConfigurationService} from "./ciclo-corso-configuration.service";

interface Dictionary<T> {
    [Key: string]: T;
}


@Injectable({
    providedIn: 'root'
})
export class AppInitService extends AbstractDefaultComponent {

    listaRuoliCodiciDipartimenti: CicloAbilitatoViewImpl[] = undefined;
    listaRuoliCodiciDipartimenti$: BehaviorSubject<CicloAbilitatoViewImpl[]> = new BehaviorSubject(undefined);
    cicloCorsoRuoloSelected: CicloCorsoRuoloInterface = undefined;
    cicloCorsoRuoloSelected$: BehaviorSubject<CicloCorsoRuoloInterface> = new BehaviorSubject(undefined);
    changedRoleByDialog$: Subject<boolean> = new Subject();
    cicloCorsoRuoloForSottoruoliRequest$: BehaviorSubject<CicloCorsoRuoloInterface> = new BehaviorSubject(undefined);
    errorInvalidLocalStorageDipRuoloCiclo: string;
    listaCicliCorsiRuoliGroupByCiclo: Dictionary<CicloAbilitatoViewImpl[]>;
    currentSottoruoliUtente$: BehaviorSubject<AuthorityType[]> = new BehaviorSubject(undefined);
    //recaptchaToken: string;
    isAmministratore: boolean;
    erroreGetAssociazioni: boolean;
    cicloAmmSelected: number = undefined;
    cicloAmmSelected$: BehaviorSubject<number> = new BehaviorSubject(undefined);
    userAmministrazioneInfoCicli: UserAmministrazioneInfoCicliDTO;
    cicloRuoloAmmSelected$: BehaviorSubject<Partial<CicloCorsoRuoloInterface>> = new BehaviorSubject(undefined);
    codiceCorsoStudiAmmSelected: string;
    codiceCorsoStudiAmmSelected$: BehaviorSubject<string> = new BehaviorSubject(undefined);
    gestionaleRoleSelectionChoiceSkipped: boolean = false;
    BEversion: string;
    FEversion: string;
    ruoloSelectedForConfigurationFetch: AuthorityType;

    constructor(
        private localStorageService: LocalStorageService,
        private usersService: UsersService,
        private router: Router,
        private logoutService: LogoutService,
        private fuseConfirmationService: FuseConfirmationService,
        private _translocoService: TranslocoService,
        private injector: Injector,
        private meta: Meta,
        private imageManagerService: ImageManagerService,
        private dialog: MatDialog,
        private cicloCorsoConfiguration: CicloCorsoConfigurationService,
    ) {
        super();
        this.cicloCorsoRuoloSelected$.pipe(takeUntil(this.destroy$)).subscribe((dipartimentoRuoloCicloSelected) => {
            this.gestionaleRoleSelectionChoiceSkipped = false;
            this.cicloCorsoRuoloSelected = dipartimentoRuoloCicloSelected;
        });
        this.cicloAmmSelected$.subscribe((cicloAmmSelected) => {
            this.cicloAmmSelected = cicloAmmSelected;
            this.cleanCorsoAmm();
            this.cicloRuoloAmmSelected$.next(cicloAmmSelected ? {
                ciclo: this.cicloAmmSelected?.toString(),
                ruolo: this.getTheMajorRole
            } : undefined);
        });

        this.getFEVersion();

    }

    setMeta(): void {
        let fullEndpoint = this.getBasePath(environment.servicesUrl);
        const fullKeycloakUrl = this.getBasePath(environment.servicesKeycloak.basePath);
        // if (fullEndpoint === '/services-api') {
        //     fullEndpoint = 'http://localhost:5200';
        // }
        this.meta.updateTag({
            httpEquiv: 'Content-Security-Policy',
            id: 'csp-header',
            content:
                'default-src \'self\' data: blob:; ' +
                'script-src \'self\' https://www.google.com https://www.gstatic.com; ' + // Aggiunto gstatic
                'style-src \'self\' \'unsafe-inline\' https://fonts.googleapis.com https://fonts.googleapis.com ; ' +
                'img-src \'self\' data: blob:; ' +
                'font-src \'self\' data: https://fonts.gstatic.com https://fonts.googleapis.com; ' +  // Aggiunta per i font esterni
                'connect-src \'self\' ' + fullEndpoint + ' ' + fullKeycloakUrl + ' ;' +
                'frame-src \'self\' https://www.google.com; ' +
                'media-src \'self\'; ' +
                'object-src \'self\'; ' +
                'manifest-src \'self\'; ' +
                'frame-ancestors \'self\'; ' +
                'form-action \'self\''
        });
    }

    sanitizeUrl(url: string): string {
        return url.replace(/\/+$/, '');
    }

    getBasePath(url: string) {
        return url.match(/^https?:\/\/[^#?\/]+/)?.[0] ?? '';
    }

    init(): Observable<any> {
        this.setMeta();
        const _translocoService = this.injector.get(TranslocoService);
        _translocoService.setActiveLang(this.localStorageService.getActiveLang() || 'it');

        // if the user is on the privacy or tcs page, the appInitService is not initialized
        if (this.isPrivacyOrTCSPaths()) {
            return EMPTY;
        }

        // if keycloak retrieves the error errore_keycloak during login, the user is forced to logout from idp
        if (window.location?.href?.endsWith('logout?motivo=errore_keycloak')) {
            return this.logoutService.logoutForcingIDP$(environment.servicesKeycloak.idpLogout + '/idp/profile/Logout?redirectUrl=' + environment.servicesKeycloak.redirectUri + '?errore=errore_keycloak');
        }

        // if the path is esterni registering, the appInitService is not initialized and storage is cleaned
        this.checkIgnoreInitAndCleanStoragePaths();

        return new Observable((subscriber) => {
            zip([
                this.init$(),
                _translocoService.selectTranslate('error.invalid_field_logout_message').pipe(
                    tap(value => this.errorInvalidLocalStorageDipRuoloCiclo = value)),
                timer(2000)
            ]).subscribe({
                next: (value) => {
                    subscriber.complete();
                },
                error: (err) => {
                    subscriber.complete();
                },
                complete: () => {
                    subscriber.complete();
                }
            });
        });
    }

    init$(): Observable<Blob | boolean> | Observable<null> {
        const accessTokenFromStorage = this.localStorageService.getAccessToken(true);

        // DIRECT NAVIGATION
        this.handleDirectNavigation();

        /*
        .pipe(
                    this.fuseConfirmationService.catchErrorCustom$(this.init$(), {openModalError: {goToHome: false}}, true, false, true, false)
                )
         */


        if (accessTokenFromStorage) {
            this.localStorageService.accessToken = accessTokenFromStorage;
            this.localStorageService.refreshToken = this.localStorageService.getRefreshToken(true);
            return this.getAssociazioni$().pipe(
                switchMap(() => this.getProfilo$().pipe(
                    this.fuseConfirmationService.catchErrorCustom$(this.init$(), {openModalError: {goToHome: false}}, true, false, true, false)
                )),
                switchMap(() => {
                    const cicloCorsoRuoloInStorage = this.localStorageService.getCicloCorsoRuolo(true);
                    if (cicloCorsoRuoloInStorage?.ruolo === AuthorityType.DOCENTE || cicloCorsoRuoloInStorage?.ruolo === AuthorityType.PERSONALEPTA || cicloCorsoRuoloInStorage?.ruolo === AuthorityType.ESTERNO) {
                        this.cicloCorsoRuoloForSottoruoliRequest$.next(cicloCorsoRuoloInStorage);
                        console.log('appInitService.init() get sottoruoli')
                        return this.getSottoruoliUtente$().pipe(
                            map(sottoruoli => true),
                            catchError(error => {
                                this.listaRuoliCodiciDipartimenti = undefined;
                                this.listaRuoliCodiciDipartimenti$.next(undefined);
                                throw new Error(error);
                            })
                        );
                    } else {
                        return of(null);
                    }
                }),
            );
        } else {
            return EMPTY;
        }
    }

    private handleDirectNavigation() {

        let currentURL = decodeURIComponent(window.location.href);
        // removing the redirectURL part
        currentURL = currentURL.replace('/login?redirectURL=', '');

        let queryParams = new URL(currentURL).searchParams;
        let numeroCiclo = queryParams.get('numCiclo');
        let codiceCorsoStudi = queryParams.get('codiceCorsoEsse3');
        let ruolo = queryParams.get('ruolo');
        let sottoruolo = queryParams.get('sottoruolo');
        console.log(numeroCiclo, codiceCorsoStudi, ruolo, sottoruolo)

        // check if triple or quadruple present
        let numOfQueryParams = 0;
        queryParams.forEach((param, key) => {
            if (['numCiclo', 'codiceCorsoEsse3', 'ruolo', 'sottoruolo'].includes(key)) {
                numOfQueryParams += 1
            }
        });
        console.log(numOfQueryParams)
        if (numeroCiclo && codiceCorsoStudi && ruolo && (numOfQueryParams === 3 || numOfQueryParams === 4)) {
            //let triple = this.listaRuoliCodiciDipartimenti.find(ciclo => String(ciclo.numeroCiclo) === numeroCiclo && ciclo.ruoloUtenteCorsoDiStudi.ruolo === ruolo && ciclo.ruoloUtenteCorsoDiStudi.corsoDiStudi.codiceEsse3 === codiceCorsoStudi);
            const dipartimentoRuoloCiclo: CicloCorsoRuoloInterface = {
                codiceCorsoStudi: codiceCorsoStudi,
                ruolo: ruolo as AuthorityType,
                ciclo: numeroCiclo,
                denominazioneCorsoStudi: '',//triple?.ruoloUtenteCorsoDiStudi.corsoDiStudi.cicliCorsiDiStudi[0].denominazione
                codiceCorsoStudiCineca: this.listaCicliCorsiRuoliGroupByCiclo?.[numeroCiclo]
                    ?.find(el => el.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.codiceEsse3 === codiceCorsoStudi)
                    ?.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.codiceCineca
            };
            this.localStorageService.setDipartimentoRuoloCiclo(dipartimentoRuoloCiclo);
            if (sottoruolo) {
                this.localStorageService.setSottoruoloCiclo(sottoruolo as AuthorityType);
            } else {
                this.localStorageService.setSottoruoloCiclo(undefined);
            }
        } else if (numOfQueryParams !== 0) {
            // else, if there are params but not 3 or 4, clearing current triple or quadruple
            this.localStorageService.removeDipartimentoRuoloCiclo();
        }
        if (numeroCiclo || codiceCorsoStudi || ruolo || sottoruolo) {
            // clearing url from query params
            let queryParamsClearedUrl = currentURL.split('?')[0];
            // if there are fragments, reattach it
            if (currentURL.includes('#')) {
                queryParamsClearedUrl += currentURL.substring(currentURL.indexOf('#'))
            }
            console.log('queryParamsClearedUrl', queryParamsClearedUrl)
            window.history.replaceState({}, 'page', queryParamsClearedUrl);
        }
    }

    getAssociazioni$(): Observable<Array<CicloAbilitatoViewImpl>> {
        return forkJoin([
            this.usersService.getCicliRuoliCorsoDiStudiAssociations()
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                            console.log(error, ' err')
                            if (error.status !== 401) {
                                this.erroreGetAssociazioni = true;
                            }
                            return throwError(() => error);
                        }
                    )),
            this.usersService.getCicliCorsoDiStudiAmministrazione().pipe(
                catchError((error: HttpErrorResponse) => {
                        if (error?.status === 404) {
                            return of(null);
                        }
                        if (error.status !== 401) {
                            this.erroreGetAssociazioni = true;
                        }
                        return throwError(() => error);
                    }
                ))]).pipe(
            takeUntil(this.destroy$),
            tap(([value, userAmministrazioneInfoCicli]: [Array<CicloAbilitatoViewImpl>, UserAmministrazioneInfoCicliDTO]) => {
                this.isAmministratore = !!userAmministrazioneInfoCicli?.roles?.length;
                this.userAmministrazioneInfoCicli = userAmministrazioneInfoCicli;
                this.listaRuoliCodiciDipartimenti = value;
                this.listaRuoliCodiciDipartimenti$.next(value);
            }),
            map(([value, amministratore]: [Array<CicloAbilitatoViewImpl>, UserAmministrazioneInfoCicliDTO]) => value)
        );
    }


    checkValidityCicloCorsoRuoloInStorage(cicloCorsoRuoloInStorage: CicloCorsoRuoloInterface): boolean {
        const codiceCorsoStudiInStorage = cicloCorsoRuoloInStorage?.codiceCorsoStudi;
        const ruoloInStorage = cicloCorsoRuoloInStorage?.ruolo;
        const cicloInStorage = cicloCorsoRuoloInStorage?.ciclo;
        const listaCicliCorsiRuoliGroupByCiclo = groupBy(this.listaRuoliCodiciDipartimenti, 'numeroCiclo');
        const corsoStudiList = uniqBy(listaCicliCorsiRuoliGroupByCiclo[cicloInStorage]?.map(el => el.ruoloUtenteCorsoDiStudi?.corsoDiStudi), 'codiceEsse3');
        if (!!corsoStudiList.find(corso => corso.codiceEsse3 === codiceCorsoStudiInStorage)) {
            const ruoli = listaCicliCorsiRuoliGroupByCiclo[cicloInStorage]?.filter(el => (el.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.codiceEsse3 === codiceCorsoStudiInStorage)).map((el => el.ruoloUtenteCorsoDiStudi?.ruolo));
            if (ruoli.includes(ruoloInStorage)) {
                return true;
            } else {
                this.logoutService.logout();
                this.fuseConfirmationService.openSnackBar({
                    message: this.errorInvalidLocalStorageDipRuoloCiclo,
                    type: SnackbarTypes.Error
                });
                return false;
            }
        } else {
            this.logoutService.logout();
            this.fuseConfirmationService.openSnackBar({
                message: this.errorInvalidLocalStorageDipRuoloCiclo,
                type: SnackbarTypes.Error
            });
            return false;
        }

    }

    get listaRuoliCodiciDipartimentiObs(): Observable<CicloAbilitatoViewImpl[]> {
        return this.listaRuoliCodiciDipartimenti$.asObservable();
    }


    getProfilo$(): Observable<Blob | boolean> {
        return this.usersService.getProfiloUtente().pipe(
            tap((userView: UserView) => this.localStorageService.setProfileResponse(userView)),
            switchMap((userView: UserView) =>
                this.imageManagerService.getProfileImage(userView?.urlImmagineProfiloThumb, userView?.codiceFiscale)),
        );
    }


    get isDipartimentoRuoloCicloSelectedInService(): Observable<CicloCorsoRuoloInterface> {
        return this.cicloCorsoRuoloSelected$.asObservable();
    }

    get _changedRoleByDialog$(): Observable<boolean> {
        return this.changedRoleByDialog$.asObservable();
    }


    checkIfIsPresentCicloCorsoRuoloInStorage(redirect?: boolean): void {
        const cicloCorsoRuoloInStorage = this.localStorageService.getCicloCorsoRuolo(true);
        if (cicloCorsoRuoloInStorage) {
            this.checkAndSetCicloCorsoRuoloFromStorage(cicloCorsoRuoloInStorage);
        } else {
            this.prevalorizzaCicloCorsoRuolo(redirect);
        }
    }

    checkIfIsPresentCicloCorsoRuoloInStorageLogin(redirect?: boolean, redirectUrl?: boolean): void {
        const cicloCorsoRuoloInStorage = this.localStorageService.getCicloCorsoRuolo(true);
        if (cicloCorsoRuoloInStorage && redirectUrl) {
            this.checkAndSetCicloCorsoRuoloFromStorage(cicloCorsoRuoloInStorage);
        } else {
            this.prevalorizzaCicloCorsoRuolo(redirect);
        }
    }


    private checkAndSetCicloCorsoRuoloFromStorage(cicloCorsoRuoloInStorage: CicloCorsoRuoloInterface) {
        const isValidDipartimentoRuoloCicloInStorage = this.checkValidityCicloCorsoRuoloInStorage(cicloCorsoRuoloInStorage);
        if (isValidDipartimentoRuoloCicloInStorage) {
            const prevDipRuoloCiclo =  cloneDeep(this.localStorageService.dipartimentoRuoloCiclo);
            this.localStorageService.setDipartimentoRuoloCiclo(cicloCorsoRuoloInStorage);
            this.setCicloCorsoRuoloSelected$(cicloCorsoRuoloInStorage).subscribe({
                next: _ => {
                    const listaCicliCorsiRuoliGroupByCiclo = groupBy(this.listaRuoliCodiciDipartimenti, 'numeroCiclo');
                    const denominazioneCorsoStudiByCode = listaCicliCorsiRuoliGroupByCiclo[cicloCorsoRuoloInStorage?.ciclo]?.find(el => el.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.codiceEsse3 === cicloCorsoRuoloInStorage?.codiceCorsoStudi)?.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.cicliCorsiDiStudi[0]?.denominazione;
                    this.cicloCorsoRuoloSelected = cicloCorsoRuoloInStorage;
                    this.prevalorizzaCicloCorsoRuolo();
                    this.cicloCorsoRuoloSelected.denominazioneCorsoStudi = denominazioneCorsoStudiByCode;
                },
                error: _ => {
                    this.localStorageService.setDipartimentoRuoloCiclo(prevDipRuoloCiclo);
                }
            });
        }
    }

    prevalorizzaCicloCorsoRuolo(redirect: boolean = true): void {
        this.listaCicliCorsiRuoliGroupByCiclo = groupBy(this.listaRuoliCodiciDipartimenti, 'numeroCiclo');
        const cicloCorsoRuoloInStorage = this.localStorageService.getCicloCorsoRuolo(true);
        if (!this.isAreaAdministrator && !!cicloCorsoRuoloInStorage?.codiceCorsoStudi && !!cicloCorsoRuoloInStorage?.ruolo && !!cicloCorsoRuoloInStorage.ciclo) {
            this.setCicloCorsoRuoloFromStorage(cicloCorsoRuoloInStorage, redirect);
            this.checkAndSetCicliAmm();
        } else if (
            (this.gestionaleRoleSelectionChoiceSkipped && Object.keys(this.listaCicliCorsiRuoliGroupByCiclo)?.length === 1)
            ||
            (!this.isAmministratore && !this.isAreaAdministrator && Object.keys(this.listaCicliCorsiRuoliGroupByCiclo)?.length === 1)) {
            this.setCicloCorsoRuoloUnica(redirect);
            this.checkAndSetCicliAmm();
        } else {
            this.verificaArea(redirect);
        }
    }

    verificaArea(redirect: boolean = true) {
        if (!this.isAmministratore || this.gestionaleRoleSelectionChoiceSkipped) {
            this.openModalModificaCicloCorsoRuolo();
        } else if (this.isAmministratore && !!this.listaRuoliCodiciDipartimenti?.length && !this.isPrivacyOrTCSPaths()) {
            this.dialogAreaSelection(redirect);
        } else if (this.isAmministratore) {
            this.checkCicliAmm();
        }
    }


    checkCicliAmm(skip?: boolean) {
        const cicloAmmSelected = this.localStorageService.getCicloAmm(true);
        const cicli = this.userAmministrazioneInfoCicli.cicli.map(value => value.numeroCiclo);
        if (!cicloAmmSelected && cicli?.length > 1) {
            this.openModalModificaCicloAmm(skip);
        } else if (cicli?.length === 1) {
            this.localStorageService.setCicloAmm(cicli[0]);
            this.cicloAmmSelected$.next(cicli[0]);
            if (skip) {
                this.gestionaleRoleSelectionChoiceSkipped = true;
            }
            this.goToAmministrazione();
        } else if (cicli?.includes(cicloAmmSelected)) {
            this.setCicloAmmAndGoAmm(cicloAmmSelected);
            if (skip) {
                this.gestionaleRoleSelectionChoiceSkipped = true;
            }
        } else {
            this.logoutService.logout();
        }
    }

    private setCicloAmmAndGoAmm(cicloAmmSelected: number) {
        this.localStorageService.setCicloAmm(cicloAmmSelected);
        this.cicloAmmSelected = cicloAmmSelected;
        this.cicloAmmSelected$.next(cicloAmmSelected);
        this.goToAmministrazione();
    }

    checkAndSetCicliAmm() {
        const cicloAmmSelected = this.localStorageService.getCicloAmm(true);
        const cicli = this.userAmministrazioneInfoCicli?.cicli?.map(value => value.numeroCiclo)
        if (cicli?.includes(cicloAmmSelected)) {
            this.localStorageService.setCicloAmm(cicloAmmSelected);
            this.cicloAmmSelected = cicloAmmSelected;
            this.cicloAmmSelected$.next(cicloAmmSelected);
        }
    }

    get isCicloAmmSelectedInService(): Observable<Partial<CicloCorsoRuoloInterface>> {
        return this.cicloRuoloAmmSelected$.asObservable().pipe(filter(v => !!v?.ciclo && !!v?.ruolo));
    }

    get getTheMajorRole(): AuthorityType {
        if (this.userAmministrazioneInfoCicli?.roles?.includes(AuthorityType.SUPERADMIN)) {
            return AuthorityType.SUPERADMIN;
        } else if (this.userAmministrazioneInfoCicli?.roles?.includes(AuthorityType.PTAATENEOCSVANS)) {
            return AuthorityType.PTAATENEOCSVANS;
        } else if (this.userAmministrazioneInfoCicli?.roles?.includes(AuthorityType.PTAATENEO)) {
            return AuthorityType.PTAATENEO;
        }
        return undefined;
    }

    get isAreaAdministrator() {
        return window.location.pathname.startsWith('/' + PathEnum.ADMINISTRATOR);
    }

    dialogAreaSelection(redirect: boolean = true): void {
        const cicloCorsoRuoloInStorage = this.localStorageService.getCicloCorsoRuolo(true);
        const cicloAmmSelected = this.localStorageService.getCicloAmm(true);
        if (!cicloAmmSelected
            || (!this.isAreaAdministrator && (!cicloCorsoRuoloInStorage?.codiceCorsoStudi && !cicloCorsoRuoloInStorage?.ruolo && !cicloCorsoRuoloInStorage?.ciclo))) {
            const activeLang = this._translocoService.getActiveLang();
            const translation = this._translocoService.getTranslation().get(activeLang);
            const data: DialogWrapI = {
                title: 'choose_area.choose_area',
                icon: {
                    name: 'info',
                    color: 'info'
                },
                message: get(translation, 'choose_area.choose_area_message')
            }
            this.dialog.open(DialogAreaSelectionComponent, {
                data,
                panelClass: 'dialog-responsive-choose-area',
                disableClose: true,
                hasBackdrop: true,
            }).afterClosed().subscribe((value: AreaSeleztione) => {
                switch (value) {
                    case AreaSeleztione.AMMINISTRAZIONE:
                        this.checkCicliAmm(true);

                        break;
                    case AreaSeleztione.GESTIONALE:
                        if (Object.keys(this.listaCicliCorsiRuoliGroupByCiclo)?.length === 1) {
                            this.checkAndGetSottoruoli();
                            this.checkAndSetCicliAmm();
                        } else {
                            this.openModalModificaCicloCorsoRuolo();
                        }
                        break;
                    default:
                        this.dialogAreaSelection();
                }
            });
        } else if (cicloAmmSelected && this.isAreaAdministrator && !!this.userAmministrazioneInfoCicli?.cicli?.length) {
            const cicli = this.userAmministrazioneInfoCicli.cicli.map(value => value.numeroCiclo)
            if (cicli.includes(cicloAmmSelected)) {
                this.setCicloAmmAndGoAmm(cicloAmmSelected);
            } else {
                this.logoutService.logout()
            }
        }
    }

    setCicloCorsoRuoloFromStorage(cicloCorsoRuoloInStorage: CicloCorsoRuoloInterface, redirect: boolean = true): void {
        if (cicloCorsoRuoloInStorage?.ruolo === AuthorityType.STUDENTE && redirect) {
            this.goToStudentDetailsPage();
        }
        //this.setCicloCorsoRuoloSelected$(cicloCorsoRuoloInStorage);
    }

    setCicloCorsoRuoloUnica(redirect: boolean = true): void {
        const ciclo = head(Object.keys(this.listaCicliCorsiRuoliGroupByCiclo));
        const codici = uniqBy(this.listaCicliCorsiRuoliGroupByCiclo[ciclo]?.map(el => el.ruoloUtenteCorsoDiStudi?.corsoDiStudi), 'codiceEsse3');
        const ruoli = this.listaCicliCorsiRuoliGroupByCiclo[ciclo]?.filter(el => (el.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.codiceEsse3 === head(codici)?.codiceEsse3)).map((el => el.ruoloUtenteCorsoDiStudi?.ruolo));
        if (codici.length === 1) {
            const codice = (head(codici)?.codiceEsse3);
            if (ruoli.length === 1) {
                const ruolo = (head(ruoli));
                this.applica(ciclo, codice, ruolo, redirect);
            } else {
                this.openModalModificaCicloCorsoRuolo();
            }
        } else {
            this.openModalModificaCicloCorsoRuolo();
        }
    }

    applica(ciclo: string, codice: string, ruolo: AuthorityType, redirect: boolean = true): void {
        const denominazioneCorsoStudiByCode = this.listaCicliCorsiRuoliGroupByCiclo[ciclo]?.find(el => el.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.codiceEsse3 === codice).ruoloUtenteCorsoDiStudi?.corsoDiStudi?.cicliCorsiDiStudi[0]?.denominazione;
        const cicloCorsoRuolo: CicloCorsoRuoloInterface = {
            codiceCorsoStudi: codice,
            denominazioneCorsoStudi: denominazioneCorsoStudiByCode,
            ruolo: ruolo,
            ciclo: ciclo,
            codiceCorsoStudiCineca: this.listaCicliCorsiRuoliGroupByCiclo?.[ciclo]
                ?.find(el => el.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.codiceEsse3 === codice)
                ?.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.codiceCineca
        };
        const prevDipRuoloCiclo =  cloneDeep(this.localStorageService.dipartimentoRuoloCiclo);
        this.localStorageService.setDipartimentoRuoloCiclo(cicloCorsoRuolo);
        this.setCicloCorsoRuoloSelected$(cicloCorsoRuolo).subscribe({
            next: _ => {
                if (redirect) {
                    if (cicloCorsoRuolo?.ruolo === AuthorityType.STUDENTE) {
                        this.goToStudentDetailsPage();
                    } else {
                        this.router.navigate([PathEnum.HOME]);
                    }
                }
            },
            error: _ => {
                this.localStorageService.setDipartimentoRuoloCiclo(prevDipRuoloCiclo);
            }
        });

    }

    openModalModificaCicloCorsoRuolo(): void {

        const data: GenericComponentDialogConfig = {
            title: 'home.dep_role_cycle',
            icon: {
                show: true,
                name: 'edit',
                color: 'primary'
            },
            dismissible: true
        };
        this.dialog.open(DialogSetCicloCorsoRuoloComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        }).afterClosed().subscribe((value) => {
            if ((!this.cicloCorsoRuoloSelected || !this.cicloCorsoRuoloSelected?.ruolo) && this.localStorageService.getAccessToken()) {
                this.verificaArea();
            }
        });
    }

    openModalModificaCicloAmm(skip?: boolean): void {

        const data: DialogSetCicloComponentI = {
            title: 'administration.choose_cicle',
            icon: {
                name: 'edit',
                color: 'primary'
            },
            cicli: this.userAmministrazioneInfoCicli.cicli.map(value => value.numeroCiclo),
        };
        this.dialog.open(DialogSetCicloComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: true,
        }).afterClosed().subscribe((annulla) => {
            if (!annulla) {
                if (!this.cicloAmmSelected && this.localStorageService.getAccessToken()) {
                    this.verificaArea();
                } else {
                    this.goToAmministrazione();
                }
            }
            if (skip) {
                this.gestionaleRoleSelectionChoiceSkipped = true;
            }

        });
    }


    openDialogRuoloNonAutorizzato(): void {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        if (this.fuseConfirmationService.errorDuringAppInit) {
            // if assistenza is opened, do not open another dialog
            return;
        }
        this.fuseConfirmationService.open({
            message: get(translation, 'common.not_authorized', null),
            onBackdrop: {
                show: false,
                backdrop: true
            },
            actions: [
                {
                    function: () => this.goToLogin(),
                    label: get(translation, 'dialog.logout', null),
                    color: 'primary'
                },
                {
                    label: get(translation, 'common.ask_for_support', null),
                    function: (): void => {
                        this.fuseConfirmationService.goToAssistenzaBecauseOfInitError();
                    },
                    color: 'primary',
                    icon: 'help',
                }
            ]
        });

    }

    goToLogin(): void {
        this.logoutService.logout();
        this.router.navigate([PathEnum.SIGN_IN]);
    }


    goToStudentDetailsPage(): void {
        const idStudente = this.localStorageService.idStudenteByProfile;
        let currentUrl = window.location.href;
        if (idStudente) {
            let extras = {};
            // if there are fragments, reattach it
            if (currentUrl.includes('#')) {
                extras['fragment'] = currentUrl.substring(currentUrl.indexOf('#') + 1);
                currentUrl = currentUrl.substring(0, currentUrl.indexOf('#'))
            }
            if (currentUrl.includes(idStudente) &&
                (currentUrl.includes(PathEnum.ACTIVITY_LOG)
                    || currentUrl.includes(PathEnum.STUDY_PLAN)
                    || currentUrl.includes(PathEnum.BUDGET)
                    /*|| currentUrl.includes(PathEnum.ANVUR_SURVEYS)*/
                    || currentUrl.includes(PathEnum.MOBILITA)
                    || currentUrl.includes(PathEnum.MISSIONS))) {
                // this.router.navigate([currentUrl], extras);
            } else if (
                currentUrl.includes(PathEnum.SUPPORT)
                || currentUrl.includes(PathEnum.PRIVACY)
                || currentUrl.includes(PathEnum.TERMS_CONDITIONS)) {
                // this.router.navigate([currentUrl], extras);
            } else {
                this.router.navigate([PathEnum.STUDENTS, idStudente, PathEnum.PROFILE], extras);
            }
        }
    }

     getSottoruoliUtente$(hideLoader = true): Observable<string[]> {
        this.fuseConfirmationService.showLoader();
        return this.usersService.getSottoruoliUtente().pipe(
            map(sottoruoli => {
                if (!!sottoruoli) {
                    // getting sottoruoli utente
                    const sottoruoliUtenteStudente = sottoruoli?.sottoruoliUtenteStudente;
                    const sottoruoliUtenteCorsiDiStudi = sottoruoli?.sottoruoliUtenteCorsiDiStudi;
                    const sottoruoliUtente = sottoruoliUtenteStudente
                        .concat(sottoruoliUtenteCorsiDiStudi)
                        .sort((a, b) => a.ruolo.localeCompare(b.ruolo))
                        .map(item => item.ruolo);
                    this.currentSottoruoliUtente$.next(sottoruoliUtente);
                    return sottoruoliUtente;
                }
                return null;
            }),
            finalize(() => hideLoader ? this.fuseConfirmationService.hideLoader() : {}),
            takeUntil(this.destroy$)
        );
    }

    private checkIgnoreInitAndCleanStoragePaths(): void {
        // if there is the token and the url contains a path in which to not do init(), clean storage variables
        if (this.localStorageService.getAccessToken(true) &&
            ignoreInitPaths.some(path => window.location?.href?.includes(path))) {
            this.localStorageService.cleanAllClassVariablesAndStorage();
        }
    }

    goToGestionale(openInNewTab?: boolean) {
        const cicloCorsoRuoloInStorage = this.localStorageService.getCicloCorsoRuolo(true);
        if (!!cicloCorsoRuoloInStorage?.codiceCorsoStudi && !!cicloCorsoRuoloInStorage?.ruolo && !!cicloCorsoRuoloInStorage.ciclo) {
            this.setCicloCorsoRuoloFromStorage(cicloCorsoRuoloInStorage);
            this.checkAndSetCicliAmm();
        } else if (Object.keys(this.listaCicliCorsiRuoliGroupByCiclo)?.length === 1) {
            this.checkAndGetSottoruoli();
            this.checkAndSetCicliAmm();
        } else {
            this.openModalModificaCicloCorsoRuolo();
        }
        if (openInNewTab) {
            const url = this.router.serializeUrl(
                this.router.createUrlTree([PathEnum.HOME])
            );
            window.open(url, '_blank');
        } else {
            this.router.navigate([PathEnum.HOME], {replaceUrl: true})
        }

    }

    goToAmministrazione(openInNewTab?: boolean) {
        if (!this.cicloAmmSelected) {
            this.checkCicliAmm();
        } else if (openInNewTab) {
            const url = this.router.serializeUrl(
                this.router.createUrlTree([PathEnum.ADMINISTRATOR, PathEnum.HOME])
            );
            window.open(url, '_blank');
        } else {
            if (!this.isAreaAdministrator) {
                this.router.navigate([PathEnum.ADMINISTRATOR, PathEnum.HOME], {replaceUrl: true})
            }

        }
    }

    get codiceCorsoStudiAmmSelectedObs() {
        return this.codiceCorsoStudiAmmSelected$.asObservable();
    }


    cleanCorsoAmm(): void {
        if (this.isAreaAdministrator) {
            this.codiceCorsoStudiAmmSelected = undefined;
            this.codiceCorsoStudiAmmSelected$.next(undefined);
        }
    }

    setCodiceCorsoStudiAmm(codiceCorsoStudiAmm: string) {
        this.codiceCorsoStudiAmmSelected = codiceCorsoStudiAmm;
        this.codiceCorsoStudiAmmSelected$.next(codiceCorsoStudiAmm);
    }

    getCorsiAmm(): Array<CorsoDiStudiViewImpl> {
        return this.userAmministrazioneInfoCicli?.cicli?.find(ciclo => ciclo?.numeroCiclo === this.cicloAmmSelected)?.corsiDiStudi?.sort((a, b) => a.codiceEsse3.localeCompare(b.codiceEsse3));
    }


    get selectedInService() {
        return this.isAreaAdministrator ? this.isCicloAmmSelectedInService : this.isDipartimentoRuoloCicloSelectedInService;
    }

    checkAndGetSottoruoli() {
        let dipartimentoRuoloCiclo: CicloCorsoRuoloInterface;
        const ciclo = head(Object.keys(this.listaCicliCorsiRuoliGroupByCiclo));
        const codici = uniqBy(this.listaCicliCorsiRuoliGroupByCiclo[ciclo]?.map(el => el.ruoloUtenteCorsoDiStudi?.corsoDiStudi), 'codiceEsse3');
        const ruoli = this.listaCicliCorsiRuoliGroupByCiclo[ciclo]?.filter(el => (el.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.codiceEsse3 === head(codici)?.codiceEsse3)).map((el => el.ruoloUtenteCorsoDiStudi?.ruolo));
        if (codici.length === 1) {
            const codice = (head(codici)?.codiceEsse3);
            if (ruoli.length === 1) {
                const ruolo = (head(ruoli));
                const denominazioneCorsoStudiByCode = this.listaCicliCorsiRuoliGroupByCiclo[ciclo]?.find(el => el.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.codiceEsse3 === codice).ruoloUtenteCorsoDiStudi?.corsoDiStudi?.cicliCorsiDiStudi[0]?.denominazione;
                dipartimentoRuoloCiclo = {
                    codiceCorsoStudi: codice,
                    denominazioneCorsoStudi: denominazioneCorsoStudiByCode,
                    ruolo: ruolo,
                    ciclo: ciclo,
                    codiceCorsoStudiCineca: this.listaCicliCorsiRuoliGroupByCiclo?.[ciclo]
                        ?.find(el => el.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.codiceEsse3 === codice)
                        ?.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.codiceCineca
                };
                if (dipartimentoRuoloCiclo?.ruolo === AuthorityType.DOCENTE || dipartimentoRuoloCiclo?.ruolo === AuthorityType.PERSONALEPTA || dipartimentoRuoloCiclo?.ruolo === AuthorityType.ESTERNO) {
                    this.cicloCorsoRuoloForSottoruoliRequest$.next(dipartimentoRuoloCiclo);
                    this.getSottoruoliUtente$(false).pipe(
                        this.fuseConfirmationService.catchErrorCustom$(this.getSottoruoliUtente$(false)),
                    ).subscribe({
                        next: () => {
                            this.fuseConfirmationService.hideLoader();
                            this.setCicloCorsoRuoloSelected$(dipartimentoRuoloCiclo).subscribe({
                                next: _ => {
                                    this.localStorageService.setDipartimentoRuoloCiclo(dipartimentoRuoloCiclo);
                                    this.router.navigate([PathEnum.HOME]);
                                }
                            });
                        },
                    });
                } else {
                    this.setCicloCorsoRuoloSelected$(dipartimentoRuoloCiclo).subscribe({
                        next: _ => {
                            this.localStorageService.setDipartimentoRuoloCiclo(dipartimentoRuoloCiclo);
                            this.router.navigate([PathEnum.HOME]);
                        }
                    });
                }
            } else {
                this.openModalModificaCicloCorsoRuolo();
            }
        } else {
            this.openModalModificaCicloCorsoRuolo();
        }

    }

    public isPrivacyOrTCSPaths() {
        return window.location?.href?.includes(PathEnum.TERMS_CONDITIONS) || window.location?.href?.includes(PathEnum.PRIVACY);
    }

    private getFEVersion() {
        // removing the last version number for production (the dev number)
        if (environment.production) {
            const tagWithoutEnv = versionInfo?.tag?.split('-')?.[0];
            this.FEversion = tagWithoutEnv?.split('.')?.slice(0, 3)?.join('.');
        } else {
            this.FEversion = versionInfo?.tag;
        }
        console.log('FE version:', this.FEversion);
    }

    setCicloCorsoRuoloSelected$(dipartimentoRuoloCiclo: CicloCorsoRuoloInterface) {
        // when setting quadruple, check if ciclo or corso changed to retrieve the config
        let observable;
        if (!this.cicloCorsoRuoloSelected || dipartimentoRuoloCiclo.ciclo !== this.cicloCorsoRuoloSelected.ciclo
            || dipartimentoRuoloCiclo.codiceCorsoStudi !== this.cicloCorsoRuoloSelected.codiceCorsoStudi) {
            this.fuseConfirmationService.showLoader();
            this.ruoloSelectedForConfigurationFetch = dipartimentoRuoloCiclo.ruolo;
            observable = this.cicloCorsoConfiguration.getCicloCorsoConfiguration(dipartimentoRuoloCiclo.ciclo, dipartimentoRuoloCiclo.codiceCorsoStudi).pipe(
                finalize(() => this.fuseConfirmationService.hideLoader()),
                catchError(err => {
                    this.fuseConfirmationService.openErrorDialog(
                        {error: err},
                        this._translocoService,
                        () => {this.logoutService.logout()},
                        undefined,
                        'dialog.logout',
                        err?.error?.message);
                    throw new Error(err);
                })
            );
        } else {
            observable = of(dipartimentoRuoloCiclo);
        }
        return observable.pipe(
            tap(_ => {
                this.cicloCorsoRuoloSelected$.next(dipartimentoRuoloCiclo);
            }));
    }
}

// path in which to not do init()
export const ignoreInitPaths = [
    "esterni/completa-registrazione"
];
