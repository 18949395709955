<ng-container *transloco="let t ">
    <div class="w-full flex flex-col">

        <div class="w-full flex flex-row items-start gap-2" *ngFor="let ctrl of form.controls; let index = index; trackBy: trackByFn">
            <!---remove-padding-form-field-->
            <mat-form-field class="w-full" [appearance]="'outline'">
                <mat-label>
                    {{ labelNoTranslate || t(label)}}
                </mat-label>
                <input #inputFile hidden type="file"
                       (change)="onFileSelected($event, index)" placeholder="Carica file"
                       [accept]=acceptedFileList>

                <input matInput
                       readonly
                       hidden
                       [required]="index < (numeroMinimoDocumenti ?? 0)"
                       [formControl]="ctrl"
                >

                <input matInput
                       *ngIf="!ctrl?.value"
                       readonly
                       class="cursor-pointer"
                       (click)="open(inputFile)"
                >

                <input matInput
                       *ngIf="ctrl?.value"
                       readonly
                       class="cursor-pointer"
                       (click)="open(inputFile)"
                       [value]="(!pipe || (pipe && isFile(ctrl?.value))) ? getDocumentName(ctrl?.value) : (getDocumentName(ctrl?.value) | dynamicPipe: pipe)"
                >

                <button
                    *ngIf="!form.disabled"
                    mat-icon-button
                    type="button"
                    (click)="open(inputFile)"
                    matSuffix>
                    <mat-icon
                        class="icon-size-5"
                        [svgIcon]="'mat_outline:file_upload'"></mat-icon>
                </button>
                <button
                    *ngIf="ctrl?.value"
                    mat-icon-button
                    type="button"
                    (click)="download(index)"
                    matSuffix>
                    <mat-icon
                        class="icon-size-5"
                        [svgIcon]="'mat_outline:download'"></mat-icon>
                </button>
                <button
                    *ngIf="ctrl?.value"
                    mat-icon-button
                    type="button"
                    (click)="clear(index)"
                    matSuffix>
                    <mat-icon
                        class="icon-size-5 text-warn"
                        [svgIcon]="'mat_outline:delete'"></mat-icon>
                </button>
                <ng-content></ng-content>

                <mat-error *ngIf="ctrl.hasError('required')">
                    {{t('form.required')}}
                </mat-error>

            </mat-form-field>

            <div class="w-24 mt-1 bg-slate-50 flex flex-row items-center justify-start px-1 h-[55px] rounded-lg">
                <button
                    mat-icon-button
                    [disabled]="(form.controls.length <= numeroMinimoDocumenti) || form.controls.length <= 1 || form.disabled"
                    [matTooltip]="(form.controls.length <= numeroMinimoDocumenti) ? (t('form.min_number_docs') + ' ' + numeroMinimoDocumenti) : t('form.remove_document')"
                    type="button"
                    (click)="deleteDocument(index)"
                    matSuffix>
                    <mat-icon
                        class="icon-size-5 text-warn"
                        [svgIcon]="'mat_outline:remove'"></mat-icon>
                </button>
                <button
                    *ngIf="index === (form.controls.length - 1)"
                    [disabled]="(form.controls.length >= numeroMassimoDocumenti) || form.disabled"
                    [matTooltip]="(form.controls.length >= numeroMassimoDocumenti) ? (t('form.max_number_docs') + ' ' + numeroMassimoDocumenti) : t('form.add_document_same_type')"
                    mat-icon-button
                    type="button"
                    (click)="addDocument()"
                    matSuffix>
                    <mat-icon
                        class="icon-size-6 text-blue-600"
                        [svgIcon]="'mat_outline:add'"></mat-icon>
                </button>
            </div>





        </div>



    </div>


</ng-container>
