<ng-container *transloco="let t">


    <app-dialog-wrap [data]="data"
                     (close$)=closeDialog()>
        <div class="grid grid-cols-1 w-full md:grid-cols-2 lg:grid-cols-2 gap-x-5">
            <app-input-value-key [label]="'dialog_delibera.approval_date'"
                                 [value]="approvalDate">
            </app-input-value-key>

            <app-input-value-key [label]="'dialog_delibera.approval_number'"
                                 [value]="data.delibera?.numero">
            </app-input-value-key>

            <app-input-value-key [label]="'common.nota'"
                                 [value]="data.delibera?.note">
            </app-input-value-key>

        </div>

        <div class="flex flex-col w-full justify-center py-5 gap-4">

            <fuse-alert *ngIf="!data?.delibera?.url"
                        [appearance]="'outline'"
            >
                {{t('dialog_delibera.the_document_to_download_is_not_present')}}
            </fuse-alert>


            <fuse-alert *ngIf="data?.delibera?.url"
                        [showIcon]="false"
                        [appearance]="'outline'"
                        class="items-center">

                    <div
                        *ngIf="data?.delibera?.url"
                        class="flex flex-col md:flex-row gap-2 w-full justify-between items-center">
                        <div>
                            {{t('dialog_delibera.the_document_to_download_present')}}
                        </div>
                        <div
                            class="bg-gray-300 px-2 py-1 flex flex-row items-center gap-2 rounded cursor-pointer hover:bg-gray-400 font-medium max-w-40 sm:max-w-60"
                            (click)="downloadAttachment()">
                            <mat-icon style="color: gray !important;">download</mat-icon>
                            <mat-label
                                class="text-sm text-gray-600 truncate">{{ t('dialog_delibera.download_delibera') }}
                            </mat-label>
                        </div>
                    </div>

            </fuse-alert>

            <fuse-alert *ngIf="data?.delibera?.urlNotaDiTrasmissione"
                        [showIcon]="false"
                        [appearance]="'outline'"
                        class="items-center">

                <div
                    *ngIf="data?.delibera?.urlNotaDiTrasmissione"
                    class="flex flex-col md:flex-row gap-2 w-full justify-between items-center">
                    <div>
                        {{t('dialog_delibera.note_to_download_present')}}
                    </div>
                    <div
                        class="bg-gray-300 px-2 py-1 flex flex-row items-center gap-2 rounded cursor-pointer hover:bg-gray-400 font-medium max-w-40 sm:max-w-60"
                        (click)="downloadNotaTrasmissione()">
                        <mat-icon style="color: gray !important;">download</mat-icon>
                        <mat-label
                            class="text-sm text-gray-600 truncate">{{ t('dialog_delibera.download_note') }}
                        </mat-label>
                    </div>
                </div>

            </fuse-alert>

        </div>


    </app-dialog-wrap>


</ng-container>

