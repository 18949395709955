<ng-container *transloco="let t">

    <div class="flex flex-col flex-auto overflow-x-hidden w-full justify-start items-center gap-4"
        *ngIf="_isTabActive && !loadingFirstDraftSave && !errorOnSaveAtNewPresentationTabVisit">

        <!-- FUSE -ALERTS -->
        <fuse-alert *ngIf="offertaFormativa?.stato !== OffertaFormativaStatus.PUBBLICA"
                    type="warning"
                    class="w-full"
                    [appearance]="'outline'">
            {{t('study_plan_status.training_offer_not_published')}}
        </fuse-alert>

        <ng-container *ngIf="offertaFormativa?.stato === OffertaFormativaStatus.PUBBLICA">

            <fuse-alert *ngIf="noUpdates"
                        [appearance]="'outline'"
                        class="w-full">
                {{t('study_plan_status.no_update')}}
            </fuse-alert>

            <fuse-alert *ngIf="isLastUpdateToApprove()"
                        [appearance]="'outline'"
                        class="w-full">
                {{t('study_plan_status.study_plan_to_be_approved')}}
            </fuse-alert>

        </ng-container>

        <!--ACTIONS-->
        <div class="flex fx-row justify-end w-full items-center gap-4"
             *ngIf="canCreateBozza()">
            <!-- CREA BOZZA SE NON C'E' -->
            <ng-container *ngIf="canCreateBozza()">
                <button mat-flat-button
                        class="multi-line-button"
                        (click)="openModaleCreazione()"
                        color="primary">
                    <mat-icon class="mr-2">add_circle</mat-icon>
                    <span>{{t('study_plan_status.create_study_plan')}}</span>
                </button>
            </ng-container>
        </div>

        <ng-container *ngIf="offertaFormativa?.stato === OffertaFormativaStatus.PUBBLICA">

            <!-- CONTENT -->
            <ng-container *ngIf="draftData">
                <div class="flex flex-col flex-auto overflow-x-hidden w-full justify-start items-center gap-3 px-1">

                    <div class="flex flex-col md:flex-row gap-4 justify-between items-center w-full">

                        <!-- INFO BOZZA -->
                        <div class="flex flex-col md:flex-row items-center gap-4">
                            <div class="flex flex-col text-sm">
                                <div *ngIf="!!draftData?.data" class="flex items-center">
                                    <mat-icon color="accent" class="mr-1">calendar_month</mat-icon>
                                    <div class="flex flex-col">
                                        <span>{{t('study_plan_status.last_update')}} </span>
                                        <span>{{draftData?.data}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="border-l-gray-500 border-l-[1.5px] border-solid pl-3 h-6 items-center"
                                    *ngIf="!!draftData
                                        && !isNil(offertaFormativa?.configurazione?.numero_modifiche_max)
                                        && offertaFormativa?.stato === OffertaFormativaStatus.PUBBLICA
                                        && studentDetailsService.studyPlanDraftCreationMode === StudyPlanDraftCreationMode.READ_ONLY
                                        && !draftData.isNuovaPresentazione">
                                <span class="w-full text-center">
                                {{t('study_plan_status.residual_edit')}}
                                    <span class="font-semibold">{{Math.max(offertaFormativa?.configurazione?.numero_modifiche_max - (currentPianoInfoView?.modificheCount || 0), 0)}}</span>
                            </span>
                            </div>
                        </div>

                        <!--ACTIONS-->
                        <div class="flex flex-col md:flex-row justify-end items-center gap-4">
                            <!-- MODIFICA BOZZA -->
                            <button *ngIf="showButtonModificaCorsi && studentDetailsService.studyPlanDraftCreationMode === StudyPlanDraftCreationMode.READ_ONLY"
                                    mat-flat-button
                                    class="multi-line-button"
                                    (click)="editDraft()"
                                    color="primary">
                                <mat-icon class="mr-2">edit</mat-icon>
                                <span>{{t('study_plan_status.edit_draft')}}</span>
                            </button>
                            <!-- SALVA BOZZA -->
                            <button *ngIf="showButtonModificaCorsi && studentDetailsService.studyPlanDraftCreationMode === StudyPlanDraftCreationMode.EDIT"
                                    mat-flat-button
                                    class="multi-line-button"
                                    (click)="openSaveDraftConfirmDialog()"
                                    color="primary">
                                <mat-icon class="mr-2">save</mat-icon>
                                <span>{{t('study_plan_status.save_draft')}}</span>
                            </button>
                            <!-- ANNULLA MODIFICHE -->
                            <button *ngIf="showButtonModificaCorsi && studentDetailsService.studyPlanDraftCreationMode === StudyPlanDraftCreationMode.EDIT"
                                    mat-flat-button
                                    class="multi-line-button"
                                    (click)="cancelDraftEdit()"
                                    color="warn">
                                <mat-icon class="mr-2">close</mat-icon>
                                <span>{{t('study_plan_status.cancel_edit')}}</span>
                            </button>
                            <!-- SOTTOMETTI BOZZA SE C'E' -->
                            <div class="flex flex-col items-center gap-1"
                                *ngIf="!!draftData
                                        && offertaFormativa?.stato === OffertaFormativaStatus.PUBBLICA
                                        && studentDetailsService.studyPlanDraftCreationMode === StudyPlanDraftCreationMode.READ_ONLY
                                        && appInitService.cicloCorsoRuoloSelected?.ruolo === AuthorityType.STUDENTE">
                                <button [color]="'primary'"
                                        mat-raised-button
                                        class="multi-line-button"
                                        (click)="tryToSubmitPlan()"
                                        [disabled]="draftData | isNotCompliantWithOffertaConfiguration: offertaFormativa: draftData.draftInReadModeCompliance"
                                        [matTooltip]="(draftData | buildOffertaConfigurationErrorsTooltip: offertaFormativa: draftData.draftInReadModeCompliance)?.join('. ')">
                                    <mat-icon class="mr-2">send</mat-icon>
                                    {{t('student.submit_your_study_plan')}}
                                </button>
                            </div>
                        </div>
                    </div>

                    <fuse-alert [appearance]="'outline'"
                                *ngIf="studentDetailsService.studyPlanDraftCreationMode === StudyPlanDraftCreationMode.EDIT"
                                class="w-full mt-1"
                                type="info">
                        {{t('study_plan_status.read_guides_and_rules')}}
                        <a class="cursor-pointer underline text-blue-600"
                           (click)="onOpenGuideERegoleTab.emit()">
                            {{t('study_plan_status.rules_and_guide')}}.
                        </a>
                    </fuse-alert>

                    <ng-container *ngIf="(studentDetailsService.studyPlanDraftCreationMode == StudyPlanDraftCreationMode.EDIT ?
                        draftData.draftInEditModeCategoriesByYear : draftData.readModeCategoriesByYear) as pianoOrOffertaByYears">

                        <app-regole-compilazione-in-plan-creation class="w-full mb-1 mt-1"
                                                                  *ngIf="!!draftData && offertaFormativa?.configurazione"
                                                                  [draftData]="draftData"
                                                                  [pianoOrOffertaByYears]="pianoOrOffertaByYears"
                                                                  [currentPianoInfoView]="currentPianoInfoView"
                                                                  [offertaFormativa]="offertaFormativa">
                        </app-regole-compilazione-in-plan-creation>


                        <!-- TABELLE -->
                        <mat-tab-group class="w-full" appScrollRetainer *ngIf="!!this.pianoOrOffertaByYears"
                                       [selectedIndex]="yearTabSelectedIndex" animationDuration="0ms"><!--animationDuration="0ms-->
                            <ng-container *ngFor="let pianoOrOffertaOfYear of pianoOrOffertaByYears; trackBy: trackByIndex">
                                <mat-tab [disabled]="!pianoOrOffertaOfYear.canEditActivitiesForYear">
                                    <ng-template matTabLabel>
                                        <div [matTooltip]="!pianoOrOffertaOfYear.canEditActivitiesForYear ? t('study_plan_status.cannot_edit_year') : undefined"
                                             class="flex flex-row items-center gap-1">
                                            <mat-icon *ngIf="!pianoOrOffertaOfYear.canEditActivitiesForYear"
                                                      class="icon-size-5 mr-1">
                                                info
                                            </mat-icon>
                                            <mat-icon *ngIf="pianoOrOffertaOfYear.canEditActivitiesForYear"
                                                      class="icon-size-5"
                                                      [class]="pianoOrOffertaOfYear.compliant ? 'text-green-600': 'text-red-600'"
                                                      [svgIcon]="pianoOrOffertaOfYear.compliant ? 'heroicons_solid:check-circle' : 'heroicons_solid:x-circle'">
                                            </mat-icon>
                                            <span>{{pianoOrOffertaOfYear.year + ' ' + t('common.year')}}</span>
                                        </div>
                                    </ng-template>
                                    <ng-template matTabContent>
<!--                                        <div class="mb-6">
                                            <span *ngIf="pianoOrOffertaOfYear.canEditActivitiesForYear">{{t('study_plan_status.total_cfu_selected_for_year') + ': '}} <span class="font-semibold">{{pianoOrOffertaOfYear.totaleCfu?.toFixed(1)}} /</span></span>
                                            {{t('study_plan_status.total_cfu_to_select') + ': '}} <span class="font-semibold">60.0</span>
                                        </div>-->
                                        <app-study-plan-cfu-year-compliancy-info
                                            [canEditActivitiesForYear]="pianoOrOffertaOfYear.canEditActivitiesForYear"
                                            [totaleCfuPrevisti]="60"
                                            [totaleCfuSelezionati]="pianoOrOffertaOfYear.totaleCfu"
                                            [year]="pianoOrOffertaOfYear.year">
                                        </app-study-plan-cfu-year-compliancy-info>
                                        <div class="mt-3 grid-cols-4 min-h-8 w-full pl-6 pr-6 text-secondary mb-2 hidden gap-1"
                                             [ngClass]="{'custom-breakpoint-1:grid' : !isMenuOpen(), 'custom-breakpoint-2:grid' : isMenuOpen()}">
                                            <div class="font-medium col-span-2 rounded-md bg-gray-200 h-full flex flex-row items-center justify-center px-1 gap-1 mr-0.5">
                                                <div class="py-1 text-center">{{t('common.FR_label')}}</div>
                                            </div>
                                            <div class="font-medium col-span-2 rounded-md bg-gray-200 h-full flex flex-row items-center justify-center px-1 gap-1">
                                                <div class="py-1 text-center">{{t('common.FAR_label')}}</div>
                                            </div>
                                        </div>
                                        <!-- TAB GROUP -->
                                        <mat-tab-group class="w-full activities-tab-group class-hide-tab4 mt-3 lg:mt-0" animationDuration="0ms">
                                            <!-- TAB CORSI-->
                                            <mat-tab class="overflow-x-hidden" #tabCorsi>
                                                <ng-template matTabLabel>
                                                    <div class="flex flex-row items-center">
                                                        <mat-icon class="icon-size-5 mr-1"
                                                                  *ngIf="pianoOrOffertaOfYear.canEditActivitiesForYear"
                                                                  [class]="pianoOrOffertaOfYear.categoriesByActivityType?.[0]?.compliant ? 'text-green-600' : 'text-red-600'"
                                                                  [svgIcon]="pianoOrOffertaOfYear.categoriesByActivityType?.[0]?.compliant ? 'heroicons_solid:check-circle' : 'heroicons_solid:x-circle'">
                                                        </mat-icon>
                                                        <span class="text-sm xl:text-base">{{t('common.didactic_activities') | titlecase}}</span>
                                                    </div>
                                                </ng-template>
                                                <ng-template matTabContent>
                                                    <div class="w-full flex flex-col gap-4">
                                                        <div class="w-full flex flex-row justify-start items-center">
                                                            <app-study-plan-cfu-type-compliancy-info
                                                                [activityType]="ElementoOffertaFormativaType.CORSO"
                                                                [canEditActivitiesForYear]="pianoOrOffertaOfYear.canEditActivitiesForYear"
                                                                [vincoloCFU]="getVincoloCFU(pianoOrOffertaOfYear.annoInsegnamento, ElementoOffertaFormativaType.CORSO)"
                                                                [totaleCfuSelezionati]="pianoOrOffertaOfYear.categoriesByActivityType?.[0].totaleCfu">
                                                            </app-study-plan-cfu-type-compliancy-info>
                                                        </div>
                                                        <div class="mb-3">
                                                            <app-activities-categories
                                                                class="w-full"
                                                                [draftData]="draftData"
                                                                [pianoOrOffertaByYears]="pianoOrOffertaByYears"
                                                                [categoriesConfiguration]="pianoOrOffertaOfYear.categoriesByActivityType?.[0].categoriesConfiguration"
                                                                (tableClickAction)="tableClickAction.next($event); tableClickActionMeth($event)"
                                                                [showEditCategoriesAndActivities]="false"
                                                                [showArchived]="false"
                                                                [planPresentationEditMode]="studentDetailsService.studyPlanDraftCreationMode === StudyPlanDraftCreationMode.EDIT"
                                                                (onAddProposedActivity)="openAddEditProposedActivityDialog($event, undefined)"
                                                                (onAddPresumedActivity)="openAddEditPresumedActivityDialog($event, undefined)"
                                                                (onPresumedProposedUnselect)="openPresumedProposedUnselectDialog($event)"
                                                                (selectedActivitiesChanged)="selectedActivitiesChanged()"
                                                                [disabledActivityTooltipText]="t(pianoOrOffertaOfYear.canEditActivitiesForYear ? 'study_plan_status.activity_already_chosen' : 'study_plan_status.cannot_edit_year')"
                                                                [canEditActivitiesForYear]="pianoOrOffertaOfYear.canEditActivitiesForYear"
                                                                [studyPlanCreationMode]="true"
                                                                [trainingOffer]="offertaFormativa">
                                                            </app-activities-categories>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </mat-tab>

                                            <!-- TAB ATTIVITA EXTRA -->
                                            <mat-tab class="overflow-x-hidden" #tabExtra>
                                                <ng-template matTabLabel>
                                                    <div class="flex flex-row items-center">
                                                        <mat-icon class="icon-size-5 mr-1"
                                                                  *ngIf="pianoOrOffertaOfYear.canEditActivitiesForYear"
                                                                  [class]="pianoOrOffertaOfYear.categoriesByActivityType?.[1]?.compliant ? 'text-green-600' : 'text-red-600'"
                                                                  [svgIcon]="pianoOrOffertaOfYear.categoriesByActivityType?.[1]?.compliant ? 'heroicons_solid:check-circle' : 'heroicons_solid:x-circle'">
                                                        </mat-icon>
                                                        <span class="text-sm xl:text-base">{{t('student.extra_activities') | titlecase}}</span>
                                                    </div>
                                                </ng-template>
                                                <ng-template matTabContent>
                                                    <div class="w-full flex flex-col gap-4">
                                                        <div class="w-full flex flex-row justify-between items-center">
                                                            <app-study-plan-cfu-type-compliancy-info
                                                                [activityType]="ElementoOffertaFormativaType.ATTIVITAEXTRA"
                                                                [canEditActivitiesForYear]="pianoOrOffertaOfYear.canEditActivitiesForYear"
                                                                [vincoloCFU]="getVincoloCFU(pianoOrOffertaOfYear.annoInsegnamento, ElementoOffertaFormativaType.ATTIVITAEXTRA)"
                                                                [totaleCfuSelezionati]="pianoOrOffertaOfYear.categoriesByActivityType?.[1].totaleCfu">
                                                            </app-study-plan-cfu-type-compliancy-info>
                                                        </div>
                                                        <div class="mb-3">
                                                            <app-activities-categories
                                                                class="w-full"
                                                                [draftData]="draftData"
                                                                [pianoOrOffertaByYears]="pianoOrOffertaByYears"
                                                                [categoriesConfiguration]="pianoOrOffertaOfYear.categoriesByActivityType?.[1].categoriesConfiguration"
                                                                (tableClickAction)="tableClickAction.next($event); tableClickActionMeth($event)"
                                                                [showEditCategoriesAndActivities]="false"
                                                                [showArchived]="false"
                                                                [planPresentationEditMode]="studentDetailsService.studyPlanDraftCreationMode === StudyPlanDraftCreationMode.EDIT"
                                                                (onAddProposedActivity)="openAddEditProposedActivityDialog($event, undefined)"
                                                                (onAddPresumedActivity)="openAddEditPresumedActivityDialog($event, undefined)"
                                                                (onPresumedProposedUnselect)="openPresumedProposedUnselectDialog($event)"
                                                                [studyPlanCreationMode]="true"
                                                                (selectedActivitiesChanged)="selectedActivitiesChanged()"
                                                                [disabledActivityTooltipText]="t(pianoOrOffertaOfYear.canEditActivitiesForYear ? 'study_plan_status.activity_already_chosen' : 'study_plan_status.cannot_edit_year')"
                                                                [canEditActivitiesForYear]="pianoOrOffertaOfYear.canEditActivitiesForYear"
                                                                [trainingOffer]="offertaFormativa">
                                                            </app-activities-categories>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </mat-tab>

                                            <!-- ATTIVITA TRASVERSALI -->
                                            <mat-tab class="overflow-x-hidden">
                                                <ng-template matTabLabel>
                                                    <div class="flex flex-row items-center">
                                                        <mat-icon class="icon-size-5 mr-1"
                                                                  *ngIf="pianoOrOffertaOfYear.canEditActivitiesForYear"
                                                                  [class]="pianoOrOffertaOfYear.categoriesByActivityType?.[2]?.compliant ? 'text-green-600' : 'text-red-600'"
                                                                  [svgIcon]="pianoOrOffertaOfYear.categoriesByActivityType?.[2]?.compliant ? 'heroicons_solid:check-circle' : 'heroicons_solid:x-circle'">
                                                        </mat-icon>
                                                        <span class="text-sm xl:text-base">{{t('study_plan_status.student_transversal_activities') | titlecase}}</span>
                                                    </div>
                                                </ng-template>
                                                <ng-template matTabContent>
                                                    <app-transversal-activities
                                                        [draftData]="draftData"
                                                        [showVincoliCFU]="true"
                                                        [activityTypeData]="pianoOrOffertaOfYear?.categoriesByActivityType?.[2]"
                                                        [showButtonModificaCorsi]="showButtonModificaCorsi"
                                                        [currentPianoInfoView]="currentPianoInfoView"
                                                        [myIdStudente]="myIdStudente"
                                                        (onAddProposedActivity)="openAddEditProposedActivityDialog($event, undefined)"
                                                        (onAddPresumedActivity)="openAddEditPresumedActivityDialog($event, undefined)"
                                                        (onAddActivity)="addTransversalActivity($event)"
                                                        (onEditActivity)="editTransversalActivity($event)"
                                                        (onRemoveActivity)="removeTransversalActivity($event)"
                                                        (updatePianoDiStudiConfigurations)="updatePianoDiStudiConfigurations.next($event)"
                                                        (tableClickAction)="tableClickAction.next($event); tableClickActionMeth($event)"
                                                        [showArchivedAlert]="true"
                                                        [offertaFormativa]="offertaFormativa"
                                                        [annoInsegnamento]="pianoOrOffertaOfYear.annoInsegnamento"
                                                        [planPresentationEditMode]="studentDetailsService.studyPlanDraftCreationMode === StudyPlanDraftCreationMode.EDIT"
                                                        (selectedActivitiesChanged)="selectedActivitiesChanged()"
                                                        [canEditActivitiesForYear]="pianoOrOffertaOfYear.canEditActivitiesForYear"
                                                        [studyPlanCreation]="true"
                                                        [pianoOrOffertaByYears]="pianoOrOffertaByYears">
                                                    </app-transversal-activities>
                                                </ng-template>
                                            </mat-tab>

                                            <mat-tab [label]="' '"
                                                     [disabled]="true">
                                            </mat-tab>

                                        </mat-tab-group>
                                    </ng-template>
                                </mat-tab>
                            </ng-container>
                        </mat-tab-group>
                    </ng-container>

                </div>


            </ng-container>

        </ng-container>

    </div>


</ng-container>
