import {Injectable} from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class TrainingOfferService {

    private _isTrainingOfferValid = true;
    private _isShowingArchived = false;
    private _canEditOffer = true;

    constructor() {

    }


    get isTrainingOfferValid(): boolean {
        return this._isTrainingOfferValid;
    }

    set isTrainingOfferValid(value: boolean) {
        this._isTrainingOfferValid = value;
    }

    get isShowingArchived(): boolean {
        return this._isShowingArchived;
    }

    set isShowingArchived(value: boolean) {
        this._isShowingArchived = value;
    }



    get canEditOffer(): boolean {
        return this._canEditOffer;
    }

    set canEditOffer(value: boolean) {
        this._canEditOffer = value;
    }
}
