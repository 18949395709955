import {
    AttivitaTrasversaleStatus,
    AuthorityType,
    CategoriaInAggiornamentoInPianoDiStudiInfoView,
    CategoriaInPianoDiStudiInfoView,
    StudenteCicloInfoView
} from "../../../../../../../api-clients/generated/services";
import {
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../../../../../shared/components/table/model/generic-table-model";
import {
    checkRuoliTrasversali,
    visualizzaActionMenuAttivitaTrasversali,
    visualizzaApprovaDocumentoDottorandoForAttivitaTrasversale,
    visualizzaApproveRequestForAttivitaTrasversale,
    visualizzaDownloadDeliberaForAttivitaTrasversale,
    visualizzaDownloadDocumentoDottorandoForAttivitaTrasversale,
    visualizzaDownloadDocumentoSupervisoreForAttivitaTrasversale,
    visualizzaRegistraSuperamentoConDocumentoForAttivitaTrasversale,
    visualizzaRegistraSuperamentoForAttivitaTrasversale,
    visualizzaRifiutoDocumentoDottorandoForAttivitaTrasversale,
    visualizzaStatoAttivitaTrasversale,
    visualizzaUploadDeliberaForAttivitaTrasversale,
    visualizzaUploadDottorandoForAttivitaTrasversale,
} from "../../study-plan-utils";
import {getPresumedNumbersForDraft} from "../nuova-presentazione/configurazione-corsi-nuova-presentazione";
import {get, sortBy, trim} from "lodash";
import {Translation} from "@ngneat/transloco";


export function buildConfigurationForTransversalActivitiesInPianoApprovato(
    translation: Translation,
    category: CategoriaInPianoDiStudiInfoView | CategoriaInAggiornamentoInPianoDiStudiInfoView,
    currentRuolo: AuthorityType,
    student: StudenteCicloInfoView,
    currentUserId: string,
    currentSottoruolo?: AuthorityType,
): GenericTableConfigurationModel {

    const coursesPlan = category.corsi;

    //count get presumed numbers
    const presumedNumbers = getPresumedNumbersForDraft(category);

    const tableData = coursesPlan?.map(element => ({...(element?.datiCorsoOffertaFormativa ?? element),}));

    let myData = tableData?.map(
        element => ({
            ...element,
            id: element?.idAttivitaTrasversale,
            tipoDocumentoDottorandoFormatted: element?.tipoDocumentoDottorando || '-',
            tipoDocumentoSupervisoreFormatted: element?.tipoDocumentoSupervisore || '-',
            denominazione: element.denominazione ?? element.denominazioneAttivitaPresunta,
            denominazioneFormatted: (element.idAttivitaPresunta && !element.denominazione) ? (get(translation, 'study_plan_status.presumed_activity') + ' ' + presumedNumbers.pop()) : element.denominazione,
            idCategoriaOffertaFormativa: category.idCategoriaOffertaFormativa,
            isArchiviato: element.isArchiviato || element?.isAssociazioneArchiviata
        })
    );

    myData = sortBy(myData, [
        attivita => !attivita.idAttivitaProposta,
        attivita => !attivita.idAttivitaPresunta,
        attivita => trim(attivita.denominazioneFormatted)?.toLowerCase()
    ], ['asc', 'asc', 'asc']);

    const myConfig = {
        configuration: {
            data: myData,
            totalElements: myData?.length,
            isPaginatedBE: false,
            sticky: true,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.activity_typology',
                    colonnaKey: 'denominazioneFormatted',
                    flex: 20,
                    alsoShowChipIf: (activity) => {
                        if(activity.idAttivitaProposta){
                            return {
                                chipText: 'study_plan_status.proposed_activity',
                                iconName: 'school',
                                classes: 'text-blue-600'
                            };
                        } else if (activity.idAttivitaPresunta){
                            return {
                                chipText: activity.denominazione ? 'study_plan_status.presumed_activity_defined' : 'study_plan_status.presumed_activity_not_defined',
                                iconName: 'question_mark',
                                classes: activity.denominazione ? 'text-green-600' : 'text-orange-600'
                            };
                        } else {
                            return undefined;
                        }
                    },
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'common.status',
                    colonnaKey: 'statoAttivitaTrasversale',
                    flex: 18,
                    show: (value, element) => visualizzaStatoAttivitaTrasversale(element),
                    statusType: AttivitaTrasversaleStatus
                },
                /*                {
                                    tipo: TipoColonnaEnum.BOOLEAN,
                                    nomeColonna: 'student.passed',
                                    colonnaKey: 'superato',
                                    flex: 16,
                                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                                },*/
                /*                    {
                        tipo: TipoColonnaEnum.BOOLEAN,
                        nomeColonna: 'cycle_doctorate.student_document_required',
                        colonnaKey: 'presenzaDocumentoDottorando',
                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no',
                        flex: 8,
                    },*/
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.student_document',
                    colonnaKey: 'tipoDocumentoDottorandoFormatted',
                    flex: 20,
                },
                /*                    {
                                        tipo: TipoColonnaEnum.BOOLEAN,
                                        nomeColonna: 'cycle_doctorate.supervisor_document_required',
                                        colonnaKey: 'presenzaDocumentoSupervisore',
                                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no',
                                        flex: 8,
                                    },*/
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.supervisor_document',
                    colonnaKey: 'tipoDocumentoSupervisoreFormatted',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'cycle_doctorate.coordinator_required',
                    colonnaKey: 'presenzaDeliberaCoordinatore',
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no',
                    flex: 8,
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'study_plan_status.archived_activity',
                    colonnaKey: 'isArchiviato',
                    show: (_, activity) => !activity.idAttivitaProposta && !activity.idAttivitaPresunta,
                    flex: 8,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                },
                {
                    nomeColonna: 'user_list.action',
                    colonnaKey: 'azioni',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    button: [
                        {
                            nameIconButton: 'info',
                            click: TipoClickEnum.SHOW,
                            tooltip: 'common.show_detail',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'done',
                            click: TipoClickEnum.CONFERMA_IN_APPROVED_PLAN,
                            tooltip: 'study_plan_status.fill_presumed',
                            valueShow: 'id',
                            show: (_, activity) => (activity.idAttivitaPresunta && !activity.denominazione && currentRuolo === AuthorityType.STUDENTE),
                            color: 'accent',
                        },
                        {
                            nameIconButton: 'upload',
                            valueShow: ['statoAttivitaTrasversale'],
                            show: (value, element) => checkRuoliTrasversali(currentRuolo, currentSottoruolo)
                                && visualizzaActionMenuAttivitaTrasversali(element, currentRuolo, currentSottoruolo, student, currentUserId)
                                && !(element?.idAttivitaPresunta && !element?.denominazione)
                                && visualizzaUploadDeliberaForAttivitaTrasversale(element, currentSottoruolo)
                                && !element?.deliberaCoordinatoreAttivitaTrasversale,
                            click: TipoClickEnum.CARICA_DELIBERA_ATTIVITA_TRASVERSALE,
                            tooltip: 'study_plan_status.inserisci_delibera',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'upload',
                            valueShow: ['statoAttivitaTrasversale'],
                            show: (value, element) => checkRuoliTrasversali(currentRuolo, currentSottoruolo)
                                && visualizzaActionMenuAttivitaTrasversali(element, currentRuolo, currentSottoruolo, student, currentUserId)
                                && !(element?.idAttivitaPresunta && !element?.denominazione)
                                && visualizzaUploadDeliberaForAttivitaTrasversale(element, currentSottoruolo)
                                && element?.deliberaCoordinatoreAttivitaTrasversale,
                            click: TipoClickEnum.CARICA_DELIBERA_ATTIVITA_TRASVERSALE,
                            tooltip: 'study_plan_status.approves_activities',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'upload',
                            valueShow: ['statoAttivitaTrasversale'],
                            show: (value, element) => checkRuoliTrasversali(currentRuolo, currentSottoruolo)
                                && visualizzaActionMenuAttivitaTrasversali(element, currentRuolo, currentSottoruolo, student, currentUserId)
                                && !(element?.idAttivitaPresunta && !element?.denominazione)
                                && visualizzaUploadDottorandoForAttivitaTrasversale(element, currentRuolo),
                            click: TipoClickEnum.CARICA_DOCUMENTO_DOTTORANDO_ATTIVITA_TRASVERSALE,
                            tooltip: 'study_plan_status.inserisci_documento_dottorando',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'done',
                            valueShow: ['statoAttivitaTrasversale'],
                            show: (value, element) => checkRuoliTrasversali(currentRuolo, currentSottoruolo)
                                && visualizzaActionMenuAttivitaTrasversali(element, currentRuolo, currentSottoruolo, student, currentUserId)
                                && visualizzaApprovaDocumentoDottorandoForAttivitaTrasversale(element, currentSottoruolo, student, currentUserId),
                            click: TipoClickEnum.ACCETTA_DOCUMENTO_DOTTORANDO_ATTIVITA_TRASVERSALE,
                            tooltip: 'study_plan_status.confirm_student_document',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'refresh',
                            valueShow: ['statoAttivitaTrasversale'],
                            show: (value, element) => checkRuoliTrasversali(currentRuolo, currentSottoruolo)
                                && visualizzaActionMenuAttivitaTrasversali(element, currentRuolo, currentSottoruolo, student, currentUserId)
                                && !(element?.idAttivitaPresunta && !element?.denominazione)
                                && visualizzaApproveRequestForAttivitaTrasversale(element, currentRuolo),
                            click: TipoClickEnum.RICHIEDI_SUPERAMENTO_ATTIVITA_TRASVERSALE,
                            tooltip: 'study_plan_status.require_approval',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'done',
                            valueShow: ['statoAttivitaTrasversale'],
                            show: (value, element) => checkRuoliTrasversali(currentRuolo, currentSottoruolo)
                                && visualizzaActionMenuAttivitaTrasversali(element, currentRuolo, currentSottoruolo, student, currentUserId)
                                && !(element?.idAttivitaPresunta && !element?.denominazione)
                                && visualizzaRegistraSuperamentoForAttivitaTrasversale(element, currentSottoruolo, student, currentUserId),
                            click: TipoClickEnum.REGISTRA_SUPERAMENTO_ATTIVITA_TRASVERSALE,
                            tooltip: 'study_plan_status.register_passed',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'done',
                            valueShow: ['statoAttivitaTrasversale'],
                            show: (value, element) => checkRuoliTrasversali(currentRuolo, currentSottoruolo)
                                && visualizzaActionMenuAttivitaTrasversali(element, currentRuolo, currentSottoruolo, student, currentUserId)
                                && !(element?.idAttivitaPresunta && !element?.denominazione)
                                && visualizzaRegistraSuperamentoConDocumentoForAttivitaTrasversale(element, currentSottoruolo, student, currentUserId),
                            click: TipoClickEnum.REGISTRA_SUPERAMENTO_CON_DOCUMENTO_ATTIVITA_TRASVERSALE,
                            tooltip: 'study_plan_status.register_passed_with_doc',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'visibility',
                            valueShow: ['statoAttivitaTrasversale'],
                            show: (value, element) => checkRuoliTrasversali(currentRuolo, currentSottoruolo)
                                && visualizzaActionMenuAttivitaTrasversali(element, currentRuolo, currentSottoruolo, student, currentUserId)
                                && visualizzaDownloadDeliberaForAttivitaTrasversale(element, currentRuolo),
                            click: TipoClickEnum.SCARICA_DELIBERA_ATTIVITA_TRASVERSALE,
                            tooltip: 'common.visualizza_delibera',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'visibility',
                            valueShow: ['statoAttivitaTrasversale'],
                            show: (value, element) => checkRuoliTrasversali(currentRuolo, currentSottoruolo)
                                && visualizzaActionMenuAttivitaTrasversali(element, currentRuolo, currentSottoruolo, student, currentUserId)
                                && visualizzaDownloadDocumentoDottorandoForAttivitaTrasversale(element),
                            click: TipoClickEnum.SCARICA_DOCUMENTO_DOTTORANDO_ATTIVITA_TRASVERSALE,
                            tooltip: 'study_plan_status.visualizza_documento_dottorando',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'visibility',
                            valueShow: ['statoAttivitaTrasversale'],
                            show: (value, element) => checkRuoliTrasversali(currentRuolo, currentSottoruolo)
                                && visualizzaActionMenuAttivitaTrasversali(element, currentRuolo, currentSottoruolo, student, currentUserId)
                                && visualizzaDownloadDocumentoSupervisoreForAttivitaTrasversale(element),
                            click: TipoClickEnum.SCARICA_DOCUMENTO_SUPERVISORE_ATTIVITA_TRASVERSALE,
                            tooltip: 'study_plan_status.visualizza_documento_supervisore',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'close',
                            valueShow: ['statoAttivitaTrasversale'],
                            show: (value, element) => checkRuoliTrasversali(currentRuolo, currentSottoruolo)
                                && visualizzaActionMenuAttivitaTrasversali(element, currentRuolo, currentSottoruolo, student, currentUserId)
                                && visualizzaRifiutoDocumentoDottorandoForAttivitaTrasversale(element, currentSottoruolo, student, currentUserId),
                            click: TipoClickEnum.RIFIUTA_DOCUMENTO_DOTTORANDO_ATTIVITA_TRASVERSALE,
                            tooltip: 'study_plan_status.rejects_student_document',
                            color: 'accent'
                        },
                    ]
                },
            ],
            hidePaginator: true,
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_activities_for_year'
        }
    };
    return myConfig as GenericTableConfigurationModel;
}

