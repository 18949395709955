import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import {AuthorityType, UserSottoruoliInfoDTO, UsersService} from "../../../api-clients/generated/services";
import {LocalStorageService} from "../../shared/service/local-storage.service";
import {PathEnum} from "../../app.routing";
import {map, Observable, of, switchMap} from "rxjs";
import {AppInitService} from "../../shared/service/app-init.service";
import {filter, first, skip} from "rxjs/operators";
import {CicloCorsoRuoloInterface} from "../../shared/interface/CicloCorsoRuoloInterface";


@Injectable({
    providedIn: 'root'
})
export class OperazioniMassiveCaricamentoDelibereGuard implements CanActivate {
    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private localStorageService: LocalStorageService,
        private appInitService: AppInitService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const cicloCorsoRuolo = this.localStorageService.getCicloCorsoRuolo(true);
        const canActivate = OperazioniMassiveCaricamentoDelibereGuard.accessCondition(cicloCorsoRuolo);
        if(!canActivate) {
            this._router.navigate([PathEnum.HOME]);
        }
        return canActivate;
    }

    static accessCondition(cicloCorsoRuolo: CicloCorsoRuoloInterface) {
        return cicloCorsoRuolo?.ruolo === AuthorityType.PERSONALEPTA || cicloCorsoRuolo?.ruolo === AuthorityType.DOCENTE;
    }

}
