import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TableData,
    TipoClickEnum
} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AggiornamentoPianoDiStudiStatus,
    AttivitaTrasversaleStatus,
    AuthorityType,
    PageStudenteCicloPianoDiStudiViewImpl,
    PianiDiStudiService,
    PianoDiStudiStatus,
    StudenteCicloPianoDiStudiViewImpl,
    StudentiCicloService
} from "../../../../../../api-clients/generated/services";
import {filter, switchMap, tap} from "rxjs/operators";
import {
    BehaviorSubject,
    finalize,
    Observable,
    ObservedValueOf,
    of,
    OperatorFunction,
    Subscription,
    takeUntil
} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";
import {get, isEmpty} from "lodash";
import * as moment from "moment/moment";
import {PageEvent} from "@angular/material/paginator";
import {FormGroup} from "@angular/forms";
import {LogoutService} from "../../../../../shared/service/logout.service";
import {LocalStorageService} from "../../../../../shared/service/local-storage.service";
import {CAN_GO_AHEAD$, CURRENT_PROFILE, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {
    StudentiBarFilterContainerComponent
} from "../../../../../shared/components/studenti-topbar-filter-container/studenti-bar-filter-container.component";
import {ApprovazionePianiMassivaDataI} from "../approvazione-piani-steps-interface";
import {
    StudyPlanFilters,
    StudyPlansFilterService
} from "../../../requests/approvazione-piani-formativi/study-plans-sidebar-filter-container-request/study-plans-filter.service";
import {buildStudyPlansConfiguration} from "../../../requests/requests-utils";
import {
    StudyPlanWithDeliberaMode
} from "../../common-steps/show-with-delibera-for-plan-step/show-with-delibera-for-plan-step.component";
import {PathEnum} from "../../../../../app.routing";
import {Router} from "@angular/router";
import {
    GenericComponentDialogConfig
} from "../../../../../layout/common/generic-components/generic-components.interface";
import {TypeDialogFormEnum} from "../../../../../layout/common/generic-components/generic-components-enum";
import {
    GenericDialogComponent
} from "../../../../../layout/common/generic-components/generic-dialog/generic-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {HttpErrorResponse} from "@angular/common/http";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";


@Component({
    selector: 'app-scegli-studenti-piani-stepper',
    templateUrl: './scegli-studenti-piani.component.html',
    styleUrls: ['./scegli-studenti-piani.component.scss']
})
export class ScegliStudentiPianiComponent extends AbstractDefaultComponent implements OnInit, OnDestroy {

    loading: boolean;
    studentsListConfiguration: GenericTableConfigurationModel;
    private pageSize: number;
    private fieldsLabelMap: Map<string, string>;
    currentFilterListChipLabels: Array<string>;
    isFirstTime: boolean;
    showMiniLoader: boolean = false;
    filterContainerComponent: StudentiBarFilterContainerComponent;
    showTopbar: boolean;
    deliberaMode: StudyPlanWithDeliberaMode;
    private subscription: Subscription;
    idRigheSolaLettura: string[] = [];
    private idRigheDaDisabilitareContainer: { idRigheSolaLettura: string[] } = { idRigheSolaLettura: []};

    @ViewChild(StudentiBarFilterContainerComponent) set studentiBarFilterContainer(component: StudentiBarFilterContainerComponent) {
        this.filterContainerComponent = component;
    }

    constructor(private fuseConfirmationService: FuseConfirmationService,
                private translocoService: TranslocoService,
                private studentiCicloService: StudentiCicloService,
                private filterManagerService: StudyPlansFilterService,
                private logOutService: LogoutService,
                private router: Router,
                private pianiDiStudiService: PianiDiStudiService,
                private dialog: MatDialog,
                private localStorageService: LocalStorageService,
                @Inject(CURRENT_PROFILE) protected currentProfile: AuthorityType,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazionePianiMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.fieldsLabelMap = this.filterManagerService.fieldsLabelMap;
        this.isFirstTime = true;
        this.getDataAndFilterSubscribe();
        this.operazioneMassivaData$?.subscribe(inputOutputData => {
            this.deliberaMode = inputOutputData?.deliberaMode;
            // when confirm step forces refresh get the data and subscribe to filters
            if (inputOutputData.refreshData) {
                console.log('REFRESHING STUDENTS IN SCEGLI STUDENTI')
                this.studentsListConfiguration = undefined;
                this.idRigheSolaLettura = [];
                this.resetRefreshData();
                this.canGoNext$.next(false);
                this.getListaStudentiRequest$(this.isFirstTime, this.filterManagerService.filterApplied);
            }
        });
    }


    getListaStudentiRequest$(isFirstTime?: boolean, filters?: StudyPlanFilters, page: number = 0, size: number = 10,
                             sort?: string): void {
        this.getStudentiFormObs$(isFirstTime, filters, page, size, sort).subscribe({
            next: (value) => {
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {
                        this.logOutService.goToHome()
                    },
                    () => this.getListaStudentiRequest$(isFirstTime, filters, page, size, sort),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });
    }

    getStudentiFormObs$(isFirstTime?: boolean, filters?: StudyPlanFilters, page: number = 0, size: number = 10,
                        sort?: string): Observable<PageStudenteCicloPianoDiStudiViewImpl> {
        if (isFirstTime) {
            this.loading = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }
        return this.studentiCicloService.getPianiDiStudiStudentiForm(
            !!filters?.nominative ? filters?.nominative : undefined,
            undefined,
            undefined,
            undefined,
            [(this.currentProfile === AuthorityType.SUPERVISORE || this.currentProfile === AuthorityType.COSUPERVISORE) ?
                AggiornamentoPianoDiStudiStatus.DAAPPROVARE : AggiornamentoPianoDiStudiStatus.APPROVATOPARZIALE],
            (this.currentProfile === AuthorityType.SUPERVISORE || this.currentProfile === AuthorityType.COSUPERVISORE) ?
                undefined : (this.deliberaMode !== StudyPlanWithDeliberaMode.EDIT),
            (this.currentProfile === AuthorityType.SUPERVISORE || this.currentProfile === AuthorityType.COSUPERVISORE) ?
                undefined : (this.deliberaMode === StudyPlanWithDeliberaMode.FIRST_WITH_DELIBERA ? true :
                    (this.deliberaMode === StudyPlanWithDeliberaMode.FIRST_NO_DELIBERA ? false : undefined)),
            this.currentProfile === AuthorityType.COSUPERVISORE ? true : undefined,
            page, size,).pipe(
            tap((pageStudente) => {
                this.studentsListConfiguration = buildStudyPlansConfiguration(pageStudente, size, page, true, this.idRigheDaDisabilitareContainer);
            }),
            takeUntil(this.destroy$),
            finalize(() => {
                    if (isFirstTime) {
                        this.isFirstTime = false;
                        this.loading = false;
                    } else {
                        this.fuseConfirmationService.hideLoader();
                    }
                }
            )
        );


    }

    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }

    tableClickAction($event: ClickEvent): void {
        switch ($event.tipoClick) {
            case TipoClickEnum.SHOW:
                this.openPlanInNewTab(($event.value as StudenteCicloPianoDiStudiViewImpl)?.studenteCicloPianoDiStudiView?.idUtente);
                break;
            case TipoClickEnum.REFUSE_REQUEST:
                this.tryOpenRefuseDialog($event);
                break;
        }
    }

    private tryOpenRefuseDialog(event: ClickEvent) {
        if (this.operazioneMassivaData$.getValue().compileInfo?.find(
            s => s.aggiornamentoId === event.value.idUltimoAggiornamentoPiano)) {
            this.openDeselectFirstDialog();
        } else {
            this.openModaleRifiuto(event.value)
        }
    }

    openModaleRifiuto(pianoDaApprovare: StudenteCicloPianoDiStudiViewImpl): void {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const data: GenericComponentDialogConfig = {
            title: 'study_plan_status.refusal_message',
            message: get(translation, 'study_plan_status.refusal_message', null),
            icon: {
                show: true,
                name: 'edit',
                color: 'primary'
            },
            readOnly: false,
            actions: {
                confirm: {
                    show: true,
                    label: get(translation, 'dialog.confirm', null), icon: 'check',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.refusePlan(
                            form,
                            dialogRef,
                            pianoDaApprovare.studenteCicloPianoDiStudiView?.idPianoDiStudi,
                            pianoDaApprovare?.studenteCicloPianoDiStudiView?.idUltimoAggiornamentoPiano
                        )
                },
                cancel: {
                    show: true,
                    label: get(translation, 'dialog.cancel', null),
                }
            },
            dismissible: true,
            formConfig: [{
                show: true,
                name: 'motivazione_rifiuto',
                required: true,
                transloco: 'study_plan_status.reasons_for_refusal',
                type: TypeDialogFormEnum.TEXTAREA
            }, {
                show: false,
                name: 'piano',
                required: true,
            }, {
                show: false,
                name: 'type',
                required: true,
            }],
            valueForm: {
                motivazione_rifiuto: null,
                piano: pianoDaApprovare,
                type: AuthorityType.COORDINATORE
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }

    refusePlan(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>,
               idPiano: string,
               idAggiornamento: string): void {
        form?.disable({emitEvent: false});
        let esito = {
            approvato: false,
            motivazione_rifiuto: form?.get('motivazione_rifiuto')?.value
        };
        let http$ = this.currentProfile === AuthorityType.COORDINATORE ?
            this.pianiDiStudiService.approvaRifiutaAggiornamentoByCoordinatore(esito, idPiano, idAggiornamento) :
            this.pianiDiStudiService.approvaRifiutaAggiornamentoBySupervisore(esito, idPiano, idAggiornamento);
        http$?.pipe(
            takeUntil(this.destroy$),
            finalize(() => {
                form?.enable();
            })
        ).subscribe({
            next: (value) => {
                dialogRef?.close(value);
                this.idRigheSolaLettura = [...(this.idRigheSolaLettura ?? []), idAggiornamento];
                this.idRigheDaDisabilitareContainer.idRigheSolaLettura = this.idRigheSolaLettura;
                const activeLang = this.translocoService.getActiveLang();
                const translation = this.translocoService.getTranslation().get(activeLang);
                const message = get(translation, 'common.operation_success', null);
                this.fuseConfirmationService.openSnackBar({message: message});
            }, error: (err: HttpErrorResponse) => {
                const activeLang = this.translocoService.getActiveLang();
                const translation = this.translocoService.getTranslation().get(activeLang);
                const message = get(translation, 'common.not_loading_data', null);
                this.fuseConfirmationService.openSnackBar({
                    message: err?.error?.message || message,
                    error: err, type: SnackbarTypes.Error
                });
            }
        });
    }


    private openDeselectFirstDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
            title: get(translation, 'dialog.attention', null),
            message: get(translation, 'common.deselect_first', null),
            icon: {
                name: 'mat_solid:error_outline',
                color: 'error'
            },
            onBackdrop: {
                show: true,
                backdrop: false
            },
            actions: [
                {
                    color: 'accent',
                    label: get(translation, 'common.close', null), icon: 'close',
                },
            ]
        });
    }

    openPlanInNewTab(id: string): void {
        const url = this.router.serializeUrl(
            this.router.createUrlTree([PathEnum.STUDENTS, id, PathEnum.STUDY_PLAN])
        );
        window.open(url, '_blank');
    }


    onStudentDataTableChanged($event: TableData) {
        console.log('SELECTION CHANGED')
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$?.getValue(),
            compileInfo: $event.selectedRows.length > 0 ? $event.selectedRows.map(row => ({
                studentId: (row.data as StudenteCicloPianoDiStudiViewImpl)?.studenteCicloPianoDiStudiView?.idUtente,
                aggiornamentoId: row.key,
                planId: (row.data as StudenteCicloPianoDiStudiViewImpl)?.studenteCicloPianoDiStudiView?.idPianoDiStudi,
                cognome: (row.data as StudenteCicloPianoDiStudiViewImpl)?.studenteCicloPianoDiStudiView?.cognome,
                nome: (row.data as StudenteCicloPianoDiStudiViewImpl)?.studenteCicloPianoDiStudiView?.nome,
            })) : undefined,
        });
        this.canGoNext$.next($event.selectedRows.length > 0);
    }

    pageAction($event: PageEvent): void {
        console.log('PageEvent', $event);
        const page = $event.pageIndex;
        this.pageSize = $event.pageSize;
        this.getListaStudentiRequest$(false, this.getMainFormGroup()?.value, page, this.pageSize);
    }

    getMainFormGroup(): FormGroup<any> {
        return this.filterManagerService?.mainFormGroup;
    }

    catchErrorInStudentList$(inputObs: Observable<any>): OperatorFunction<unknown, ObservedValueOf<Observable<any>> | unknown> {
        return this.fuseConfirmationService.catchErrorCustom$(inputObs, {openModalError: {goToHome: true}}, true, true, true, false);
    }

    getListChipsLabel(): Array<string> {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (!isEmpty(this.filterManagerService.filterApplied)) {
            const entries: [string, any][] = Object.entries(this.filterManagerService.filterApplied)?.filter(item =>
                item[0] !== 'approvedStatus' && item[0] !== 'approvedValidity' && item[0] !== 'lastUpdateStatus' && item[0] !== 'showOnlyApprovableRequests');
            const filteredEntries = entries?.filter(item =>
                typeof item === 'boolean' || item.every(value => !!value));
            let labels = filteredEntries?.map(filteredItem => filteredItem?.map((element) => {
                    if (typeof element === 'string') {
                        if (!!this.fieldsLabelMap.get(element)) {

                            return get(translation, this.fieldsLabelMap.get(element), element);
                        } else {
                            return element;
                        }
                    } else if (typeof element === 'boolean') {
                        return get(translation, element ? 'common.yes' : 'common.no');
                    } else if (!!element?.codiceFiscale) {
                        const completeName = (element?.cognome || '') + ' ' + (element?.nome || '');
                        return (!!element?.cognome ? completeName : '')?.toUpperCase();
                    } else if (!!element?.id && !!element?.denominazione) {
                        return (!!element?.denominazione ? element.denominazione : '');
                    } else {
                        return element;
                    }
                }
            ).join(': '));
            console.log('Labels chips', labels);
            labels = labels.map(label => label?.endsWith(': ') ? label.slice(0, -2) : label);
            return labels;
        } else {
            return [];
        }
    }

    toogleFilterContainer(hasToogleInternalMenu: boolean): void {
        this.filterContainerComponent?.setFilterApplied();
        this.showTopbar = true;
    }


    private getSottoruolo() {
        return this.localStorageService.getSottoruoloCiclo();
    }

    resetFilters(): void {
        this.getMainFormGroup()?.reset();
        this.filterContainerComponent?.resetFilterApplied();
        this.filterManagerService?.checkApplyFilterClick$.next(true);
    }

    closeBar() {
        this.showTopbar = false;
    }

    private resetRefreshData() {
        console.log(this.operazioneMassivaData$.getValue())
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$.getValue(),
            refreshData: false
        })
    }

    private getDataAndFilterSubscribe() {

        if(this.currentProfile !== AuthorityType.COORDINATORE && this.isFirstTime){
            this.getListaStudentiRequest$(this.isFirstTime, this.filterManagerService?.filterApplied);
        }

        this.subscription = this.filterManagerService.checkApplyFilterClick$.asObservable().pipe(
            filter(Boolean),

            switchMap(value => {
                if (!this.isFirstTime) {
                    return this.getStudentiFormObs$(this.isFirstTime, this.filterManagerService?.filterApplied, 0, this.pageSize).pipe(
                        this.catchErrorInStudentList$(this.getStudentiFormObs$(this.isFirstTime, this.filterManagerService?.filterApplied, 0, this.pageSize))
                    )
                }
                return of(null);
            }),
            takeUntil(this.destroy$)
        ).subscribe(
            {
                next: (value) => {
                    this.isFirstTime = false;
                    this.filterManagerService.filterApplied$.next(this.getMainFormGroup().getRawValue());
                    this.currentFilterListChipLabels = this.getListChipsLabel();
                }
            }
        );
    }


    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        super.ngOnDestroy();
    }

    openFilters() {
        this.filterManagerService.openDrawer.next(true)
    }


    protected readonly PianoDiStudiStatus = PianoDiStudiStatus;
    protected readonly AggiornamentoPianoDiStudiStatus = AggiornamentoPianoDiStudiStatus;
    protected readonly StudyPlanWithDeliberaMode = StudyPlanWithDeliberaMode;
    protected readonly AttivitaTrasversaleStatus = AttivitaTrasversaleStatus;
    protected readonly AuthorityType = AuthorityType;
}
