import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {
    AddUpdatePianoDiStudiDTO,
    AggiornamentoPianoDiStudiInfoView,
    AggiornamentoPianoDiStudiStatus,
    AnnoRiferimentoValues,
    AttivitaExtraIdsDTO,
    AuthorityType,
    CategoriaInAggiornamentoInPianoDiStudiInfoView,
    CategoriaInPianoDiStudiInfoView,
    CorsoAggiornamentoPianoDiStudiInfoView,
    CorsoPianoDiStudiInfoView,
    ElementoOffertaFormativaType,
    EsitoRichiestaApprovazioneAttivitaExtra,
    GetAllegatoAttivitaExtraPianoDiStudiRequest,
    GuidaLinguaInfoView,
    MediaService,
    OffertaFormativaInfoViewImpl,
    OffertaFormativaStatus,
    OfferteFormativeService,
    PianiDiStudiService,
    PianoDiStudiInfoView,
    PianoDiStudiInfoViewImpl,
    PianoDiStudiStatus,
    QuestionarioSottomessoStatus,
    StudentiCicloService,
    UserRoleBasedInfoView,
    UsersService,
    UsersView
} from '../../../../../api-clients/generated/services';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, first, skip, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject, catchError, finalize, Observable, of, take, takeUntil} from 'rxjs';
import {AppInitService} from '../../../../shared/service/app-init.service';
import {
    AbstractDefaultComponent
} from '../../../../shared/abstracts/abstract-default-component/abstract-default-component';
import {TranslocoService} from '@ngneat/transloco';
import {FuseConfirmationConfig, FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import {ClickEvent, TipoClickEnum} from '../../../../shared/components/table/model/generic-table-model';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {DialogInfoComponent, DialogInfoI} from '../../../../shared/components/dialog-info/dialog-info.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
    StudyPlanAggiornamentoDataUI,
    StudyPlanCategoryDataUI,
    StudyPlanYearDataUI
} from '../../../../shared/interface/piano-di-studi-data-u-i';
import {get, head, isEmpty, orderBy, sortBy} from 'lodash';
import * as moment from 'moment/moment';
import {
    annoInsegnamentoValueToNumber,
    annoRiferimentoFromRomanNumeral,
    annoRiferimentoToRomanNumeral,
    getCosupPermessoApprovazione,
    makeFilename,
    openFileInBlankWindow
} from '../../../../shared/utils/utils';
import {StudentDetailManagerService} from '../../../../shared/service/student-detail-manager.service';
import {SnackbarTypes} from '../../../../../@fuse/services/confirmation/snackbar/snackbar.component';
import {LocalStorageService} from '../../../../shared/service/local-storage.service';
import {FormGroup} from '@angular/forms';
import {
    formGroupConfigInterface,
    GenericComponentDialogConfig
} from '../../../../layout/common/generic-components/generic-components.interface';
import {
    GenericDialogComponent
} from '../../../../layout/common/generic-components/generic-dialog/generic-dialog.component';
import {TypeDialogFormEnum} from '../../../../layout/common/generic-components/generic-components-enum';
import {HttpErrorResponse} from '@angular/common/http';
import {CicloCorsoRuoloInterface} from '../../../../shared/interface/CicloCorsoRuoloInterface';
import {calculateTotalCFUOfActivitiesInCategories, getFieldCfuByYear} from './study-plan-utils';
import {PageEvent} from '@angular/material/paginator';
import {docente} from '../../cycle/cycle-information/cycle-information.component';
import {optionFile} from '../../../../shared/costants/app-constants';
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';

import {LogoutService} from '../../../../shared/service/logout.service';
import {
    CurriculumAccordionComponent
} from "../../../../shared/components/curriculum-accordion/curriculum-accordion.component";
import {StudentDetailsService} from "../student-details.service";
import {TimeService} from "../../../../shared/service/time.service";
import {PathEnum} from "../../../../app.routing";
import {FuseNavigationService, FuseVerticalNavigationComponent} from "../../../../../@fuse/components/navigation";
import {StudyPlanDraftCreationMode} from "./tab-creation-study-plan/tab-creation-study-plan.component";
import {
    annoRiferimentoToCheckCategoryConfigurationYearCallback,
    createPlanCategoriesByActivityTypeConfigurations,
    getDraftCategoriesByYearInEditMode,
    getPlanCategoriesByYear, getPlanCategoryForCoursePresumedFill,
    getSelectedCoursesInCategoryForPresumedFill,
    UIActivityI
} from "./study-plans-categories-utils";
import {
    buildConfigurationForCoursesPlanInPianoApprovato
} from './study-plan-configurations/approvato/configurazione-corsi-piano-approvato';
import {
    buildConfigurationForCoursesPlanAggiornamenti
} from "./study-plan-configurations/aggiornamenti/configurazione-corsi-piani-da-aggiornamenti";
import {
    buildConfigurationForCoursesPlanToApprove
} from "./study-plan-configurations/da-approvare/configurazione-corsi-piani-da-approvare";
import {
    buildConfigurationForExtraActivitiesInPianoApprovato
} from "./study-plan-configurations/approvato/configurazione-attivita-extra-piano-approvato";
import {
    buildConfigurationForTransversalActivitiesInPianoApprovato
} from "./study-plan-configurations/approvato/configurazione-trasversali-piano-approvato";
import {
    buildConfigurationForExtraActivitiesPlanAggiornamenti
} from "./study-plan-configurations/aggiornamenti/configurazione-attivita-extra-piani-aggiornamenti";
import {
    buildConfigurationForTransversalActivitiesAggiornamenti
} from "./study-plan-configurations/aggiornamenti/configuration-attivita-trasversali-piano-aggiornamenti";
import {FuseConfirmationDialogComponent} from "../../../../../@fuse/services/confirmation/dialog/dialog.component";
import {
    buildConfigurationForExtraActivitiesPlanToApprove
} from "./study-plan-configurations/da-approvare/configurazione-attivita-extra-piani-da-approvare";
import {
    buildConfigurationForTransversalActivitiesToApprove
} from "./study-plan-configurations/da-approvare/configuration-attivita-trasversali-piano-da-approvare";
import {
    DialogPlanSummaryComponent,
    DialogPlanSummaryI,
    PlanSummaryByYearsI
} from "./dialog-plan-summary/dialog-plan-summary.component";
import {cloneDeep, maxBy} from "lodash-es";
import {
    DialogDefineCoursePresumedI,
    DialogDefinePresumedCourseComponent
} from "./dialog-define-presumed-course/dialog-define-presumed-course.component";
import {
    TrainingOfferCategoryDataUI
} from "../../training-offer-study-plan-shared-module/components/activities-categories/activities-categories.component";
import {getOfferCategoriesByType} from "../../cycle/training-offer/training-offer-utils";
import {
    buildConfigurationForCoursesInDraftEditMode
} from "./study-plan-configurations/nuova-presentazione/configurazione-corsi-nuova-presentazione";


@Component({
    selector: 'app-study-plan',
    templateUrl: './study-plan.component.html',
    styleUrls: ['./study-plan.component.scss']
})
export class StudyPlanComponent extends AbstractDefaultComponent implements OnInit {

    authorityType = AuthorityType;
    loading: any;
    myIdStudente: string;
    pianoDiStudiApproved: StudyPlanYearDataUI[];
    showButtonModificaCorsi: boolean = false;
    anniRiferimento = [
        AnnoRiferimentoValues.PRIMO,
        AnnoRiferimentoValues.SECONDO,
        AnnoRiferimentoValues.TERZO
    ];
    pianoAggiornamenti: StudyPlanAggiornamentoDataUI[];
    currentPianoInfoView: PianoDiStudiInfoViewImpl;
    dataAggiornamentoChip: AggiornamentoPianoDiStudiStatus;
    isCoordinatore: boolean = false;
    pianoDaApprovare: StudyPlanAggiornamentoDataUI;
    pianoApprovatoSupervisoreOrCoSupervisore: StudyPlanAggiornamentoDataUI;
    pianoDaApprovareDaModificare: StudyPlanAggiornamentoDataUI;
    pianoApprovatoSupervisoreOrCoSupervisoreDaModificare: StudyPlanAggiornamentoDataUI;
    _bozza = AggiornamentoPianoDiStudiStatus.BOZZA;
    _studente = AuthorityType.STUDENTE;
    currentRuolo: AuthorityType;
    cancelReturnValue = 'cancelled';
    startSlicePaginator: number;
    endSlicePaginator: number;
    initialAggiornamentiPageSize = 5;
    dialogButtonConfirm: string;
    dialogButtonCancel: string;
    maxValCFUErrorMessage: string;
    dataMaxSuperamentoCorso: Date = moment().toDate();
    isDocenteCorso: boolean;
    minValCFUErrorMessage: string;
    superamentoCorsoTitle: string;
    insersciAttestatoTitle: string;
    rifiutaAttestatoTitle: string;
    approvaAttivitaTitle: string;
    panelId: number;
    dialogButtonClose: string;
    yesString: string;
    noString: string;
    showBoxMessageForPlanCreation: boolean;
    draftExists: boolean;
    approvedExists: boolean;
    toApproveExists: boolean;
    _tabsGroup: MatTabGroup;
    guidaCoordinatore: GuidaLinguaInfoView[];
    guidaAteneo: GuidaLinguaInfoView[];
    numberOfArchivedRemovableActivities: number;
    archivedRemovableActivities: CorsoAggiornamentoPianoDiStudiInfoView[];
    currentSottoruolo: AuthorityType;
    sottoruoloPrimoApprovatore: AuthorityType;
    offertaFormativa: OffertaFormativaInfoViewImpl;
    currentLanguage: string;
    isSupervisoreOrApprovingCosupervisore: boolean;

    @ViewChild(MatTabGroup) set tabsGroup(tabsGroup: MatTabGroup) {
        if (tabsGroup && !this._tabsGroup) {
            this._tabsGroup = tabsGroup;
            this.handleFragmentNavigation(tabsGroup);
            // if to approve preselect to approve tabs
            this._tabsGroup._tabs.forEach((tab, index) => {
                if (tab.ariaLabel === this.StudyPlanFragments.DA_APPROVARE_PARZIALMENTE
                    || tab.ariaLabel === this.StudyPlanFragments.DA_APPROVARE_COORDINATORE) {
                    this._tabsGroup.selectedIndex = tab.position;
                }
            })
        }
    }

    protected readonly OffertaFormativaStatus = OffertaFormativaStatus;
    protected readonly AuthorityType = AuthorityType;
    protected readonly StudyPlanFragments = StudyPlanFragments;
    protected readonly AggiornamentoPianoDiStudiStatus = AggiornamentoPianoDiStudiStatus;
    protected readonly QuestionarioSottomessoStatus = QuestionarioSottomessoStatus;
    protected readonly PianoDiStudiStatus = PianoDiStudiStatus;
    protected readonly StudyPlanDraftCreationMode = StudyPlanDraftCreationMode;


    constructor(private studentiCicloService: StudentiCicloService,
                private router: Router,
                private appInitService: AppInitService,
                private _translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private usersService: UsersService,
                private mediaService: MediaService,
                private sanitizer: DomSanitizer,
                private dialog: MatDialog,
                private offerteFormativeService: OfferteFormativeService,
                private _changeDetectorRef: ChangeDetectorRef,
                private studentDetailManagerService: StudentDetailManagerService,
                private localStorageService: LocalStorageService,
                private pianiDiStudiService: PianiDiStudiService,
                private activatedRoute: ActivatedRoute,
                private logOutService: LogoutService,
                protected studentDetailsService: StudentDetailsService,
                private timeService: TimeService,
                private fuseNavigationService: FuseNavigationService,
                private translocoService: TranslocoService,
                private matDialog: MatDialog,
    ) {
        super();
        this.activatedRoute.parent.params.pipe(takeUntil(this.destroy$)).subscribe((value) => {
            if (value.id) {
                this.myIdStudente = value.id;
            } else {
                this.router.navigate(['/' + PathEnum.HOME]);
            }
        });
        this._translocoService.selectTranslate('dialog.confirm').subscribe(value => this.dialogButtonConfirm = value);
        this._translocoService.selectTranslate('dialog.cancel').subscribe(value => this.dialogButtonCancel = value);
        this._translocoService.selectTranslate('dialog.close').subscribe(value => this.dialogButtonClose = value);
        this._translocoService.selectTranslate('form.cfu_max').subscribe(value => this.maxValCFUErrorMessage = value);
        this._translocoService.selectTranslate('form.cfu_min').subscribe(value => this.minValCFUErrorMessage = value);
        this._translocoService.selectTranslate('common.superamento_corso').subscribe(value => this.superamentoCorsoTitle = value);
        this._translocoService.selectTranslate('common.inserisci_attestato').subscribe(value => this.insersciAttestatoTitle = value);
        this._translocoService.selectTranslate('study_plan_status.rejects_activities').subscribe(value => this.rifiutaAttestatoTitle = value);
        this._translocoService.selectTranslate('study_plan_status.approves_activities').subscribe(value => this.approvaAttivitaTitle = value);
        this._translocoService.selectTranslate('common.yes').subscribe(value => this.yesString = value);
        this._translocoService.selectTranslate('common.no').subscribe(value => this.noString = value);
    }


    /**
     * NON RIMUOVERE: Utilizzata nel Can Deactive del componente
     */
    getCanDeactivate(): boolean {
        return this.studentDetailsService.studyPlanDraftCreationMode === StudyPlanDraftCreationMode.EDIT;
    }

    /**
     * NON RIMUOVERE: Utilizzata nel Can Deactive del componente
     */
    canDeactivate(): Observable<boolean> {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const config: FuseConfirmationConfig = {
            title: get(translation, 'common.attention', null),
            message: get(translation, 'student.study_plan_alert', null),
            onBackdrop: {
                show: false,
                backdrop: true
            },
            icon: {
                show: true,
                name: 'mat_solid:info',
                color: 'basic'
            },
            actions: [
                {
                    color: 'accent',
                    label: get(translation, 'common.close', null),
                    icon: 'close',
                    closeValue: false,
                },
                {
                    color: 'primary',
                    label: get(translation, 'common.confirm', null),
                    closeValue: true,
                    icon: 'done'
                }
            ]
        };
        return this.matDialog.open(FuseConfirmationDialogComponent, {
            autoFocus: false,
            disableClose: true,
            data: config,
            panelClass: 'fuse-confirmation-dialog-panel'
        }).afterClosed().pipe(tap(v => console.log(v, 'v')));
    }

    ngOnInit(): void {
        this.studentDetailsService.studyPlanDraftCreationMode = StudyPlanDraftCreationMode.READ_ONLY;
        // resetting study plan status and validity for header chip when entering page
        this.studentDetailManagerService.checkCurrentStudyPlanStatusAndValidity$.next(undefined);
        this.getPianoDiStudiRequest();
        this.setSottoruoloConditions(this.localStorageService.getSottoruoloCiclo());
        this.localStorageService.checkCurrentSottoRuoloSelected$.asObservable().pipe(
            skip(1),
            tap(sottoruolo => this.setSottoruoloConditions(sottoruolo)),
            tap(() => this.fuseConfirmationService.showLoader()),
            switchMap(() => this.getPianoOnSottoruoloChange$()),
            takeUntil(this.destroy$),
            finalize(() => this.fuseConfirmationService.hideLoader()),
        ).subscribe(
            () => this.fuseConfirmationService.hideLoader() // perché non va in complete
        );

        this.onAggiornamentiPageChange({
            pageIndex: 0,
            pageSize: this.initialAggiornamentiPageSize,
            length: null
        });

        this._translocoService.langChanges$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(lang => this.currentLanguage = lang);


    }

    handleFragmentNavigation(tabsGroup: MatTabGroup) {
        console.log('handling fragments callback');
        this.activatedRoute.fragment.pipe(takeUntil(this.destroy$), first()).subscribe((fragment: string) => {
            console.log('typed fragment', fragment);
            const tabs = (tabsGroup?._tabs as any)._results;
            console.log(tabs);
            const matchIndex = tabs?.findIndex(x => x.ariaLabel === fragment);
            console.log('matched fragment with tab at position', matchIndex);
            if (matchIndex >= 0) {
                tabsGroup.selectedIndex = matchIndex;
            }
        });
    }

    getPianoOnSottoruoloChange$(): Observable<PianoDiStudiInfoViewImpl> {
        return this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            tap((info: CicloCorsoRuoloInterface) => this.currentRuolo = info.ruolo),
            switchMap(() => this.studentiCicloService.getPianoDiStudiStudente(this.myIdStudente)),
            tap(valuePlan => this.buildStudyPlanData(valuePlan ?? {})),
            tap(value => this.updatePianoDiStudiConfigurations(value ?? {}, true))
        );
    }

    getPianoDiStudiRequest(): void {
        this.loading = true;
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            tap((info: CicloCorsoRuoloInterface) => this.currentRuolo = info.ruolo),
            switchMap(() => this.timeService.updateCurrentTime()),
            // offer is needed only to the student for plan presentation
            switchMap(() => this.currentRuolo === AuthorityType.STUDENTE ? this.offerteFormativeService.getOffertaFormativa() : of(null)),
            tap((offertaFormativa: OffertaFormativaInfoViewImpl) => this.offertaFormativa = offertaFormativa),
            switchMap(() => this.studentiCicloService.getPianoDiStudiStudente(this.myIdStudente)),
            tap(valuePlan => this.buildStudyPlanData(valuePlan ?? {})),
            takeUntil(this.destroy$),
            finalize(() => this.loading = false)
        ).subscribe({
            next: (value: PianoDiStudiInfoViewImpl) => {
                this.updatePianoDiStudiConfigurations(value ?? {}, true);
            },
            error: (err) => {
                console.error(err);
                this.fuseConfirmationService.openErrorDialog({error: err},
                    this._translocoService,
                    () => this.logOutService.goToHome(),
                    () => this.getPianoDiStudiRequest(),
                    (this.currentRuolo !== AuthorityType.STUDENTE) ? 'common.go_to_home' : 'common.go_to_anagrafica',
                    err?.error?.message);
            }
        });
    }

    isStudyPlanEditable(piano: PianoDiStudiInfoView): boolean {
        const firstCondition = (piano?.stato === PianoDiStudiStatus.APPROVATO) || (piano?.stato === PianoDiStudiStatus.RIFIUTATO)
            || (piano?.stato === PianoDiStudiStatus.NONPRESENTATO);
        const lastUpdateStatus = head(orderBy(piano?.aggiornamenti,
            element => moment(new Date(element?.updatedAt)).format('YYYYMMDDHHmmss'),
            ['desc']
        ));
        this.dataAggiornamentoChip = lastUpdateStatus?.stato;
        this.sottoruoloPrimoApprovatore = lastUpdateStatus?.sottoruoloPrimoApprovatore;
        const secondCondition = (lastUpdateStatus?.stato === AggiornamentoPianoDiStudiStatus.BOZZA)
            || (lastUpdateStatus?.stato === AggiornamentoPianoDiStudiStatus.APPROVATO) || (lastUpdateStatus?.stato === AggiornamentoPianoDiStudiStatus.RIFIUTATO);
        const thirdCondition = (this.localStorageService.dipartimentoRuoloCiclo?.ruolo === AuthorityType.STUDENTE/* || this.localStorageService.dipartimentoRuoloCiclo?.ruolo === AuthorityType.SUPERADMIN*/);
        return (firstCondition && (!lastUpdateStatus?.stato || secondCondition) && thirdCondition);
    }

    tableClickAction(event: ClickEvent, isAggiornamento = false): void {
        if (event.tipoClick === TipoClickEnum.CHIP_DETAIL) {
            // La configurazione della tabella prevede che al click su una chip
            // venga emesso un evento il cui value è di tipo UsersView
            this.openInfoMember(event.value);
        } else if (event.tipoClick === TipoClickEnum.SHOW) {
            this.openDescriptionDialog(event?.value, isAggiornamento);
        } else if (event.tipoClick === TipoClickEnum.SUPERAMENTO_CORSO) {
            this.openSuperamentoCorsoDialog(event?.value, event.category);
        } else if (event.tipoClick === TipoClickEnum.CARICA_ATTESTATO) {
            this.openRichiediApprovazioneAttivitaExtraDialog(event?.value, event.category);
        } else if (event.tipoClick === TipoClickEnum.SHOW_ATTESTATO_ATTIVITA_EXTRA) {
            this.showAttestatoAttivitaExtra(event?.value);
        } else if (event.tipoClick === TipoClickEnum.SHOW_DELIBERA_ATTIVITA_EXTRA) {
            this.showDeliberaAttivitaExtra(event?.value);
        } else if (event.tipoClick === TipoClickEnum.CONFERMA_IN_APPROVED_PLAN) {
            this.openFillPresumedActivityInApprovedPlan(event.category as StudyPlanCategoryDataUI, event?.value);
        } else if (event.tipoClick === TipoClickEnum.APPROVA_ATTIVITA_EXTRA) {
            this.openApprovaAttivitaExtraDialog(event?.value);
        } else if (event.tipoClick === TipoClickEnum.RIFIUTA_ATTIVITA_EXTRA) {
            this.openRifiutaAttivitaExtraDialog(event?.value, event.category);
        }
    }

    buildStudyPlanData(pianoDiStudi: PianoDiStudiInfoViewImpl): void {
        this.showBoxMessageForPlanCreation = isEmpty(pianoDiStudi?.aggiornamenti);
        // BUILDING APPROVED PLAN DATA
        this.pianoDiStudiApproved = this.buildStudyPlanDataApproved(pianoDiStudi);
        // BUILDING AGGIORNAMENTI PLAN DATA
        this.pianoAggiornamenti = this.buildStudyPlanDataForAggiornamenti(pianoDiStudi);
        // BUILDING DA APPROVARE PLANS DATA
        this.pianoDaApprovare = this.buildStudyPlanDataForToApprove(pianoDiStudi,
            false, [AggiornamentoPianoDiStudiStatus.DAAPPROVARE]);
        this.pianoDaApprovareDaModificare = this.buildStudyPlanDataForToApprove(pianoDiStudi,
            true, [AggiornamentoPianoDiStudiStatus.DAAPPROVARE]);
        this.pianoApprovatoSupervisoreOrCoSupervisore = this.buildStudyPlanDataForToApprove(pianoDiStudi,
            false, [AggiornamentoPianoDiStudiStatus.APPROVATOPARZIALE]);
        this.pianoApprovatoSupervisoreOrCoSupervisoreDaModificare = this.buildStudyPlanDataForToApprove(pianoDiStudi,
            true, [AggiornamentoPianoDiStudiStatus.APPROVATOPARZIALE]);
    }

    buildStudyPlanDataApproved(pianoDiStudi: PianoDiStudiInfoViewImpl): StudyPlanYearDataUI[] {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        if (pianoDiStudi.aggiornamenti.find(agg => agg.stato === AggiornamentoPianoDiStudiStatus.APPROVATO)) {
            return this.anniRiferimento.map(year => {
                const categoriesByYear = getPlanCategoriesByYear(pianoDiStudi, year);
                return {
                    year: annoRiferimentoToRomanNumeral(year),
                    categoriesByActivityType: createPlanCategoriesByActivityTypeConfigurations(
                        categoriesByYear,
                        translation,
                        year,
                        (t, category) => buildConfigurationForCoursesPlanInPianoApprovato(
                            translation,
                            category,
                            this.localStorageService.profile?.id,
                            this.isDocenteCorso,
                            this.isSupervisoreOrApprovingCosupervisore,
                            this.currentRuolo
                        ),
                        (category) => buildConfigurationForExtraActivitiesInPianoApprovato(
                            translation,
                            category,
                            this.currentRuolo,
                            this.isSupervisoreOrApprovingCosupervisore
                        ),
                        (category) => buildConfigurationForTransversalActivitiesInPianoApprovato(
                            translation,
                            category,
                            this.currentRuolo,
                            this.studentDetailsService.studentDetails,
                            this.localStorageService?.getProfileResponse()?.id,
                            this.localStorageService.getSottoruoloCiclo(),
                        ),
                    ),
                    cfuPrevisti: getFieldCfuByYear('cfuPrevisti', year, pianoDiStudi) || 0,
                    cfuOttenuti: getFieldCfuByYear('cfuOttenuti', year, pianoDiStudi) || 0,
                    annoInsegnamento: year,
                }
            });
        } else {
            return undefined;
        }
    }

    openInfoMember(user: UsersView): void {
        let userResponse: UserRoleBasedInfoView;
        let imageResponse: SafeUrl;
        this.fuseConfirmationService.showLoader();
        if (this.appInitService.isAreaAdministrator) {
            this.appInitService.setCodiceCorsoStudiAmm(this.studentDetailsService.studentDetails.codiceCorsoDiStudiEsse3)
        }

        this.usersService.getUserInfoRoleBased(user?.codiceFiscale).pipe(
            tap((value: UserRoleBasedInfoView) => userResponse = value),
            switchMap((userResp: UserRoleBasedInfoView) => {
                    if (!!userResp.urlImmagineProfiloThumb && !!userResp.codiceFiscale) {
                        return this.mediaService.getImmagineDocenteOrPtaForm(user.urlImmagineProfiloThumb, userResp.codiceFiscale).pipe(
                            catchError(err => of(undefined)),
                            tap((image) => {
                                if (image) {
                                    const objectURL = URL.createObjectURL(image);
                                    imageResponse = this.sanitizer.bypassSecurityTrustUrl(objectURL);
                                } else {
                                    imageResponse = undefined;
                                }
                            })
                        );
                    } else {
                        return of(userResp);
                    }
                }
            ),
            takeUntil(this.destroy$),
            finalize(() => {
                if (this.appInitService.isAreaAdministrator) {
                    this.appInitService.cleanCorsoAmm();
                }
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: () => {
                const data: DialogInfoI = this.buildDataForDIalog(userResponse, imageResponse);
                this.dialog.open(DialogInfoComponent, {
                    data,
                    panelClass: 'dialog-responsive-full-screen',
                });
            },
            error: (error) => {
                this.fuseConfirmationService.openErrorDialog({error: error}, this._translocoService,
                    () => {
                    },
                    undefined,
                    'dialog.close');
            }
        });
    }

    buildDataForDIalog(user?: UserRoleBasedInfoView, image?: SafeUrl): DialogInfoI {
        return {
            imageSafeUrl: image,
            showImage: true,
            title: (user.cognome || '') + ' ' + (user.nome || ''),
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            ...docente(user, this._translocoService.getTranslation().get(this._translocoService.getActiveLang()))
        };
    }

    openDescriptionDialog(corso: UIActivityI, isAggiornamento: boolean): void {
        const data: DialogInfoI = this.buildInfoAggiuntiveForDIalog(corso, isAggiornamento);
        this.dialog.open(DialogInfoComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
            minWidth: '80vw'
        });
    }

    buildInfoAggiuntiveForDIalog(corso: UIActivityI, isAggiornamento: boolean): DialogInfoI {
        return {
            showImage: false,
            title: corso?.denominazione ?? corso?.descrizione,
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            ...this.infoAggiuntiveCorso(corso, isAggiornamento, this.currentRuolo)
        };
    }

    updatePianoDiStudiConfigurations(piano: PianoDiStudiInfoViewImpl, fillInfo: boolean = false): void {
        this.studentDetailsService.hasAPresentedPiano =
            !!(piano?.aggiornamenti?.find(p => p.stato === AggiornamentoPianoDiStudiStatus.APPROVATO));
        const lastEdit = this.studentDetailsService.hasAPresentedPiano ? maxBy(
            piano.aggiornamenti?.filter(a => a.stato !== AggiornamentoPianoDiStudiStatus.BOZZA),
            agg => moment(agg?.dataRichiesta)
        ) : undefined;
        this.studentDetailManagerService.setCurrentStudyPlanStatusAndValidity(piano.stato, !piano.isPianoNonValido, piano.motiviPianoNonValido, lastEdit?.stato);
        this.buildStudyPlanData(piano);
        this.currentPianoInfoView = piano ?? {};
        this.showButtonModificaCorsi = this.isStudyPlanEditable(piano);
        this.draftExists = !!piano?.aggiornamenti?.find(item => item.stato === AggiornamentoPianoDiStudiStatus.BOZZA)
        this.approvedExists = !!piano?.aggiornamenti?.find(item => item.stato === AggiornamentoPianoDiStudiStatus.APPROVATO)
            && this.offertaFormativa?.stato === OffertaFormativaStatus.PUBBLICA;
        this.toApproveExists = !!piano?.aggiornamenti?.find(item => item.stato === AggiornamentoPianoDiStudiStatus.DAAPPROVARE
                || item.stato === AggiornamentoPianoDiStudiStatus.APPROVATOPARZIALE)
            && this.offertaFormativa?.stato === OffertaFormativaStatus.PUBBLICA;
        if (fillInfo) {
            this.guidaCoordinatore = piano?.offertaFormativa?.guidaCoordinatoreLingue;
            this.guidaAteneo = piano?.offertaFormativa?.guidaAteneoLingue;
        }
    }

    buildStudyPlanDataForAggiornamenti(pianoDiStudi: PianoDiStudiInfoViewImpl): StudyPlanAggiornamentoDataUI[] {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        pianoDiStudi.aggiornamenti.sort((a, b) =>
            moment(a.updatedAt).isBefore(moment(b.updatedAt,)) ? 1 : -1,
        );
        return pianoDiStudi.aggiornamenti.filter(item => item.stato !== this._bozza)
            .map(aggiornamento => ({
                dataSecondaApprovazione: !!aggiornamento?.dataSecondaApprovazione ?
                    moment(new Date(aggiornamento?.dataSecondaApprovazione)).format('DD/MM/YYYY') : null,
                dataPrimaApprovazione: !!aggiornamento?.dataPrimaApprovazione ?
                    moment(new Date(aggiornamento?.dataPrimaApprovazione)).format('DD/MM/YYYY') : null,
                primoApprovatore: aggiornamento.primoApprovatore,
                secondoApprovatore: aggiornamento.secondoApprovatore,
                idPiano: pianoDiStudi.id,
                idAggiornamento: aggiornamento.id,
                sottoruoloPrimoApprovatore: aggiornamento.sottoruoloPrimoApprovatore,
                title: aggiornamento?.stato,
                motivazioneRifiuto: aggiornamento?.motivazioneRifiuto,
                data: moment(new Date(aggiornamento.updatedAt)).format('DD/MM/YYYY'),
                readModeCategoriesByYear: this.anniRiferimento.map(anno => {
                    const categoriesByYear = getPlanCategoriesByYear(aggiornamento, anno) as CategoriaInAggiornamentoInPianoDiStudiInfoView[];
                    return {
                        annoInsegnamento: anno,
                        year: annoRiferimentoToRomanNumeral(anno),
                        totaleCfu: calculateTotalCFUOfActivitiesInCategories(categoriesByYear),
                        categoriesByActivityType: createPlanCategoriesByActivityTypeConfigurations(
                            categoriesByYear,
                            translation,
                            anno,
                            (t, category) => buildConfigurationForCoursesPlanAggiornamenti(
                                translation,
                                category,
                                pianoDiStudi
                            ),
                            (category) => buildConfigurationForExtraActivitiesPlanAggiornamenti(
                                translation,
                                category,
                                pianoDiStudi
                            ),
                            (category) => buildConfigurationForTransversalActivitiesAggiornamenti(
                                translation,
                                category,
                                pianoDiStudi
                            ),
                            true
                        ),
                    }
                }),
                deliberaApprovazione: aggiornamento?.delibera,
                utenteCaricamentoDelibera: aggiornamento?.utenteCaricamentoDelibera,
                dataCaricamentoDelibera: aggiornamento?.dataCaricamentoDelibera,
                stato: aggiornamento?.stato,
            }));
    }


    buildStudyPlanDataForToApprove(pianoDiStudi: PianoDiStudiInfoViewImpl,
                                   checkModificaForApprover?: boolean,
                                   statusForFilter?: AggiornamentoPianoDiStudiStatus[]): StudyPlanAggiornamentoDataUI {
        const lastForStatus = head(orderBy(pianoDiStudi.aggiornamenti,
            element => moment(new Date(element.updatedAt)).format('YYYYMMDDHHmmss'),
            ['desc']
        ).filter(item => item.stato !== this._bozza)
            .filter((element) => {
                if (!statusForFilter || isEmpty(statusForFilter)) {
                    return true;
                } else {
                    return statusForFilter?.includes(element.stato);
                }
            }));
        if (!lastForStatus) {
            return undefined;
        }
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        return {
            dataSecondaApprovazione: !!lastForStatus?.dataSecondaApprovazione ? moment(new Date(lastForStatus?.dataSecondaApprovazione)).format('DD/MM/YYYY') : null,
            dataPrimaApprovazione: !!lastForStatus?.dataPrimaApprovazione ?
                moment(new Date(lastForStatus?.dataPrimaApprovazione)).format('DD/MM/YYYY') : null,
            primoApprovatore: lastForStatus.primoApprovatore,
            secondoApprovatore: lastForStatus.secondoApprovatore,
            idPiano: pianoDiStudi.id,
            idAggiornamento: lastForStatus.id,
            title: lastForStatus?.stato,
            motivazioneRifiuto: lastForStatus?.motivazioneRifiuto,
            sottoruoloPrimoApprovatore: lastForStatus.sottoruoloPrimoApprovatore,
            data: moment(new Date(lastForStatus.updatedAt)).format('DD/MM/YYYY'),
            readModeCategoriesByYear: this.anniRiferimento.map(anno => {
                const categoriesByYear = getPlanCategoriesByYear(lastForStatus, anno) as CategoriaInAggiornamentoInPianoDiStudiInfoView[];
                return {
                    annoInsegnamento: anno,
                    year: annoRiferimentoToRomanNumeral(anno),
                    categoriesByActivityType: createPlanCategoriesByActivityTypeConfigurations(
                        categoriesByYear,
                        translation,
                        anno,
                        (t, category) => buildConfigurationForCoursesPlanToApprove(
                            translation,
                            category,
                            pianoDiStudi
                        ),
                        (category) => buildConfigurationForExtraActivitiesPlanToApprove(
                            translation,
                            category,
                            pianoDiStudi
                        ),
                        (category) => buildConfigurationForTransversalActivitiesToApprove(
                            translation,
                            category,
                            pianoDiStudi,
                        ),
                        true
                    ),
                    totaleCfu: calculateTotalCFUOfActivitiesInCategories(categoriesByYear),
                }
            }),
            deliberaApprovazione: lastForStatus?.delibera,
            utenteCaricamentoDelibera: lastForStatus?.utenteCaricamentoDelibera,
            dataCaricamentoDelibera: lastForStatus?.dataCaricamentoDelibera,
            isNuovaPresentazione: lastForStatus?.isNuovaPresentazione,
        };
    }

    sottomettiPiano(planToSubmit: AddUpdatePianoDiStudiDTO): void {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.studentiCicloService.addOrUpdatePianoDiStudiStudente(
            planToSubmit,
            this.myIdStudente,
        ).pipe(
            takeUntil(this.destroy$),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (result) => {
                    this.updatePianoDiStudiConfigurations(result ?? {});
                    this._changeDetectorRef.detectChanges();
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'common.operation_success', null),
                        type: SnackbarTypes.Success,
                    });
                },
                error: (err) => {
                    if (err.status === 409 && err?.error?.code == 1034) {
                        this.fuseConfirmationService.open({
                                title: get(translation, 'common.attention', null),
                                icon: {
                                    show: true,
                                    name: 'error',
                                    color: 'warn'
                                },
                                onBackdrop: {
                                    show: false,
                                    backdrop: true,
                                },
                                listData: err?.error?.utentiConPianoNonPiuValido?.[0]?.errors?.map(e => ({mainText: e})),
                                actions: [
                                    {
                                        color: 'accent',
                                        label: get(translation, 'common.close', null), icon: 'close',
                                    },
                                ]
                            },
                        );
                    } else {
                        const errorConfig: FuseConfirmationConfig = {
                            title: get(translation, 'common.attention', null),
                            message: err?.error?.message || get(translation, 'common.not_loading_data', null),
                            onBackdrop: {
                                show: false,
                                backdrop: false,
                            },
                            actions: [
                                {
                                    label: get(translation, 'common.close', null), icon: 'close',
                                    hexColor: '#809dbd',
                                    hexColorText: '#FFFFFF',
                                },
                            ]
                        };
                        return this.fuseConfirmationService.open(errorConfig);
                    }
                }
            }
        );
    }


    setSottoruoloConditions(sottoruolo: AuthorityType): void {
        this.currentSottoruolo = sottoruolo;
        this.isCoordinatore = (sottoruolo === AuthorityType.COORDINATORE);
        this.isDocenteCorso = (sottoruolo === AuthorityType.DOCENTECORSO);
        this.isSupervisoreOrApprovingCosupervisore = this.currentSottoruolo === AuthorityType.SUPERVISORE ||
            (this.currentSottoruolo === AuthorityType.COSUPERVISORE && getCosupPermessoApprovazione(this.studentDetailsService.studentDetails, this.localStorageService.getProfileResponse()?.id));
    }

    openModaleRifiuto(pianoDaApprovare: StudyPlanAggiornamentoDataUI, authorityType: AuthorityType): void {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const data: GenericComponentDialogConfig = {
            title: 'study_plan_status.refusal_message',
            message: get(translation, 'study_plan_status.refusal_message', null),
            icon: {
                show: true,
                name: 'edit',
                color: 'primary'
            },
            readOnly: false,
            actions: {
                confirm: {
                    show: true,
                    label: get(translation, 'dialog.confirm', null), icon: 'check',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.refuseOrApprovaEvento(form, dialogRef, false)
                },
                cancel: {
                    show: true,
                    label: get(translation, 'dialog.cancel', null),
                }
            },
            dismissible: true,
            formConfig: [{
                show: true,
                name: 'motivazione_rifiuto',
                required: true,
                transloco: 'study_plan_status.reasons_for_refusal',
                type: TypeDialogFormEnum.TEXTAREA
            }, {
                show: false,
                name: 'piano',
                required: true,
            }, {
                show: false,
                name: 'type',
                required: true,
            }],
            valueForm: {
                motivazione_rifiuto: null,
                piano: pianoDaApprovare,
                type: authorityType
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive',
            hasBackdrop: data.dismissible,
            disableClose: true,
        }).afterClosed().subscribe((value) => {
            if (!!value && value !== this.cancelReturnValue) {
                this.updatePianoDiStudiConfigurations(value ?? {});
            }
        });
    }

    refuseOrApprovaEvento(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>, isApproved: boolean, typeInp?: AuthorityType, pianoDaApprovareInfo?: StudyPlanAggiornamentoDataUI): void {
        if (form?.enabled || isApproved) {
            form?.disable({emitEvent: false});
            const pianoDaApprovare = form?.get('piano')?.value || pianoDaApprovareInfo;
            let esito;
            if (isApproved) {
                this.fuseConfirmationService.showLoader();
                esito = {
                    approvato: true,
                };
            } else {
                esito = {
                    approvato: false,
                    motivazione_rifiuto: form?.get('motivazione_rifiuto')?.value
                };
            }
            let http$;
            const type = form?.get('type')?.value || typeInp;
            if (type === AuthorityType.COORDINATORE) {
                http$ = this.pianiDiStudiService.approvaRifiutaAggiornamentoByCoordinatore(
                    esito, pianoDaApprovare.idPiano, pianoDaApprovare.idAggiornamento
                );
            }
            if (type === AuthorityType.SUPERVISORE || type === AuthorityType.COSUPERVISORE) {
                http$ = this.pianiDiStudiService.approvaRifiutaAggiornamentoBySupervisore(
                    esito, pianoDaApprovare.idPiano, pianoDaApprovare.idAggiornamento
                );
            }
            http$?.pipe(
                takeUntil(this.destroy$),
                finalize(() => {
                    form?.enable();
                    if (isApproved) {
                        this.fuseConfirmationService.hideLoader();
                    }
                })
            ).subscribe({
                next: (value) => {
                    dialogRef?.close(value);
                    if (isApproved) {
                        this.updatePianoDiStudiConfigurations(value ?? {});
                    }
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.operation_success', null);
                    this.fuseConfirmationService.openSnackBar({message: message});
                }, error: (err: HttpErrorResponse) => {
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.not_loading_data', null);
                    this.fuseConfirmationService.openSnackBar({
                        message: err?.error?.message || message,
                        error: err, type: SnackbarTypes.Error
                    });
                }
            });
        }
    }

    openModaleApprovaPiano(pianoDaApprovare: StudyPlanAggiornamentoDataUI): void {
        this.studentDetailManagerService.openApprovalModal$(
            pianoDaApprovare,
            this.localStorageService.getSottoruoloCiclo(),
            this.numberOfArchivedRemovableActivities
        ).subscribe((result: PianoDiStudiInfoView) => {
            if (!!result && result !== this.cancelReturnValue) {
                // La result corrisponde al piano aggiornato
                this.updatePianoDiStudiConfigurations(result ?? {});
                this._changeDetectorRef.detectChanges();
            }
        });
    }

    openModaleConfermaPresentazionePiano(planToSubmit: AddUpdatePianoDiStudiDTO): void {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const planSummary = this.buildDraftSummaryForSubmit();
        const data: DialogPlanSummaryI = {
            title: 'student.submit_your_study_plan',
            message: get(translation, 'study_plan_status.plan_presentation_message', null),
            icon: {
                name: 'mat_outline:send',
                color: 'success'
            },
            planSummaryByYears: planSummary,
            onConfirm: () => this.sottomettiPiano(planToSubmit)
        };
        this.dialog.open(DialogPlanSummaryComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: true,
        });
    }

    onAggiornamentiPageChange($event: PageEvent): void {
        this.startSlicePaginator = (($event.pageIndex) * $event.pageSize);
        this.endSlicePaginator = (($event.pageIndex + 1) * $event.pageSize);
    }


    openSuperamentoCorsoDialog(corso: CorsoPianoDiStudiInfoView, category: CategoriaInPianoDiStudiInfoView): void {

        const data: GenericComponentDialogConfig = {
            title: corso?.denominazione,
            message: this.superamentoCorsoTitle,
            icon: {
                show: true,
                name: 'done_all',
                color: 'primary'
            },
            actions: {
                confirm: {
                    show: true,
                    label: this.dialogButtonConfirm,
                    color: 'primary',
                    function: (form, dialogRef) => this.inviaSuperamentoCorso(form, dialogRef, corso, category.idCategoriaOffertaFormativa)
                },
                cancel: {
                    show: true,
                    label: this.dialogButtonCancel,
                }
            },
            dismissible: true,
            formConfig: this.formGroupSuperamentoCorso(corso),
            valueForm: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                data_superamento: (moment().format('DD/MM/YYYY')),
                // eslint-disable-next-line @typescript-eslint/naming-convention
                cfu_ottenuti: corso?.cfuPrevisti,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                nota_superamento: ''
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });

    }

    inviaSuperamentoCorso(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>, corso: CorsoPianoDiStudiInfoView, idCategoriaOffertaFormativa: string): void {
        if (form.enabled) {
            form.disable({emitEvent: false});
            const body = {...form.getRawValue(), id_categoria_offerta_formativa: idCategoriaOffertaFormativa};
            this.pianiDiStudiService.registraSuperamentoCorsoPianoDiStudi(body, this.currentPianoInfoView?.id, corso?.idCorsoInOffertaFormativa).pipe(
                takeUntil(this.destroy$),
                finalize(() => form.enable())
            ).subscribe({
                next: (pianoDiStudi: PianoDiStudiInfoView) => {
                    this.currentPianoInfoView = pianoDiStudi ?? {};
                    this.buildStudyPlanData(pianoDiStudi);
                    dialogRef.close();
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.operation_success', null);
                    this.fuseConfirmationService.openSnackBar({message: message});
                },
                error: (err: HttpErrorResponse) => {
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.not_loading_data', null);
                    this.fuseConfirmationService.openSnackBar({
                        message: err?.error?.message || message,
                        error: err, type: SnackbarTypes.Error
                    });
                }
            });
        }
    }

    formGroupSuperamentoCorso(user): formGroupConfigInterface[] {
        return [
            {
                show: true,
                name: 'data_superamento',
                dateMax: this.dataMaxSuperamentoCorso,
                required: true,
                transloco: 'common.day',
                type: TypeDialogFormEnum.DATA,
            },
            {
                show: true,
                name: 'cfu_ottenuti',
                required: true,
                max: {number: user?.cfuPrevisti, text: this.maxValCFUErrorMessage},
                min: {number: 1, text: this.minValCFUErrorMessage},
                onlyPositiveWithOneDecimal: true,
                transloco: 'common.cfu_obtained',
                type: TypeDialogFormEnum.NUMBER,
            },
            {
                show: true,
                name: 'nota_superamento',
                required: false,
                transloco: 'common.nota',
                type: TypeDialogFormEnum.TEXTAREA
            },

        ];
    }

    formGroupSInserisciAttestato(user): formGroupConfigInterface[] {
        return [
            {
                show: true,
                name: 'file',
                transloco: 'study_plan_status.student_document_input_name',
                required: false,
                type: TypeDialogFormEnum.FILE,
                optionFile: optionFile
            },
        ];
    }


    openRichiediApprovazioneAttivitaExtraDialog(corso: CorsoPianoDiStudiInfoView, category: CategoriaInPianoDiStudiInfoView): void {
        const data: GenericComponentDialogConfig = {
            title: corso?.denominazione,
            icon: {
                show: true,
                name: 'upload',
                color: 'primary'
            },
            actions: {
                confirm: {
                    show: true,
                    label: this.dialogButtonConfirm,
                    color: 'primary',
                    function: (form, dialogRef) => this.richiediApprovazioneAttivitaExtra(form, dialogRef, corso, category.idCategoriaOffertaFormativa)
                },
                cancel: {
                    show: true,
                    label: this.dialogButtonCancel,
                }
            },
            dismissible: true,
            formConfig: this.formGroupSInserisciAttestato(corso),
            valueForm: {
                file: undefined
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });

    }


    richiediApprovazioneAttivitaExtra(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>, corso: CorsoPianoDiStudiInfoView, idCategoriaInOffertaFormativa: string): void {
        if (form.enabled) {
            form.disable({emitEvent: false});
            this.pianiDiStudiService.richiediApprovazioneAttivitaExtraPianoDiStudiForm(
                idCategoriaInOffertaFormativa,
                this.currentPianoInfoView?.id,
                {
                    id_corso_in_offerta_formativa: corso?.idCorsoInOffertaFormativa,
                    id_attivita_presunta: corso?.idAttivitaPresunta,
                    id_attivita_proposta: corso?.idAttivitaProposta,
                },
                form.getRawValue().file
            ).pipe(
                takeUntil(this.destroy$),
                finalize(() => form.enable())
            ).subscribe({
                next: (pianoDiStudi: PianoDiStudiInfoView) => {
                    this.currentPianoInfoView = pianoDiStudi ?? {};
                    this.buildStudyPlanData(pianoDiStudi);
                    dialogRef.close();
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.operation_success', null);
                    this.fuseConfirmationService.openSnackBar({message: message});
                },
                error: (err: HttpErrorResponse) => {
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.not_loading_data', null);
                    this.fuseConfirmationService.openSnackBar({
                        message: err?.error?.message || message,
                        error: err, type: SnackbarTypes.Error
                    });
                }
            });
        }
    }


    openRifiutaAttivitaExtraDialog(corso: CorsoPianoDiStudiInfoView, category: CategoriaInPianoDiStudiInfoView): void {
        const data: GenericComponentDialogConfig = {
            title: corso?.denominazione,
            message: this.rifiutaAttestatoTitle,
            icon: {
                show: true,
                name: 'close',
                color: 'primary'
            },
            actions: {
                confirm: {
                    show: true,
                    label: this.dialogButtonConfirm,
                    color: 'primary',
                    function: (form, dialogRef) => this.rifiutaAttivitaExtra(form, dialogRef, corso, category.idCategoriaOffertaFormativa)
                },
                cancel: {
                    show: true,
                    label: this.dialogButtonCancel,
                }
            },
            dismissible: true,
            formConfig: this.formGroupRifiutaAttestato(corso),
            valueForm: {
                motivazione_rifiuto: ''
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }

    rifiutaAttivitaExtra(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>, corso: CorsoPianoDiStudiInfoView, idCategoriaOffertaFormativa: string): void {
        if (form.enabled) {
            form.disable({emitEvent: false});
            const body: EsitoRichiestaApprovazioneAttivitaExtra = {
                approvato: false,
                motivazione_rifiuto: form.getRawValue()?.motivazione_rifiuto,
                id_categoria_offerta_formativa: idCategoriaOffertaFormativa,
            }
            const attivitaExtraIds: AttivitaExtraIdsDTO = {
                id_attivita_proposta: corso.idAttivitaProposta,
                id_attivita_presunta: corso.idAttivitaPresunta,
                id_corso_in_offerta_formativa: corso.idCorsoInOffertaFormativa,
            }
            this.pianiDiStudiService.verificaRichiestaApprovazioneAttivitaExtraPianoDiStudiForm(
                this.currentPianoInfoView?.id,
                {
                    id_corso_in_offerta_formativa: corso?.idCorsoInOffertaFormativa,
                    id_attivita_presunta: corso?.idAttivitaPresunta,
                    id_attivita_proposta: corso?.idAttivitaProposta,
                },
                body
            ).pipe(
                takeUntil(this.destroy$),
                finalize(() => form.enable())
            ).subscribe({
                next: (pianoDiStudi: PianoDiStudiInfoView) => {
                    this.currentPianoInfoView = pianoDiStudi ?? {};
                    this.buildStudyPlanData(pianoDiStudi);
                    dialogRef.close();
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.operation_success', null);
                    this.fuseConfirmationService.openSnackBar({message: message});
                },
                error: (err: HttpErrorResponse) => {
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.not_loading_data', null);
                    this.fuseConfirmationService.openSnackBar({
                        message: err?.error?.message || message,
                        error: err, type: SnackbarTypes.Error
                    });
                }
            });
        }
    }

    formGroupRifiutaAttestato(corso: UIActivityI): formGroupConfigInterface[] {
        return [
            {
                show: true,
                name: 'motivazione_rifiuto',
                required: true,
                transloco: 'study_plan_status.reasons_for_refusal',
                type: TypeDialogFormEnum.TEXTAREA
            },

        ];
    }


    openApprovaAttivitaExtraDialog(corso: UIActivityI): void {
        const data: GenericComponentDialogConfig = {
            title: corso?.denominazione,
            message: this.approvaAttivitaTitle,
            icon: {
                show: true,
                name: 'done',
                color: 'primary'
            },
            actions: {
                confirm: {
                    show: true,
                    label: this.dialogButtonConfirm,
                    color: 'primary',
                    function: (form, dialogRef) => this.approvaAttivitaExtra(form, dialogRef, corso)
                },
                cancel: {
                    show: true,
                    label: this.dialogButtonCancel,
                }
            },
            dismissible: true,
            formConfig: this.formGroupApprovaAttivita(corso),
            valueForm: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                data_superamento: (moment().format('DD/MM/YYYY')),
                // eslint-disable-next-line @typescript-eslint/naming-convention
                cfu_ottenuti: corso?.cfuPrevisti,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                nota_superamento: '',
                file: undefined
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }

    approvaAttivitaExtra(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>,
                         corso: UIActivityI): void {
        if (form.enabled) {
            form.disable({emitEvent: false});
            const body: EsitoRichiestaApprovazioneAttivitaExtra = {
                data_superamento: form.getRawValue().data_superamento,
                cfu_ottenuti: form.getRawValue().cfu_ottenuti,
                nota_superamento: form.getRawValue().nota_superamento,
                approvato: true,
                id_categoria_offerta_formativa: corso?.idCategoriaOffertaFormativa,
            };
            this.pianiDiStudiService.verificaRichiestaApprovazioneAttivitaExtraPianoDiStudiForm(
                this.currentPianoInfoView?.id,
                {
                    id_corso_in_offerta_formativa: corso?.idCorsoInOffertaFormativa,
                    id_attivita_presunta: corso?.idAttivitaPresunta,
                    id_attivita_proposta: corso?.idAttivitaProposta,
                },
                body,
                form.getRawValue().file
            ).pipe(
                takeUntil(this.destroy$),
                finalize(() => form.enable())
            ).subscribe({
                next: (pianoDiStudi: PianoDiStudiInfoView) => {
                    this.currentPianoInfoView = pianoDiStudi ?? {};
                    this.buildStudyPlanData(pianoDiStudi);
                    dialogRef.close();
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.operation_success', null);
                    this.fuseConfirmationService.openSnackBar({message: message});
                },
                error: (err: HttpErrorResponse) => {
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.not_loading_data', null);
                    this.fuseConfirmationService.openSnackBar({
                        message: err?.error?.message || message,
                        error: err, type: SnackbarTypes.Error
                    });
                }
            });
        }
    }

    formGroupApprovaAttivita(corso: UIActivityI): formGroupConfigInterface[] {
        console.log('formGroupApprovaAttivita')
        return [
            {
                show: true,
                name: 'data_superamento',
                dateMax: this.dataMaxSuperamentoCorso,
                required: true,
                transloco: 'common.day',
                type: TypeDialogFormEnum.DATA,
            },
            {
                show: true,
                name: 'cfu_ottenuti',
                required: true,
                max: {number: corso?.cfuPrevisti, text: this.maxValCFUErrorMessage},
                min: {number: 1, text: this.minValCFUErrorMessage},
                onlyPositiveWithOneDecimal: true,
                transloco: 'common.cfu_obtained',
                type: TypeDialogFormEnum.NUMBER
            },
            {
                show: true,
                name: 'nota_superamento',
                required: false,
                transloco: 'common.nota',
                type: TypeDialogFormEnum.TEXTAREA
            },
            {
                show: true,
                name: 'file',
                transloco: 'study_plan_status.document',
                required: false,
                type: TypeDialogFormEnum.FILE,
                optionFile: optionFile
            },

        ];
    }


    infoAggiuntiveCorso = (corso: UIActivityI, isAggiornamento: boolean, currentRuolo: AuthorityType) => {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const yesString = get(translation, 'common.yes', null);
        const noString = get(translation, 'common.no', null);
        const data = {
            readonlyData: [
                {
                    key: 'cycle_doctorate.ssd',
                    value: corso?.ssd,
                    hide: corso?.tipo !== ElementoOffertaFormativaType.CORSO,
                },
                {
                    key: 'total_hour_table.column_total_hour',
                    value: corso?.ore
                },
                {
                    key: 'requests.description_CFU',
                    value: corso?.descrizioneCfu,
                    hide: corso?.tipo !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE,
                },
                {
                    key: 'cycle_doctorate.disbursement_period',
                    value: corso?.periodoErogazione,
                    hide: corso?.tipo !== ElementoOffertaFormativaType.CORSO,
                },
                {
                    key: 'common.description',
                    value: corso?.descrizione,
                    hide: !corso?.descrizione
                },
                {
                    key: 'cycle_doctorate.final_check',
                    value: corso?.verificaFinale ? yesString : noString,
                    hide: corso?.tipo !== ElementoOffertaFormativaType.CORSO,
                },
                {
                    key: 'cycle_doctorate.mandatory',
                    value: corso?.isObbligatorio ? yesString : noString,
                },
                {
                    key: 'cycle_doctorate.student_document',
                    value: corso?.tipoDocumentoDottorando || '-',
                    hide: corso?.tipo !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE,
                },
                {
                    key: 'cycle_doctorate.supervisor_document',
                    value: corso?.tipoDocumentoSupervisore || '-',
                    hide: corso?.tipo !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE,
                },
                {
                    key: 'cycle_doctorate.coordinator_required',
                    value: corso?.presenzaDeliberaCoordinatore ? yesString : noString,
                    hide: corso?.tipo !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE,
                },
                {
                    key: 'study_plan_status.number_of_credits_obtained_and_required',
                    value: (!!corso?.cfuOttenuti ? corso?.cfuOttenuti : 0) + '/' + (!!corso?.cfuPrevisti ? corso?.cfuPrevisti : 0)
                },
                {
                    key: 'budget.approval_date',
                    value: !!corso?.deliberaCoordinatoreAttivitaTrasversale?.data ?
                        moment(corso?.deliberaCoordinatoreAttivitaTrasversale?.data, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
                    hide: !corso?.deliberaCoordinatoreAttivitaTrasversale?.data || currentRuolo === AuthorityType.STUDENTE
                },
                {
                    key: 'budget.approval_number',
                    value: corso?.deliberaCoordinatoreAttivitaTrasversale?.numero,
                    hide: !corso?.deliberaCoordinatoreAttivitaTrasversale?.numero || currentRuolo === AuthorityType.STUDENTE
                },
            ],
            bottomComponent: corso?.tipo !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE ? CurriculumAccordionComponent : undefined,
            bottomComponentData: corso?.tipo !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE ? corso.percorsiDiStudi : undefined,
            bottomComponentLabel: 'cycle_doctorate.reference_curriculum'
        };
        if (!!corso.motivazioneRifiuto) {
            data.readonlyData.push({
                key: 'study_plan_status.reasons_for_refusal',
                value: corso?.motivazioneRifiuto
            });
        }
        if (!isAggiornamento) {
            data.readonlyData.push({
                    key: 'student.executed',
                    value: corso?.superato ? this.yesString : this.noString,
                },
                {
                    key: corso?.tipo === ElementoOffertaFormativaType.CORSO ? 'student.exam_passed_date' : 'student.activity_executed_date',
                    value: corso?.dataSuperamento,
                    hide: !corso?.superato,
                },
                {
                    key: corso?.tipo === ElementoOffertaFormativaType.CORSO ? 'student.exam_passed_note' : 'student.activity_executed_note',
                    value: corso?.notaSuperamento,
                    hide: !corso?.superato,
                });
        }
        return data;
    };


    showAttestatoAttivitaExtra(corso: CorsoPianoDiStudiInfoView & { idCategoriaOffertaFormativa: string },): void {
        this.fuseConfirmationService.showLoader();
        const requestBody: GetAllegatoAttivitaExtraPianoDiStudiRequest = {
            id_categoria_in_offerta_formativa: corso.idCategoriaOffertaFormativa,
            nome_file: corso.attestatoAttivitaExtra,
            attivita_extra_ids: {
                id_attivita_proposta: corso.idAttivitaProposta,
                id_attivita_presunta: corso.idAttivitaPresunta,
                id_corso_in_offerta_formativa: corso.idCorsoInOffertaFormativa,
            }
        };
        this.pianiDiStudiService.getAllegatoAttivitaExtraPianoDiStudi(requestBody, this.currentPianoInfoView?.id).pipe(
            takeUntil(this.destroy$),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (value) => {
                    const activeLang = this.translocoService.getActiveLang();
                    const translation = this.translocoService.getTranslation().get(activeLang);
                    const fileNameToDownload = makeFilename(corso?.attestatoAttivitaExtra, true)
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'budget.file_show_success', null),
                        type: SnackbarTypes.Success,
                    });
                    openFileInBlankWindow(value, fileNameToDownload);
                    this.fuseConfirmationService.hideLoader();
                }, error: (err: HttpErrorResponse) => {
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.not_loading_data', null);
                    this.fuseConfirmationService.openSnackBar({
                        message: err?.error?.message || message,
                        error: err, type: SnackbarTypes.Error
                    });
                }
            });
    }


    showDeliberaAttivitaExtra(corso: CorsoPianoDiStudiInfoView & { idCategoriaOffertaFormativa: string },): void {
        this.fuseConfirmationService.showLoader();
        const requestBody: GetAllegatoAttivitaExtraPianoDiStudiRequest = {
            id_categoria_in_offerta_formativa: corso.idCategoriaOffertaFormativa,
            nome_file: corso.deliberaAttivitaExtra,
            attivita_extra_ids: {
                id_attivita_proposta: corso.idAttivitaProposta,
                id_attivita_presunta: corso.idAttivitaPresunta,
                id_corso_in_offerta_formativa: corso.idCorsoInOffertaFormativa,
            }
        };
        this.pianiDiStudiService.getAllegatoAttivitaExtraPianoDiStudi(requestBody, this.currentPianoInfoView?.id)
            .pipe(
                takeUntil(this.destroy$),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe(
            {
                next: (value) => {
                    const activeLang = this.translocoService.getActiveLang();
                    const translation = this.translocoService.getTranslation().get(activeLang);
                    const fileNameToDownload = makeFilename(corso?.deliberaAttivitaExtra, true);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'budget.file_show_success', null),
                        type: SnackbarTypes.Success,
                    });
                    openFileInBlankWindow(value, fileNameToDownload);
                    this.fuseConfirmationService.hideLoader();
                }, error: (err: HttpErrorResponse) => {
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.not_loading_data', null);
                    this.fuseConfirmationService.openSnackBar({
                        message: err?.error?.message || message,
                        error: err, type: SnackbarTypes.Error
                    });
                }
            });
    }


    onActivityTabChange($event: MatTabChangeEvent) {
        /*
                if($event.tab.ariaLabel === StudyPlanFragments.NUOVA_PRESENTAZIONE) {
                    console.log(this.numberOfArchivedRemovableActivities)
                    const bozza = this.currentPianoInfoView?.aggiornamenti?.find(item => item.stato === this._bozza);
                    if(!!bozza && this.numberOfArchivedRemovableActivities > 0) {
                        const activeLang = this._translocoService.getActiveLang()
                        const translation = this._translocoService.getTranslation().get(activeLang)
                        this.fuseConfirmationService.open({
                            title: get(translation, 'study_plan_status.remove_archived_activities', null),
                            message: get(translation, 'study_plan_status.remove_archived_activities_message', null) + ' (' + this.numberOfArchivedRemovableActivities + ')',
                            icon: {
                                name: 'mat_solid:error_outline',
                                color: 'warning'
                            },
                            onBackdrop: {
                                show: true,
                                backdrop: false
                            },
                            actions: [
                                {
                                    color: 'accent',
                                    label: get(translation, 'common.close', null), icon: 'close',
                                },
                            ]
                        });
                    }
                }
        */
    }

    openSbloccaBloccaPresentazionePianoNonValidoDialog(sblocca: boolean) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const title = sblocca ? 'study_plan_status.unlock_plan_invalid' : 'study_plan_status.lock_plan_invalid';
        const message = sblocca ? 'study_plan_status.unlock_plan_invalid_message' : 'study_plan_status.lock_plan_invalid_message';
        this.fuseConfirmationService.open({
                title: get(translation, title, null),
                message: get(translation, message, null),
                icon: {
                    name: 'mat_outline:warning',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.confirm', null), icon: 'check',
                        function: () => this.sbloccaBloccaPresentazionePianoNonValidoRequest(sblocca)
                    }]
            }
        );
    }

    openSbloccaBloccaPresentazioneLimitsPianoDialog(sblocca: boolean) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const title = sblocca ? 'study_plan_status.unlock_first_presentation' : 'study_plan_status.lock_first_presentation';
        const message = sblocca ? 'study_plan_status.unlock_first_presentation_message' : 'study_plan_status.lock_first_presentation_message';
        this.fuseConfirmationService.open({
                title: get(translation, title, null),
                message: get(translation, message, null),
                icon: {
                    name: 'mat_outline:warning',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.confirm', null), icon: 'check',
                        function: () => this.sbloccaBloccaPresentazionePianoRequest(sblocca)
                    }]
            }
        );
    }

    sbloccaBloccaPresentazionePianoNonValidoRequest(sblocca: boolean) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.pianiDiStudiService.sbloccaSottomissionePianoDiStudiNonValido(this.currentPianoInfoView?.id, !sblocca)),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (pianoDiStudi: PianoDiStudiInfoViewImpl) => {
                this.updatePianoDiStudiConfigurations(pianoDiStudi ?? {}, true);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'common.operation_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err},
                    this._translocoService,
                    () => {
                    },
                    () => this.sbloccaBloccaPresentazionePianoNonValidoRequest(sblocca),
                    'common.go_to_home',
                    err?.error?.message);
                console.log(err)
            }
        });
    }

    sbloccaBloccaPresentazionePianoRequest(sblocca: boolean) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.pianiDiStudiService.sbloccaPresentazionePianoDiStudi(this.currentPianoInfoView?.id, !sblocca)),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (pianoDiStudi: PianoDiStudiInfoViewImpl) => {
                this.updatePianoDiStudiConfigurations(pianoDiStudi ?? {}, true);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'common.operation_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err},
                    this._translocoService,
                    () => {
                    },
                    () => this.sbloccaBloccaPresentazionePianoRequest(sblocca),
                    'common.go_to_home',
                    err?.error?.message);
                console.log(err)
            }
        });
    }


    public openGuideERegoleTab() {
        const tabs = (this._tabsGroup?._tabs as any)._results;
        const matchIndex = tabs?.findIndex(x => x.ariaLabel === StudyPlanFragments.GUIDE_REGOLE);
        this._tabsGroup.selectedIndex = matchIndex;
    }

    isMenuOpen() {
        return this.fuseNavigationService?.getComponent<FuseVerticalNavigationComponent>('mainNavigation')?.opened;
    }

    cancelDraftEdit() {
        // reset plan states to the original ones
        this.buildStudyPlanData(this.currentPianoInfoView);
    }

    private buildDraftSummaryForSubmit(): PlanSummaryByYearsI[] {

        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);

        const draftToSend = this.currentPianoInfoView?.aggiornamenti?.find(a => a.stato === AggiornamentoPianoDiStudiStatus.BOZZA);

        const activities = draftToSend.categorie
            ?.flatMap(category => category?.corsi
                ?.map(s => {
                    const actTypeFormatted = category.tipoAttivitaContenute === ElementoOffertaFormativaType.CORSO ? 'common.didactic_activities' :
                        (category.tipoAttivitaContenute === ElementoOffertaFormativaType.ATTIVITAEXTRA ? 'student.extra_activities' : 'student.transversal_activities');
                    return {
                        activityDenomination: ((s?.datiCorsoOffertaFormativa?.idAttivitaPresunta && !s?.datiCorsoOffertaFormativa?.denominazione) ?
                                get(translation, 'study_plan_status.presumed_activity') : s?.datiCorsoOffertaFormativa?.denominazione)
                            + (s.datiCorsoOffertaFormativa.dettaglioAttivitaTrasversale ? ' - ' + s.datiCorsoOffertaFormativa.dettaglioAttivitaTrasversale : ''),
                        categoryDenomination: category.denominazione,
                        categoryType: get(translation, actTypeFormatted),
                        year: s?.datiCorsoOffertaFormativa?.annoInsegnamento
                    }
                }) ?? []
            ) ?? [];

        let allActivities = [
            ...activities,
        ];

        allActivities = sortBy(allActivities, [
            activity => activity.categoryType,
            activity => activity.activityDenomination?.toLowerCase()?.trim()
        ]);

        const allActivitiesByYears = [
            {
                year: AnnoRiferimentoValues.PRIMO,
                activities: [],
                canEditActivitiesForYear: this.canEditActivityForYear(AnnoRiferimentoValues.PRIMO)
            },
            {
                year: AnnoRiferimentoValues.SECONDO,
                activities: [],
                canEditActivitiesForYear: this.canEditActivityForYear(AnnoRiferimentoValues.SECONDO)
            },
            {
                year: AnnoRiferimentoValues.TERZO,
                activities: [],
                canEditActivitiesForYear: this.canEditActivityForYear(AnnoRiferimentoValues.TERZO)
            },
        ];

        allActivities.forEach(a => {
            allActivitiesByYears?.find(aby => aby.year === a.year)?.activities?.push(a);
        })

        return allActivitiesByYears;
    }

    private canEditActivityForYear(anno: AnnoRiferimentoValues) {
        return this.offertaFormativa?.configurazione?.is_possibile_inserire_corsi_per_anni_successivi
            || (annoInsegnamentoValueToNumber(this.studentDetailsService.studentDetails?.annoDiCorso) >= annoInsegnamentoValueToNumber(anno));
    }

    private openFillPresumedActivityInApprovedPlan(categoryConfig: StudyPlanCategoryDataUI, activity: CorsoPianoDiStudiInfoView & {
        idCategoriaOffertaFormativa: string
    },) {
        if (activity.tipo === ElementoOffertaFormativaType.CORSO) {
            this.openFillCourseActivityDialog(categoryConfig, activity);
        } else {
            this.openFillExtraOrTransversalActivityDialog(categoryConfig, activity);
        }
    }

    private openFillExtraOrTransversalActivityDialog(categoryConfig: StudyPlanCategoryDataUI, activity: CorsoPianoDiStudiInfoView & {
        idCategoriaOffertaFormativa: string
    }) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let confirmLabel = get(translation, 'dialog.confirm', null);
        let cancelLabel = get(translation, 'dialog.cancel', null);
        const data: GenericComponentDialogConfig = {
            title: 'study_plan_status.fill_presumed_activity',
            subtitle: activity.descrizione,
            icon: {
                show: true,
                name: 'done',
                color: 'info'
            },
            actions: {
                confirm: {
                    show: true,
                    label: confirmLabel,
                    color: 'primary',
                    icon: 'check',
                    function: (form, dialogRef) =>
                        this.fillPresumedActivity(categoryConfig, activity, form, dialogRef,)
                },
                cancel: {
                    show: true,
                    label: cancelLabel,
                }
            },
            alert: {
                type: 'info',
                message: get(translation, 'study_plan_status.fill_presumed_other_message', null)
            },
            dismissible: true,
            formConfig: [
                {
                    show: true,
                    name: 'denominazione',
                    required: true,
                    transloco: 'cycle_doctorate.activity_denomination',
                    type: TypeDialogFormEnum.TEXT,
                },
            ],
            valueForm: {
                denominazione: activity.denominazione
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            maxWidth: '150px',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }

    private openFillCourseActivityDialog(categoryConfig: StudyPlanCategoryDataUI, activity: CorsoPianoDiStudiInfoView & {
        idCategoriaOffertaFormativa: string
    }) {
        const availableCoursesTableConfig = this.getAvailableCoursesForPresumedFill(categoryConfig);
        const data: DialogDefineCoursePresumedI = {
            title: 'study_plan_status.fill_presumed_activity',
            subtitle: activity.descrizione,
            icon: {
                name: 'done',
                color: 'info'
            },
            onConfirm: (replacementActivity, loading$, dialogRef) => this.fillPresumedActivity(categoryConfig, activity, undefined, dialogRef, loading$, replacementActivity),
            activitiesConf: availableCoursesTableConfig,
            activitiesToNotShow: availableCoursesTableConfig.configuration?.data
                ?.filter(sa =>
                    this.isCourseAlreadyInPlan(sa.id))
                ?.map(c => c.id),
            onTableClickAction: (event) => this.tableClickAction(event),
            dontCloseDialog: true
        };
        this.dialog.open(DialogDefinePresumedCourseComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            minWidth: '95vw',
            hasBackdrop: true,
            disableClose: true,
        });
    }

    private fillPresumedActivity(categoryConfig: CategoriaInPianoDiStudiInfoView,
                                 activity: UIActivityI,
                                 form?: FormGroup,
                                 dialogRef?: MatDialogRef<GenericDialogComponent | DialogDefinePresumedCourseComponent>,
                                 loading$?: BehaviorSubject<boolean>,
                                 replacementCourse?: UIActivityI) {
        form?.disable({emitEvent: false});
        if (!form) {
            this.fuseConfirmationService.showLoader();
        }
        loading$?.next(true);
        this.pianiDiStudiService.definisciAttivitaPresunta(
            {
                tipo: activity.tipo,
                id_categoria_offerta_formativa: categoryConfig.idCategoriaOffertaFormativa,
                denominazione: form?.getRawValue()?.denominazione,
                id_corso_in_offerta_formativa: replacementCourse?.id,
            },
            this.currentPianoInfoView?.id,
            activity.idAttivitaPresunta
        ).pipe(
            takeUntil(this.destroy$),
            finalize(() => {
                form?.enable({emitEvent: false});
                if (!form) {
                    this.fuseConfirmationService.hideLoader();
                }
                loading$?.next(false);
            }),
        ).subscribe(
            {
                next: (updatedStudyPlan) => {
                    this.updatePianoDiStudiConfigurations(updatedStudyPlan ?? {}, true);
                    dialogRef?.close();
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'common.operation_success', null),
                        type: SnackbarTypes.Success,
                    });
                },
                error: (err) => {
                    console.log(err);
                    if (err.status === 409 && err?.error?.code == 1034) {
                        this.openInvalidStudyPlanDialog(err?.error?.message, err?.error?.utentiConPianoNonPiuValido?.[0]?.errors);
                    } else {
                        this.fuseConfirmationService.openErrorDialog({error: err},
                            this._translocoService,
                            () => {
                            },
                            () => this.fillPresumedActivity(categoryConfig, activity, form, dialogRef, loading$, replacementCourse),
                            'dialog.close',
                            err?.error?.message);
                    }
                }
            }
        );
    }

    private getAvailableCoursesForPresumedFill(categoryConfig: StudyPlanCategoryDataUI) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const year = annoRiferimentoFromRomanNumeral(categoryConfig.year);
        const checkConfigurationYearCallback = annoRiferimentoToCheckCategoryConfigurationYearCallback(year);
        // creating ui configuration for edit mode section
        const category = getPlanCategoryForCoursePresumedFill(this.offertaFormativa, this.currentPianoInfoView, year, categoryConfig);
        const selectedCorsi = getSelectedCoursesInCategoryForPresumedFill(this.currentPianoInfoView?.categorie, category.id, ElementoOffertaFormativaType.CORSO, year);
        const confForThisYear = category.configurazione?.configurazioni?.find(checkConfigurationYearCallback);
        // building table config for given category
        const activitiesTableConfiguration = buildConfigurationForCoursesInDraftEditMode(
            translation,
            category,
            this.currentPianoInfoView as AggiornamentoPianoDiStudiInfoView,
            this.currentPianoInfoView,
            true,
            selectedCorsi
        );
        activitiesTableConfiguration.configuration.singleChoiceSelection = true;
        return activitiesTableConfiguration;
    }

    private isCourseAlreadyInPlan(idCorsoInOffertaFormativa: string) {
        return !!this.currentPianoInfoView?.categorie
            ?.flatMap(cat => cat.corsi
                ?.map(att => att.idCorsoInOffertaFormativa)
            )?.find(id => id === idCorsoInOffertaFormativa);
    }

    private openInvalidStudyPlanDialog(message: string, errors: string[]) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                message: message,
                icon: {
                    show: true,
                    name: 'mat_outline:warning',
                    color: 'error'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                listData: errors?.map(e => ({ mainText: e })),
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                ]
            },
        );
    }
}


export enum StudyPlanFragments {
    APPROVATO = 'approvato',
    DA_APPROVARE_PARZIALMENTE = 'da-approvare-parzialmente',
    DA_APPROVARE_COORDINATORE = 'da-approvare-coordinatore',
    PIANI_PRESENTATI = 'presentati',
    NUOVA_PRESENTAZIONE = 'nuova-presentazione',
    GUIDE_REGOLE = 'guide-e-regole',
}


