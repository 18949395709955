import {
    showActionColumnForExtra,
    showCaricaAttestato,
    showRifiutaApprovaAttivita,
    showVisualizzaAttestato,
    showVisualizzaDelibera,
    unifyDocentiAndExtra
} from '../../study-plan-utils';
import {
    AttivitaExtraPianoDiStudiStatus,
    AuthorityType,
    CategoriaInAggiornamentoInPianoDiStudiInfoView,
    CategoriaInPianoDiStudiInfoView
} from '../../../../../../../api-clients/generated/services';
import {
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from '../../../../../../shared/components/table/model/generic-table-model';
import {getPresumedNumbersForDraft} from "../nuova-presentazione/configurazione-corsi-nuova-presentazione";
import {Translation} from "@ngneat/transloco";
import {get, sortBy, trim} from "lodash";


export function buildConfigurationForExtraActivitiesInPianoApprovato(translation: Translation,
                                                                     category: CategoriaInPianoDiStudiInfoView | CategoriaInAggiornamentoInPianoDiStudiInfoView,
                                                                     currentRuolo?: AuthorityType,
                                                                     isSupervisoreOrCoSupervisore?: boolean,
                                                                     ): GenericTableConfigurationModel {

    const coursesPlan = category.corsi;

    //count get presumed numbers
    const presumedNumbers = getPresumedNumbersForDraft(category);

    const tableData = coursesPlan?.map(element => ({...(element?.datiCorsoOffertaFormativa ?? element),}));

    let myData =
        tableData?.map(item => ({
            ...item,
            cfuRatio: item.cfuOttenuti + '/' + item.cfuPrevisti, //getCfuOttentuti(item, approvedPlan, category.idCategoriaOffertaFormativa) + '/' + getCfuPrevisti(item, approvedPlan, category.idCategoriaOffertaFormativa),
            organizzatori: item?.organizzatori?.map(organizzatore => ({nome: organizzatore})),
            docentiTutti: unifyDocentiAndExtra(item),
            denominazione: item.denominazione ?? item.denominazioneAttivitaPresunta,
            denominazioneFormatted: (item.idAttivitaPresunta && !item.denominazione) ? (get(translation, 'study_plan_status.presumed_activity') + ' ' + presumedNumbers.pop()) : item.denominazione,
            idCategoriaOffertaFormativa: category.idCategoriaOffertaFormativa,
            isArchiviato: item.isArchiviato || item?.isAssociazioneArchiviata,
        }));

    myData = sortBy(myData, [
        attivita => !attivita.idAttivitaProposta,
        attivita => !attivita.idAttivitaPresunta,
        attivita => trim(attivita.denominazioneFormatted)?.toLowerCase()
    ], ['asc', 'asc', 'asc']);

    return {
        configuration: {
            data: myData,
            totalElements: myData?.length,
            isPaginatedBE: false,
            sticky: true,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.activity_denomination',
                    colonnaKey: 'denominazioneFormatted',
                    flex: 35,
                    alsoShowChipIf: (activity) => {
                        if(activity.idAttivitaProposta){
                            return {
                                chipText: 'study_plan_status.proposed_activity',
                                iconName: 'school',
                                classes: 'text-blue-600'
                            };
                        } else if (activity.idAttivitaPresunta){
                            return {
                                chipText: activity.denominazione ? 'study_plan_status.presumed_activity_defined' : 'study_plan_status.presumed_activity_not_defined',
                                iconName: 'question_mark',
                                classes: activity.denominazione ? 'text-green-600' : 'text-orange-600'
                            };
                        } else {
                            return undefined;
                        }
                    },
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'common.status',
                    colonnaKey: 'statoAttivitaExtra',
                    flex: 15,
                    statusType: AttivitaExtraPianoDiStudiStatus
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'cycle_doctorate.final_check',
                    colonnaKey: 'verificaFinale',
                    flex: 15,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no',
                    show: (_, activity) => !activity.idAttivitaProposta && (!activity.idAttivitaPresunta || activity.denominazione),
                    hideColonna: true
                },
                {
                    tipo: TipoColonnaEnum.CHIP_USER,
                    nomeColonna: 'cycle_doctorate.organizer',
                    colonnaKey: 'organizzatori',
                    isChipDisabled: true,
                    show: (_, activity) => !activity.idAttivitaProposta && (!activity.idAttivitaPresunta || activity.denominazione),
                    flex: 25,
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'study_plan_status.archived_activity',
                    colonnaKey: 'isArchiviato',
                    show: (_, activity) => !activity.idAttivitaProposta && (!activity.idAttivitaPresunta || activity.denominazione),
                    flex: 8,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                },
                {
                    nomeColonna: 'user_list.action',
                    colonnaKey: 'azioni',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    button: [
                        {
                            nameIconButton: 'info',
                            click: TipoClickEnum.SHOW,
                            tooltip: 'common.show_detail',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'done',
                            click: TipoClickEnum.CONFERMA_IN_APPROVED_PLAN,
                            tooltip: 'study_plan_status.fill_presumed',
                            valueShow: 'id',
                            show: (_, activity) => (activity.idAttivitaPresunta && !activity.denominazione && currentRuolo === AuthorityType.STUDENTE),
                            color: 'accent',
                        },
                        {
                            nameIconButton: 'done',
                            click: TipoClickEnum.APPROVA_ATTIVITA_EXTRA,
                            valueShow: ['statoAttivitaExtra'],
                            show: (value, element) => showActionColumnForExtra(value, element, currentRuolo, isSupervisoreOrCoSupervisore) && showRifiutaApprovaAttivita(value, element, isSupervisoreOrCoSupervisore),
                            tooltip: 'study_plan_status.approve_activity',
                            color: 'primary'
                        },
                        {
                            nameIconButton: 'upload',
                            valueShow: ['statoAttivitaExtra'],
                            show: (value, element) => showActionColumnForExtra(value, element, currentRuolo, isSupervisoreOrCoSupervisore) && !(element?.idAttivitaPresunta && !element?.denominazione) && showCaricaAttestato(value, element, currentRuolo),
                            click: TipoClickEnum.CARICA_ATTESTATO,
                            tooltip: 'study_plan_status.require_approval',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'visibility',
                            valueShow: ['statoAttivitaExtra'],
                            show: (value, element) => showActionColumnForExtra(value, element, currentRuolo, isSupervisoreOrCoSupervisore) && showVisualizzaAttestato(value, element, currentRuolo, isSupervisoreOrCoSupervisore),
                            click: TipoClickEnum.SHOW_ATTESTATO_ATTIVITA_EXTRA,
                            tooltip: 'common.visualizza_attestato',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'visibility',
                            valueShow: ['statoAttivitaExtra'],
                            show: (value, element) => showActionColumnForExtra(value, element, currentRuolo, isSupervisoreOrCoSupervisore) && showVisualizzaDelibera(value, element, currentRuolo, isSupervisoreOrCoSupervisore),
                            click: TipoClickEnum.SHOW_DELIBERA_ATTIVITA_EXTRA,
                            tooltip: 'study_plan_status.visualizza_documento_supervisore',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'close',
                            click: TipoClickEnum.RIFIUTA_ATTIVITA_EXTRA,
                            valueShow: ['statoAttivitaExtra'],
                            show: (value, element) => showActionColumnForExtra(value, element, currentRuolo, isSupervisoreOrCoSupervisore) && showRifiutaApprovaAttivita(value, element, isSupervisoreOrCoSupervisore),
                            tooltip: 'study_plan_status.rejects_approval',
                            color: 'primary'
                        },
                    ]
                },

            ],
            hidePaginator: true,
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_activities_for_year'
        }
    };
}

